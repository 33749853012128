.headerContainer {
    width: 100%;
    text-align: center;
    border-bottom: 2px solid #d6ff00;
    padding-bottom: 10px;
  }
  
  .headerContainer h1 {
    color: #d6ff00;
    font-size: 28px;
    font-weight: 600;
  }
  
  .content {
    color: #fff;
    line-height: 22px;
    margin: 0 10rem 0 10rem;
  }
  .content > p {
    margin-top: 24px;
  }
  
  .content > img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-top: 24px;
  }
  
  @media (min-width: 1024px) {
    .mainContainer {
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: 32px;
      max-width: 1200px;
      margin: 0 auto;
    }
    .headerContainer {
      margin-top: 24px;
    }
    .sidebar {
      display: block;
    }
  
    .content {
      padding: 0;
      overflow: scroll;
    }
    .content > p {
      margin-top: 26px;
    }
  }
  