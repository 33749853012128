.calculatorsImage {
  display: none;
}

@media (min-width: 1024px) {
  .calculatorsImage {
    display: flex;

    justify-content: center;
    /* max-width: 320px; */
    object-fit: contain;
    position: sticky;

    color: white;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .calculatorsImage > div {
    position: absolute;
    inset: 0;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .calculatorsImage div h3 {
    font-size: 24px;
    font-weight: 600;
  }

  .calculatorsImage button {
    color: #ffffff;
    background-color: transparent;
    outline-offset: none;
    border: 1px solid #ffffff;
    backdrop-filter: blur(12px);
    border-radius: 6px;
    padding: 0.5rem 1.5rem;
    margin-top: 1.5rem;
  }

  .calculatorsImage .bottomWrapper {
    margin-top: auto;
  }

  .calculatorsImage img {
    /* max-width: 320px;
    max-height: 260px; */

    max-height: 100%;
    height: auto;
    width: auto;
    border-radius: 0.5rem;
  }
}
