.shopAdminOrders * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.shopAdminOrders {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
}

.top > div {
  display: flex;
  align-items: center;
  gap: 20px;
}

.searchTermInput {
  width: 300px;
  padding: 0.3rem 0.5rem;
}

.searchTermInput:focus,
.searchTermInput:focus-within {
  background-color: white !important;
}


.orderTableContainer {
  display: flex;
}
.orderTable {
  width: 100%;
  flex: 1;
  height: calc(100vh - 130px);
  overflow: auto;
}

.orderPreviewTable {
  width: 100%;
  border-collapse: collapse;
}

.orderPreviewTable th,
.orderPreviewTable td {
  border: 1px solid #000;
  padding: 0.5rem;
  text-align: left;
}

.orderPreviewTable th {
  background-color: #f2f2f2;
}

.orderPreviewTable td {
  background-color: #fff;
}

.orderPreviewModal {
  width: 70vw !important;
}
