.outherContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.container {
  display: flex;
  width: 100%;
  margin-top: 16px;
}
.leftContainer {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}
.inputFields {
  display: flex;
}
.left {
  display: flex;
  width: 50%;
  flex-direction: column;
  margin-right: 5px;
}
.partOne {
  display: flex;
}
.partOne :first-of-type {
  margin-right: 5px;
}
.partTwo {
  display: flex;
  width: 100%;
}
.right {
  display: flex;
  width: 50%;
  flex-direction: column;
}
.input {
  margin-top: 6px;
  width: 100%;
  border: 3px solid black;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 22px;
  font-weight: bolder;
}
.input::placeholder {
  font-weight: 300;
  font-size: 16px;
}
.calculator {
  width: 20%;
  height: 100%;
  margin-right: 5px;
  background: black;
}
.footer {
  display: flex;
  justify-content: center;
  margin-top: 7px;
}
.footer > a {
  width: 25%;
  height: 20px;
  text-align: center;
  background: linear-gradient(#000f2c, #001a4f);
  color: whitesmoke;
  font-weight: bolder;
  padding: 5px;
  margin-right: 3px;
  border: 1px solid linear-gradient(#000f2c, #001a4f);
  border-radius: 5px;
  cursor: pointer;
}
.footer > a:nth-child(1) {
  color: orange;
}
.footer > a:nth-child(2) {
  color: yellow;
}
.footer > a:nth-child(3) {
  color: greenyellow;
}
.footer > a:nth-child(4) {
  color: lightskyblue;
}
a.tooltip {
  text-decoration: none;
}
a.tooltip:hover {
  cursor: pointer;
  position: relative;
}
a.tooltip span {
  display: none;
}
a.tooltip:hover span {
  border: black 2px solid;
  border-radius: 8px;
  padding: 10px;
  display: block;
  z-index: 100;
  background: black;
  left: 0px;
  margin: 15px;
  width: 300px;
  position: absolute;
  bottom: 20px;
  text-decoration: none;
}
