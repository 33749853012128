.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  overflow: auto;
  align-items: center;
  margin: 0;
  padding: 0;
}
.calculator {
  background-image: url("../kalkulatoriSlikaPozadina/hokejSlika.png");
  background-color: #cccccc;
  width: 100%;
  /* height: 284px; */
  height: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 5px;
  margin-right: 10px;
}
.pobedaDomacina {
  position: absolute;
  top: 0px;
  left: 190px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.pobedaGosta {
  position: absolute;
  top: 0px;
  left: 1150px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.nereseno {
  position: absolute;
  top: 0px;
  left: 665px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.golMin {
  position: absolute;
  top: 60px;
  left: 665px;
  color: black;
  font-size: 32px;
  font-weight: bolder;
}
.golMax {
  position: absolute;
  top: 185px;
  left: 665px;
  color: black;
  font-size: 32px;
  font-weight: bolder;
}
.golBalans {
  position: absolute;
  top: 125px;
  left: 665px;
  color: black;
  font-size: 32px;
  font-weight: bolder;
}
.tacanRezultatDomacin {
  position: absolute;
  top: 75px;
  left: 1100px;
  color: black;
  font-size: 30px;
  font-weight: bolder;
}
.tacanRezultatGost {
  position: absolute;
  top: 75px;
  left: 1280px;
  color: black;
  font-size: 30px;
  font-weight: bolder;
}
.pobedaSaRazlikom {
  position: absolute;
  top: 140px;
  left: 1130px;
  color: black;
  font-size: 32px;
  font-weight: bolder;
}
.pobedaSaRazlikomZagrada {
  position: absolute;
  top: 140px;
  left: 1200px;
  color: black;
  font-size: 32px;
  font-weight: bolder;
}
.betkoPredlog1 {
  position: absolute;
  top: 210px;
  left: 1070px;
  color: black;
  font-size: 32px;
  font-weight: bolder;
}
.betkoPredlog2 {
  position: absolute;
  top: 210px;
  left: 1220px;
  color: black;
  font-size: 32px;
  font-weight: bolder;
}
