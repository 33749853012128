.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding-bottom: 40px;
}

.calculator {
  width: 99.2%;
  margin-right: 8px;
}

.tabela {
  flex-direction: row;
  display: flex;
  margin-top: 10px;
  font-weight: bolder;
  background: darkblue;
}

.column {
  border: 10px solid #082A78;
  display: flex;
  flex-direction: column;
}

.headerCelija {
  border-bottom: 10px solid #082A78;
  height: 80px;
  background: rebeccapurple;
}

.celija {
  border-bottom: 10px solid #082A78;
  border-top: 10px solid #082A78;
  height: 40px;
}

.headerCelija>p {
  font-size: 43px;
  margin-top: 18px;
  text-align: center;
}

.celija>p {
  text-align: center;
}

.celija>input {
  width: 97%;
  height: 95%;
  border: none;
  text-align: center;
  font-size: 30px;
}



/*.forma {*/
/*  padding-left: 40px;*/
/*  background-color: rgb(20, 47, 100);*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*}*/
/*.column {*/
/*  padding: 15px 0px 10px 0px;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*}*/

/*.column > div {*/
/*  padding: 10px;*/
/*  color: white;*/
/*  border: 1px solid white;*/
/*}*/

/*.column p {*/
/*  padding: 0px;*/
/*  margin: 0;*/
/*}*/

/*.lastcolumn {*/
/*  padding: 15px 0px 10px 0px;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*}*/

/*.lastcolumn > div {*/
/*  padding: 10px;*/
/*  border: 1px solid white;*/
/*}*/

/*.lastcolumn p {*/
/*  padding: 0;*/
/*  margin: 0;*/
/*}*/

/*input {*/
/*  color: black;*/
/*}*/