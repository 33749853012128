.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px;
  margin: 0;
  padding-top: 13px;
  overflow: hidden;
  overflow-y: auto;
  text-align: center;
  height: calc(100vh - 356px);
  direction: rtl;
  background-color: var(--p-color);
  width: 338px !important;
  flex-shrink: 0;
  scrollbar-width: none;
}

.container > ul {
  width: 95%;
  flex-direction: column;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  border-radius: 15px;
  margin-top: 3px;
}
.liTitle {
  background: linear-gradient(#f92910, #f44f1f);
  width: 98%;
  border-radius: 10px;
  padding: 10px;
  min-height: 32px;
  font-weight: bolder;
  color: #f1f1f1;
}
.liItem {
  min-height: 32px;
  background: linear-gradient(#000f2c, #001a4f);
  width: 98%;
  border: 1.5px solid var(--s-color);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
  margin: 1px;
  color: whitesmoke;
  font-weight: 700;
  cursor: pointer;
}
