.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  overflow: auto;
  align-items: center;
  margin: 0;
  padding: 0;
}
.calculator {
  background-image: url("../kalkulatoriSlikaPozadina/odbojkaSlika.png");
  background-color: #cccccc;
  width: 100%;
  /* height: 284px; */
  height: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 5px;
  margin-right: 10px;
}
.prvi {
  position: absolute;
  top: -15px;
  left: 90px;
  color: white;
  font-size: 60px;
  font-weight: bolder;
}
.drugi {
  position: absolute;
  top: -15px;
  left: 430px;
  color: white;
  font-size: 60px;
  font-weight: bolder;
}
.maxSetova {
  position: absolute;
  top: 115px;
  right: 545px;
  color: white;
  font-size: 60px;
  font-weight: bolder;
}

.treci {
  position: absolute;
  top: -15px;
  right: 545px;
  color: white;
  font-size: 60px;
  font-weight: bolder;
}

.cetvrti {
  position: absolute;
  top: -15px;
  right: 180px;
  color: white;
  font-size: 60px;
  font-weight: bolder;
}

.peti {
  position: absolute;
  top: 115px;
  left: 520px;
  color: white;
  font-size: 60px;
  font-weight: bolder;
}

.minSetova {
  position: absolute;
  top: 115px;
  left: 165px;
  color: white;
  font-size: 60px;
  font-weight: bolder;
}

.betkopredlog {
  position: absolute;
  top: 115px;
  right: 150px;
  color: white;
  font-size: 60px;
  font-weight: bolder;
}
