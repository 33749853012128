.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding-bottom: 40px;
}

.calculator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  margin-top: 10px;
}

.firstRow {
  display: flex;
  background: orangered;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.upisUlog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.upisKvota {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.eventualniDobitak {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.upisProlazDaNe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.cistaDobit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.upisProcenata {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.prenosUunitma {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.trenutnoStanje {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.upisUlogaPromasaja {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
}

.first>p,
.second>p {
  margin: 0;
  font-size: 14px;
  font-weight: bolder;
}

.upisUlog,
.upisKvota,
.eventualniDobitak,
.upisProlazDaNe,
.cistaDobit,
.upisProcenata,
.prenosUunitma,
.trenutnoStanje,
.upisUlogaPromasaja {
  border: 2px solid black;
  color: whitesmoke;
}

.upisUlog>p,
.upisKvota>p,
.eventualniDobitak>p,
.upisProlazDaNe>p,
.cistaDobit>p,
.upisProcenata>p,
.prenosUunitma>p,
.trenutnoStanje>p,
.upisUlogaPromasaja>p {
  font-size: 17px;
  font-weight: bolder;
}

.row {
  display: flex;
  border: 2px solid black;
}

.upisUlogFirstRow>input[type="number"],
.upisKvotaRow>input[type="number"],
.upisProlazDaNeRow>input[type="number"],
.upisProcenataRow>input[type="number"] {
  margin: 0;
  padding: 0;
  text-align: center;
  font-weight: bolder;
}

.upisUlogFirstRow>p,
.upisKvotaRow>p,
.eventualniDobitakRow>p,
.upisProlazDaNeRow>p,
.cistaDobitRow>p,
.upisProcenataRow>p,
.prenosUunitmaRow>p,
.trenutnoStanjeRow>p,
.upisUlogaPromasajaRow>p {
  margin: 0;
  padding: 0;
}

.upisUlogFirstRow {
  width: 81px;
}

.upisUlogFirstRow>input[type="number"] {
  /*width: 77px;*/
}

.upisKvotaRow {
  width: 69px;
}

.upisKvotaRow>input[type="number"] {
  width: 65px;
  border: none;
}

.eventualniDobitakRow {
  width: 128px;
  background: #712ea1;
  color: whitesmoke;
  text-align: center;
  background: #712ea1;
  color: whitesmoke;
  text-align: center;
  border-right: 1px solid black;
}

.upisProlazDaNeRow {
  width: 148px;
  background: #712ea1;
  color: whitesmoke;
  text-align: center;
  border-right: 1px solid black;
}

.cistaDobitRow {
  width: 123px;
  text-align: center;
  background: #712ea1;
  color: whitesmoke;
}

.upisProcenataRow {
  width: 191px;
}

.upisProcenataRow>input[type="number"] {
  width: 187px;
  border: none;
}

.prenosUunitmaRow {
  width: 180px;
  text-align: center;
  border-right: 1px solid black;
  background: #712ea1;
  color: whitesmoke;
}

.trenutnoStanjeRow {
  width: 179px;
  text-align: center;
  border-right: 1px solid black;
  background: #712ea1;
  color: whitesmoke;
}

.upisUlogaPromasajaRow {
  width: 330px;
  background: #712ea1;
  color: whitesmoke;
  text-align: center;
}

.upisUlogaPromasajaRow>input[type="number"] {
  width: 314px;
}

.upisUlogFirstRow2 {
  width: 78px;
  text-align: center;
  background: #712ea1;
  color: whitesmoke;
}

.bottom {
  display: flex;
  border: 2px solid black;
}

.prvi {
  width: 546px;
  border-right: 2px solid black;
  border-left: 2px solid black;
  text-align: center;
  font-weight: bolder;
  padding: 10px 0;
  background: orangered;
  color: whitesmoke;
}

.drugi {
  width: 372px;
  border-right: 2px solid black;
  text-align: center;
  font-weight: bolder;
  padding: 10px 0;
  background: green;
  color: white;
}

.treci {
  width: 179px;
  border-right: 2px solid black;
  text-align: center;
  font-weight: bolder;
  padding: 10px 0;
  background: orangered;
  color: whitesmoke;
}

.cetvrti {
  width: 327px;
  border-right: 2px solid black;
  text-align: center;
  font-weight: bolder;
  padding: 10px 0;
  background: orangered;
  color: whitesmoke;
}