.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding-bottom: 40px;
}

.calculator {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  margin-top: 10px;
  width: 99.2%;
}

.firstRow {
  display: flex;
  background: orangered;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.upisUlog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.upisKvota {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.eventualniDobitak {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.upisProlazDaNe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.cistaDobit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.upisProcenata {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.prenosUunitma {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.trenutnoStanje {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.upisUlogaPromasaja {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
}

.first>p,
.second>p {
  margin: 0;
  font-size: 14px;
  font-weight: bolder;
}

.upisUlog,
.upisKvota,
.eventualniDobitak,
.upisProlazDaNe,
.cistaDobit,
.upisProcenata,
.prenosUunitma,
.trenutnoStanje,
.upisUlogaPromasaja {
  border: 2px solid black;
  color: whitesmoke;
}

.upisUlog>p,
.upisKvota>p,
.eventualniDobitak>p,
.upisProlazDaNe>p,
.cistaDobit>p,
.upisProcenata>p,
.prenosUunitma>p,
.trenutnoStanje>p,
.upisUlogaPromasaja>p {
  font-size: 17px;
  font-weight: bolder;
}

.row {
  display: flex;
  border: 2px solid black;
}

.upisUlogFirstRow>input[type="number"],
.upisKvotaRow>input[type="number"],
.upisProlazDaNeRow>input[type="number"],
.upisProcenataRow>input[type="number"] {
  margin: 0;
  padding: 0;
  text-align: center;
  font-weight: bolder;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.upisUlogFirstRow>p,
.upisKvotaRow>p,
.eventualniDobitakRow>p,
.upisProlazDaNeRow>p,
.cistaDobitRow>p,
.upisProcenataRow>p,
.prenosUunitmaRow>p,
.trenutnoStanjeRow>p,
.upisUlogaPromasajaRow>p {
  margin: 0;
  padding: 0;
}

.upisUlogaPromasajaRow>input {
  border: none !important;
}

.upisUlogFirstRow {
  width: 6%;
}

.upisUlogFirstRow>input[type="number"] {
  width: 95%;
  border: none;
}

.upisKvotaRow {
  width: 6%;
}

.upisKvotaRow>input[type="number"] {
  width: 95%;
  border: none;
}

.eventualniDobitakRow {
  width: 9.2%;
  background: #712ea1;
  color: whitesmoke;
  text-align: center;
}

.upisProlazDaNeRow {
  width: 12%;
}

.upisProlazDaNeRow>select {
  width: 99.2%;
  border: none;
}

.cistaDobitRow {
  width: 9.2%;
  text-align: center;
  background: #712ea1;
  color: whitesmoke;
}

.upisProcenataRow {
  width: 15%;
}

.upisProcenataRow>input[type="number"] {
  width: 99.2%;
  box-sizing: border-box;
  border: none;
}

.prenosUunitmaRow {
  width: 18%;
  text-align: center;
  border-right: 1px solid black;
  background: #712ea1;
  color: whitesmoke;
}

.trenutnoStanjeRow {
  width: 18%;
  text-align: center;
  border-right: 1px solid black;
  background: #712ea1;
  color: whitesmoke;
}

.upisUlogaPromasajaRow {
  width: 22%;
  /* background: #712ea1; */
  color: whitesmoke;
  text-align: center;
}

.upisUlogFirstRow2 {
  width: 6.2%;
  text-align: center;
  background: #712ea1;
  color: whitesmoke;
}

.bottom {
  display: flex;
  border: 2px solid black;
}

.prvi {
  width: 546px;
  border-right: 1px solid black;
  /*border-left: 1px solid black;*/
  text-align: center;
  font-weight: bolder;
  padding: 10px 0;
  background: orangered;
  color: whitesmoke;
}

.drugi {
  width: 372px;
  border-right: 1px solid black;
  text-align: center;
  font-weight: bolder;
  padding: 10px 0;
  background: green;
  color: black;
}

.treci {
  width: 179px;
  border-right: 1px solid black;
  text-align: center;
  font-weight: bolder;
  padding: 10px 0;
  background: orangered;
  color: whitesmoke;
}

.cetvrti {
  width: 327px;
  border-right: 1px solid black;
  text-align: center;
  font-weight: bolder;
  padding: 10px 0;
  background: orangered;
  color: whitesmoke;
}

.upisProlazDaNe>select {
  text-align: center;
}

.upisUlogHeader {
  width: 6.2%;
  background: orangered;
  border-left: none;
  color: whitesmoke;
  height: 50px;
  border: 1px solid black;
}

.upisKvotaHeader {
  width: 6%;
  background: orangered;
  color: whitesmoke;
  height: 50px;
  border: 1px solid black;
  border-left: none;
}

.eventualniDobitakHeader {
  width: 9.85%;
  background: orangered;
  color: whitesmoke;
  height: 50px;
  border: 1px solid black;
  border-left: none;
}

.upisProlazDaNeHeader {
  width: 12%;
  background: orangered;
  color: whitesmoke;
  height: 50px;
  border: 1px solid black;
  border-left: none;
}

.cistaDobitHeader {
  width: 9.9%;
  background: orangered;
  color: whitesmoke;
  height: 50px;
  border: 1px solid black;
  border-left: none;
}

.upisProcenataHeader {
  width: 15%;
  background: orangered;
  color: whitesmoke;
  height: 50px;
  border: 1px solid black;
  border-left: none;
}

.prenosUunitmaHeader {
  width: 18%;
  background: orangered;
  color: whitesmoke;
  height: 50px;
  border: 1px solid black;
  border-left: none;
}

.trenutnoStanjeHeader {
  width: 18%;
  background: orangered;
  color: whitesmoke;
  height: 50px;
  border: 1px solid black;
  border-left: none;
}

.upisUlogPromasajaHeader {
  width: 22%;
  background: orangered;
  color: whitesmoke;
  height: 50px;
  border: 1px solid black;
  border-left: none;
}