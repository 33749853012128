.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  overflow: auto;
  align-items: center;
  margin: 0;
  padding: 0;
}
.calculator {
  background-image: url("../kalkulatoriSlikaPozadina/futsalSlika.png");
  background-color: #cccccc;
  width: 100%;
  /* height: 284px; */
  height: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 5px;
  margin-right: 10px;
}
.pobedaDomacina {
  position: absolute;
  top: 0;
  left: 190px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.pobedaGosta {
  position: absolute;
  top: 0;
  left: 1150px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.nereseno {
  position: absolute;
  top: 0;
  left: 670px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.golMin {
  position: absolute;
  top: 60px;
  left: 46.5%;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.golMax {
  position: absolute;
  top: 190px;
  left: 46.5%;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.golBalans {
  position: absolute;
  top: 125px;
  left: 46.5%;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.tacanRezultatDomacin {
  position: absolute;
  top: 70px;
  left: 1120px;
  color: white;
  font-size: 30px;
  font-weight: bolder;
}
.tacanRezultatGost {
  position: absolute;
  top: 70px;
  left: 1270px;
  color: white;
  font-size: 30px;
  font-weight: bolder;
}
.pobedaSaRazlikom {
  position: absolute;
  top: 135px;
  left: 1170px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.pobedaSaRazlikomZagrada {
  position: absolute;
  top: 135px;
  left: 1270px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.betkoPredlog1 {
  position: absolute;
  top: 210px;
  left: 1080px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.betkoPredlog2 {
  position: absolute;
  top: 210px;
  left: 84.5%;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
