.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  align-items: center;
  width: 1788px;
  color: #070044;
  font-weight: 900;
}
.active {
  color: #24006c;
}
.left {
  display: flex;
}
.podesavanja {
  width: 230px;
  font-weight: 900;
  text-transform: uppercase;
  border-radius: 10px;
  margin-right: 8px;
  padding: 12px 20px;
  background-color: #032b3d;
  color: whitesmoke;
  cursor: pointer;
  border: none;
  border: 2px solid var(--s-color);
}
.pokreniKalkulatore {
  width: 230px;
  font-weight: 900;
  text-transform: uppercase;
  border-radius: 10px;
  padding: 12px 20px;
  background-color: #032b3d;
  color: whitesmoke;
  margin-right: 8px;
  cursor: pointer;
  border: 2px solid var(--s-color);
}

.betkoFamilyBtn {
  width: 339px;
  font-weight: 900;
  background-color: #000f2c;
  color: #00a8f4;
  margin-right: 8px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 0 20px;
  font-size: 22px;
  cursor: pointer;
  border: none;
  border: 2px solid var(--s-color);
}
