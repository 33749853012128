.container {
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.buttonsContainer {
  display: flex;
  gap: 20px;
}

.buttonsContainer button {
  background-color: #1677ff;
  padding: 35px;
  border-radius: 1rem;
  font-size: 1.3rem;
  cursor: pointer;
  color: white;
}
