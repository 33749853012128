.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin: 0;
  padding: 0;
}
.calculator {
  background-image: url("../kalkulatoriSlikaPozadina/pikadoSlika.png");
  background-color: #cccccc;
  width: 100%;
  /* height: 284px; */
  height: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 5px;
  margin-right: 10px;
}
.prvi {
  position: absolute;
  top: 10px;
  left: 100px;
  color: red;
  font-size: 42px;
  font-weight: bolder;
}
.drugi {
  position: absolute;
  top: 10px;
  left: 450px;
  color: red;
  font-size: 42px;
  font-weight: bolder;
}
.maxSetova {
  position: absolute;
  top: 130px;
  left: 860px;
  color: red;
  font-size: 42px;
  font-weight: bolder;
}

.treci {
  position: absolute;
  top: 10px;
  left: 860px;
  color: red;
  font-size: 42px;
  font-weight: bolder;
}

.cetvrti {
  position: absolute;
  top: 10px;
  left: 1240px;
  color: red;
  font-size: 42px;
  font-weight: bolder;
}

.peti {
  position: absolute;
  top: 130px;
  left: 510px;
  color: red;
  font-size: 42px;
  font-weight: bolder;
}

.minSetova {
  position: absolute;
  top: 130px;
  left: 150px;
  color: red;
  font-size: 42px;
  font-weight: bolder;
}

.betkopredlog {
  position: absolute;
  top: 130px;
  left: 1230px;
  color: red;
  font-size: 42px;
  font-weight: bolder;
}
