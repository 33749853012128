.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 180px) !important;
  padding: 30px;
  overflow: hidden;
  overflow-y: auto;
}

.container::-webkit-scrollbar {
  width: 15px !important;
  height: 10px !important;
}

.container::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 4px !important;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.container::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 4px !important;
}
.menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 20px;
  padding: 30px;
}

.addImageBtn {
  background-color: #007bff; /* Primary blue color */
  border: none;
  color: white; /* White text color */
  padding: 12px 24px; /* Padding to make the button larger */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px 0 10px;
  cursor: pointer;
  border-radius: 4px; /* Rounded corners */
  transition-duration: 0.4s; /* Smooth transition for hover effect */
}

.addImageBtn:hover {
  background-color: #0056b3; /* Darken the button color when hovered */
  color: white;
}

.row {
  display: flex;
  padding: 10px 5% 10px 5%;
  flex-wrap: wrap;
  min-height: 80px;
  justify-content: space-between;
  border-bottom: 1px solid rgb(100, 100, 100);
}

.imagesList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.imageItem {
  max-width: 200px;
  min-width: 200px;
  margin-right: 10px;
  max-height: 150px !important;
  min-height: 150px !important;
  padding: 5px;
}

.imageItem img {
  max-width: 200px;
  min-width: 200px;
  max-height: 150px !important;
  min-height: 150px !important;
  border-radius: 10px;
}

.left {
  display: flex;
}

.left p {
  min-width: 200px;
}

.input {
  background-color: white !important;
  width: 40%;
}

.saveBtn {
  background-color: #ff0000; /* Primary blue color */
  border: none;
  color: white; /* White text color */
  padding: 12px 24px; /* Padding to make the button larger */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px 0 10px;
  cursor: pointer;
  border-radius: 4px; /* Rounded corners */
  transition-duration: 0.4s; /* Smooth transition for hover effect */
}

.saveBtn:hover {
  background-color: #b30000; /* Darken the button color when hovered */
  color: white;
}
