.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 102px);
  width: 100%;
  max-width: 600px;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
}

.singleContainer>p {
  font-size: 24px;
  margin-right: 30px;
}

.span {
  color: aqua;
  font-size: 24px;
}

.singleContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.datePicker {
  width: 130px;
  padding: 8px;
  font-size: 22px;
  color: aqua;
  text-align: center;
  background-color: black;
  border: none;
  cursor: pointer;
}

.select {
  padding: 8px;
  font-size: 22px;
  color: aqua;
  text-align: center;
  background-color: black;
  border: none;
  cursor: pointer;
}

.napomena {}

.buttonsContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.btn {
  width: 200px;
  padding: 16px;
  font-size: 22px;
  color: aqua;
  text-align: center;
  background-color: black;
  border: 1px solid aqua;
  border-radius: 12px;
  cursor: pointer;
}

.restrictContainer {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.restrictContainer>input {
  width: 100%;
  height: 100%;
  margin: 0;
  accent-color: aqua;
}

.moderationInput {
  width: 25px;
  height: 25px;
  accent-color: aqua;
  cursor: pointer;
}