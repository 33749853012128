.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  height: 100%;
  overflow: auto;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-bottom: 40px;
}
.statisticContainer {
  width: 100%;
  justify-content: flex-start;
  margin-top: 24px;
}
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
  flex-direction: column;
}

.msg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 32px;
  font-weight: bold;
}
