.mainCenter {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 65%;
  height: 100%;
  text-align: left;
  overflow: auto;
  margin-bottom: 10px;
}
.mainCenter > p {
  padding: 0 30px;
  color: #0066cc;
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 0;
}
.mainCenter > img {
  margin-top: 15px;
  width: 600px;
  display: flex;
  border: 10px solid black;
  border-radius: 12px;
}
