.container {
  display: flex;
  max-width: 1200px;
  height: calc(100vh - 113px);
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  background-color: white;
  background-color: var(--p-color);

}

.container::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
