.tipsterMatch {
  display: flex;
  align-items: center;
  gap: 5px;
  border: 3px solid black;
  padding: 0.3rem;
}

.tipsteMatchWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
}

.tipBtnsContainer {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 5px;
  margin-right: 5px;
}

.tipBtnsContainer button {
  height: 90%;
  width: 70px;
  font-size: 1.2rem;
  cursor: pointer;
}
.matchInfo {
  flex: 1;
}

.tipBtnsContainer button,
.matchLeague,
.matchTime,
.matchOpponents div {
  background-color: black;
  color: white;
  border: 2px solid #11273a;
  padding: 0.2rem;
  border-radius: 0.5rem;
  text-align: center;
  vertical-align: center;
}

.tipBtnsContainer button {
  border: none;
}

.matchInfo {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.timeAndOpponents {
  display: flex;
  gap: 3px;
  height: 70px;
}

.matchOpponents {
  display: flex;
  gap: 3px;
  flex: 1;
}

.matchOpponents div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-family: Arial Black, sans-serif;
}

.matchTime {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 2/1;
  width: 100px;
}

.blockedMatchTime {
  background: linear-gradient(to right, #e20000, #7d0303);
  color: black;
  font-size: 0.95rem;
  font-weight: 700;
}

.matchLeague {
  padding-left: 108px;
}

.blockedMatch {
  background-color: gray !important;
  cursor: not-allowed !important;
  color: black !important;
}

.isSelected {
  background-color: #ffbf37 !important ;
  color: black !important;
}

.win {
  background-color: #64fa36 !important;
  color: black !important;
}

.fail {
  background-color: red !important;
  color: black !important;
  cursor: pointer;
}

.blc .tipBtnsContainer button,
.blc .matchLeague,
.blc .matchTime,
.blc .matchOpponents div {
  background-color: #404040;
}

.profileTable .tipBtnsContainer button,
.profileTable .matchLeague,
.profileTable .matchTime,
.profileTable .matchOpponents div {
  background-color: #080ee7;
  color: white;
  border: none;
}

.profileTable .tipBtnsContainer {
  order: 2;
  margin-left: 5px;
  font-size: 1.3rem;
}

.profileTable .tipBtnsContainer button {
  font-family: Arial Black, sans-serif !important;
}
