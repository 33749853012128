.pagination_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
  margin-top: 1rem;
}

.back_button_container,
.next_button_container {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back_button_container>button,
.next_button_container>button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.back_button_container>button>img {
  width: 100%;
  height: 100%;
}

.targetButtonsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.targetButtonsContainer>button {
  background-color: #13f16c;
  border: none;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  font-size: 25px;
  font-weight: bolder;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
}

.targetButtonsContainer>button.active {
  background-color: #2C3EC4;
  color: white;
}