.tipsterProfilePage {
  flex: 1;
  overflow: auto;
  padding: 2rem;
  padding-top: 0;
  font-weight: 700;
  text-align: center;
  padding-bottom: 3rem;
  position: relative;
  scrollbar-width: none;
}

.tipsterProfilePage h2 {
  padding: 0;
  margin: 3px;
  font-family: Arial Black, sans-serif;
}

.sticky {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 5;
  padding: 5px 0;
}

.pairsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 3px;
}

.checkboxWrapper {
  position: relative;
  display: inline-block;
}

.checkboxWrapper input {
  appearance: none;
  border: 2px solid black;
  outline: none;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 16px 18px;
  border-radius: 0;
  position: relative;
}

.checkboxWrapper input:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 22px;
  height: 22px;
  background-color: #64fa36;
  border: 1px solid #5e99c0;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.top {
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.top h2 {
  font-size: 2rem;
  margin: 0;
  text-transform: uppercase !important;
}

.top .inputWrapper {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

.top input {
  height: 30px;
  width: 30px;
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: auto;
  margin: 0 5rem;
}

.imageWrapper img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.mid {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-top: -20px;
}

.mid h2 { 
  color: #0914f1;
  text-transform: uppercase;
}

.scoreWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 5px;
}

.scoreInner {
  display: flex;
  gap: 5px;
  font-size: 2rem;
  height: 70px;
}

.scoreOrder,
.totalScore {
  color: #1af835;
  background-color: #00143d;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  font-family: Arial Black, sans-serif;
}

.scoreTitle {
  background-color: black;
  color: white;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  padding: 0.8rem;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: Arial Black, sans-serif;
}

.scoreOrder {
  min-width: 70px;
}
.totalScore {
  flex: 1;
}

.tipsterUsername {
  background-color: #0914f1;
  width: 100%;
  color: white;
  font-size: 2rem;
  border-radius: 0.5rem;
  margin-top: 5px;
  padding: 0.5rem 0;
  font-family: Arial Black, sans-serif;
}

.yearTableInfo {
  display: flex;
  gap: 13px;
  margin-top: 5px;
}

.todayTipsTitle {
  background-color: #002060;
  color: white;
  border-radius: 4px;
  font-size: 1.2rem;
  padding: 0.5rem;
  width: 220px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.yearTableBtn {
  flex: 1;

  cursor: pointer;
}

.yearTableBtn,
.positionYear,
.pointsYear {
  color: #1cf836;
  background: linear-gradient(to right, #012d85, #012d85);
  border-radius: 8px;
  outline: none;
  border: none;
  padding: 0.5rem 1.5rem;
  font-size: 1.6rem;
  font-family: Arial Black, sans-serif;
}

.todayMatchTableHeader {
  display: flex;
  margin-top: 5px;
}

.x12 {
  width: 220px;
  font-size: 2rem;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  padding: 0 1.5rem;
  box-sizing: border-box;
  font-family: Arial Black, sans-serif;
}
.x12 .xTip {
  color: red;
}
.tipsterMatchTitle {
  background-color: black;
  flex: 1;
  color: white;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 3px;
  font-family: Arial Black, sans-serif;
}

.backButton {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem !important;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  height: 20px;
  flex-shrink: 0;
  cursor: pointer;
  font-family: Arial Black, sans-serif;
}

.backButton svg {
  color: #ff0000;
  text-align: center;
  height: 100%;
  font-size: 3rem;
  display: flex;
  align-items: center;
  width: 20px;
  transform: scale(1.8);
}

.backButton span {
  margin-left: -2px;
}

.globalTableHeader {
  display: flex;
  gap: 15px;
}

.globalTableBtn {
  flex: 1;
  cursor: pointer;
}

.globalTableBtn,
.globalPosition {
  color: #1cf836;
  background-color: #00143d;
  padding: 0.5rem;
  box-sizing: border-box;
  font-size: 1.3rem;
  margin: 0;
  border: none;
  border-radius: 8px;
}

.globalTableBtn svg {
  color: white;
}

.bettingSpan {
  color: #c20b35;
}

.globalPosition {
  width: 220px;
}
