.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin: 0;
  padding: 0;
}
.calculator {
  background-image: url("../kalkulatoriSlikaPozadina/odbranaTiketaSlika.png");
  background-color: #cccccc;
  width: 1440px;
  height: 284px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 5px;
  margin-right: 10px;
}
.prvi {
  position: absolute;
  top: 5%;
  left: 5%;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.drugi {
  position: absolute;
  top: 5%;
  left: 32.5%;
  color: red;
  font-size: 32px;
  font-weight: bolder;
}

.treci {
  position: absolute;
  height: 50px;
  width: 163px;
  top: 42%;
  left: 43.9%;
  color: white;
  font-size: 32px;
  font-weight: bolder;
  text-align: center;
  overflow: hidden;
}

.cetvrti {
  position: absolute;
  height: 50px;
  width: 163px;
  top: 66.2%;
  left: 43.9%;
  color: white;
  font-size: 32px;
  font-weight: bolder;
  text-align: center;
  overflow: hidden;
}

.peti {
  position: absolute;
  height: 50px;
  width: 163px;
  top: 42%;
  left: 57.4%;
  color: white;
  font-size: 32px;
  font-weight: bolder;
  text-align: center;
  overflow: hidden;
}

.sesti {
  position: absolute;
  height: 50px;
  width: 163px;
  top: 66.2%;
  left: 57.4%;
  color: white;
  font-size: 32px;
  font-weight: bolder;
  text-align: center;
  overflow: hidden;
}

.sedmi {
  position: absolute;
  height: 50px;
  width: 163px;
  top: 42%;
  left: 70.7%;
  color: white;
  font-size: 32px;
  font-weight: bolder;
  text-align: center;
  overflow: hidden;
}

.osmi {
  position: absolute;
  height: 50px;
  width: 163px;
  top: 42%;
  left: 84.1%;
  color: white;
  font-size: 32px;
  font-weight: bolder;
  text-align: center;
  overflow: hidden;
}

.deveti {
  position: absolute;
  height: 50px;
  width: 163px;
  top: 66.2%;
  left: 70.7%;
  color: white;
  font-size: 32px;
  font-weight: bolder;
  text-align: center;
  overflow: hidden;
}

.deseti {
  position: absolute;
  height: 50px;
  width: 163px;
  top: 66.2%;
  left: 84.1%;
  color: white;
  font-size: 32px;
  font-weight: bolder;
  text-align: center;
  overflow: hidden;
}
