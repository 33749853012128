.singleAnswerComment {
  background-color: #454a59;
  overflow: hidden;
}

.singleAnswerCommentInner {
  padding: 1rem;
}
.divider {
  border-top: 1px solid #212631;
  width: calc(100% - 2rem);
  margin-inline: auto;
}

.adminCommentMessage {
  background-color: #2e3551;
  border: 1px solid #cabb90;
  padding: 8px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: #cabb90;
}

.adminCommentMessage div img {
  height: 36px;
  aspect-ratio: 1/1;
}

.adminCommentMessageContent {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.adminCommentMessageContent .adminCommentText {
  font-size: 13px;
  font-weight: 400;
  color: #ffffff;
}

.adminCommentTitle {
  color: #cabb90;
  font-weight: 600;
  font-size: 1rem;
}

.commentHeader {
  display: flex;
}

.userInfo {
  color: #ffffffad;
  font-size: 12px;
  margin-inline: 0.5rem;
  font-weight: 400;
}

.userRole {
  display: flex;
  align-items: center;
  gap: 2px;
}

.userName {
  color: white;
  font-size: 1rem;
  line-height: 22px;
  font-weight: 500;
  word-break: break-word;
}

.userIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14px;
  width: 14px;
}

.commentHeaderBtns {
  display: flex;
  gap: 0.5rem;
  margin-left: auto;
  position: relative;
}

.commentHeaderBtns button {
  height: 24px;
  width: 24px;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  outline: none;
  justify-content: center;
  background-color: transparent;
  outline: none;
  border: none;
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  width: 42px;
  border: 1px solid #d0d5dd;
  border-radius: 5.76px;
  flex-shrink: 0;
  background-color: white;
}

/* COMMENT TIME */

.commentTime {
  color: #ffffffad;
  font-size: 13px;
  font-weight: 400;
  margin-block: 1rem;
}

/* COMMENT BODY */

.commentBody {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 1rem;
  background-color: #353945;
  padding: 1rem;
  border-radius: 12px;
}

/* COMMENT FEEDBACK */

.commentFeedback {
  display: flex;
  gap: 20px;
}

/* COMMENT MENU */

.optionsMenu {
  background-color: #262c39;
  border: 1px solid #212631;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 2rem;

  right: 0;
  width: fit-content;
  border-radius: 4px;
  padding: 0 8px;
}

.optionsMenu li {
  list-style: none;
  width: 169px;
  display: flex;
  padding: 0.5rem 0;
  border-bottom: 1px solid #212631;
  border-radius: none;
}

.optionsMenu ul li:last-child {
  border: 0;
}

.optionsMenu li button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  width: 100%;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  border-radius: 0px;
}

.idLi {
  border: none;
}

.idLi button span {
  color: #ffffffad;
  font-size: 10px;
  border: none;
}

.optionsMenu li button:disabled span {
  color: #ffffffad;
  display: none;
}

.optionsMenu li button img {
  height: 20px;
  width: 20px;
}

/* COMMENT FEEDBACK OPTIONS */
.commentFeedbackOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feedbackBtnsWrapper {
  display: flex;
  gap: 1rem;
  padding: 4px;
  border-radius: 8px;
}

.commentFeedbackOptions button {
  border: none;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  gap: 4px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
}

.commentFeedbackOptions button img {
  height: 20px;
  width: 20px;
  border: none;
  outline: none;
}

.commentFeedbackOptions button.responseBtn {
  color: #1d2939;
  background-color: white;
  outline: none;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  padding: 6px 8px;
}

/* COMMENT TYPE */

.commentType {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #ffffffd1;
}

.commentInputContainer {
  background-color: #454a59;
  padding: 1rem;
}

.textAreaWrapper {
  display: flex;
  align-items: center;
  background-color: #212631;
  border-radius: 12px;
  padding: 12px;
  min-height: 56px;
  height: 56px;
}

.messageIcon,
.sendIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.messageIcon {
  padding-right: 12px;
  border-right: 1px solid #d0d5dd;
  height: 32px;
}

.messageIcon img,
.sendIcon img {
  width: 20px;
  height: 20px;
}

.sendIcon {
  background-color: #ffffff;
  border: 1px solid #d0d5dd;
  height: 32px;
  width: 40px;
  border-radius: 6px;
}
.commentInputContainer textarea {
  width: 100% !important;
  resize: none;
  padding-inline: 12px;
  padding-top: 16px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background-color: #212631;
  color: white;
  font-size: 14px;
  vertical-align: middle;
}

.commentInputContainer textarea::placeholder {
  color: white;
}

.showCommentsBtn {
  background-color: #ffffff29;
  color: var(--a-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 0.5rem;
  border: none;
  width: 100%;
  padding: 6px;
  height: 36px;
  margin-top: 1rem;
}

@media (min-width: 1024px) {
  .commentLinkToPost {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
