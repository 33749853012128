.container {
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.imageUploadContainer {
  display: flex;
  align-items: center;
  gap: 15px;
}

.title {
  font-size: 32px;
}

.imageUploadButton {
  position: relative;
  background-color: #007bff;
  border-radius: 5px;
  cursor: pointer;
}

.imageUploadButton>input {
  opacity: 0;
  pointer-events: none;
  margin: 0;
  max-width: 120px;
}

.imageUploadButton>label {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
  display: grid;
  place-content: center;
  cursor: pointer;
}

.imagesContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 200px));
  column-gap: 10px;
  row-gap: 10px;
}

.avatarImage {
  width: 100%;
  height: 250px;
  /* object-fit: cover; */
  border-radius: 10px;
}

.delButton {
  background: transparent;
  position: relative;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid rgb(255, 0, 0);
  border-radius: 25px;
  outline: none;
  overflow: hidden;
  color: rgb(255, 0, 0);
  transition: color 0.3s 0.1s ease-out;
  text-align: center;
  width: 100%;
  margin-top: 10px;
}

.delButton span {
  margin: 10px;
}

.delButton::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  content: "";
  border-radius: 50%;
  display: block;
  width: 20em;
  height: 20em;
  left: -5em;
  text-align: center;
  transition: box-shadow 0.5s ease-out;
  z-index: -1;
}

.delButton:hover {
  color: #fff;
  border: 1px solid rgb(255, 0, 0);
  background-color: 1px solid rgb(255, 0, 0);
}

.delButton:hover::before {
  box-shadow: inset 0 0 0 10em rgb(255, 0, 0);
}