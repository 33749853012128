.yearTableContainer {
  display: flex;
  flex: 1;
  overflow: hidden;
  padding: 1rem;
  padding-top: 0;
  box-sizing: border-box;
}


.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 auto;
}
