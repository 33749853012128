.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding-bottom: 40px;
}

.calculator {
  /*background: gray;*/
  width: 99.2%;
  margin-right: 10px;
  margin-top: 10px;
  padding: 3px;
  /*padding-left: 8px;*/
}

.kolona {
  width: 33%;
}

.naslovnaCelija {
  border: 3px solid white;
  height: 35px;
}

.naslovnaCelija>p {
  font-size: 25px;
  margin-top: 4px;
  text-align: center;
  font-weight: bolder;
}

.celijaLeveKolone {
  border: 3px solid white;
  height: 35px;
}

.celijaLeveKolone>p {
  font-size: 25px;
  margin-top: 4px;
  text-align: center;
  font-weight: bolder;
}

.naslovnaCelijaCosak {
  background: linear-gradient(25deg,
      transparent 46.5%,
      white 0.5%,
      white 52.5%,
      transparent 30.5%);
  height: 35px;
  border: 3px solid white;
  background-color: gray;
}

.celija {
  height: 35px;
  border: 3px solid black;
  display: flex;
  flex-direction: row;
}

.poslednjaCelija {
  height: 35px;
  border: 3px solid black;
  display: flex;
  flex-direction: row;
}

.levaPodCelijaPoslednjeCelije {
  width: 70%;
  border-right: 3px solid black;
  background: rebeccapurple;
}

.desnaPodCelijaPoslednjeCelije {
  width: 29%;
  background: #92d050;
}

.desnaPodCelijaPoslednjeCelije>p {
  text-align: center;
  font-weight: bolder;
  font-size: 12px;
  margin-top: 12px;
  color: black;
  transform: rotate(-90deg);
}

.celija {
  height: 35px;
  border: 3px solid black;
  display: flex;
  flex-direction: row;
}

.levaPodCelija {
  width: 70%;
  border-right: 3px solid black;
  background: rebeccapurple;
}

.levaPodCelija p {
  color: white;
}

.levaPodCelijaPoslednjeCelije p {
  color: white;
}

.desnaPodCelija {
  background: #00b0f0;
  width: 29%;
}

.desnaPodCelija>p {
  text-align: center;
  font-weight: bolder;
  font-size: 12px;
  margin-top: 12px;
  color: white;
  transform: rotate(-90deg);
}

.celijaX {
  background: linear-gradient(25deg,
      transparent 47.5%,
      white 0.5%,
      white 51.5%,
      transparent 30.5%),
    linear-gradient(155deg,
      transparent 47.5%,
      white 0.5%,
      white 51.5%,
      transparent 30.5%);
  background-color: darkblue;
  height: 35px;
  border: 3px solid white;
  display: flex;
  flex-direction: row;
}