.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  overflow: auto;
  align-items: center;
  margin: 0;
  padding: 0;
}
.calculator {
  background-image: url("../kalkulatoriSlikaPozadina/americkiFudbalSlika.png");
  background-color: #cccccc;
  width: 100%;
  /* height: 284px; */
  height: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 5px;
  margin-right: 10px;
}
.procenatPobede1 {
  position: absolute;
  left: 160px;
  color: white;
  font-size: 50px;
  font-weight: bolder;
}
.procenatPobede2 {
  position: absolute;
  left: 550px;
  color: white;
  font-size: 50px;
  font-weight: bolder;
}
.opcijaTacnogRezultata1 {
  position: absolute;
  top: 60px;
  right: 395px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.opcijaTacnogRezultata2 {
  position: absolute;
  top: 60px;
  right: 144px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.bodBalans {
  position: absolute;
  top: 139px;
  left: 660px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.bodMin {
  position: absolute;
  top: 95px;
  left: 660px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.bodMax {
  position: absolute;
  top: 183px;
  left: 660px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.betkopredlog {
  position: absolute;
  top: 170px;
  right: 240px;
  color: white;
  font-size: 42px;
  font-weight: bolder;
}
