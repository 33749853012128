.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  overflow: auto;
  align-items: center;
  margin: 0;
  padding: 0;
}

.forma {
  width: 100%;
  height: 100%;
  background-color: rgb(20, 47, 100);
  margin: 0;
  padding: 0;
  display: flex;
}

.baldText {
  font-weight: bold;
}

.column1 {
  width: 20%;
  font-size: 19px;
  color: white;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
}

.column1 input {
  height: 80px;
  width: 100%;
  padding: 5px;
  /*font-size: 26px;*/
  text-align: center;
  font-weight: bold;
}

.innercolumn1 {
  display: flex;
  justify-content: space-between;
}


.innercolumn1 input {
  font-size: 26px;
  text-align: center;
}

.domacin1 {
  width: 40%;
}

.gost1 {
  width: 40%;
}

.column3 {
  width: 20%;
  color: white;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  text-align: center;
}

.column3 input {
  text-align: center;
  font-weight: bold;
}

.innercolumn3column1 {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  /* flex-direction: row; */
  justify-content: space-around;
}

.innercolumn3column2 {
  display: grid;
  width: 100%;
  gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-around;
}

.column3 input {
  height: 75px;
  font-size: 26px;
}

.innercolumn3column1 input {
  width: 100%;
}

.innercolumn3column2 input {
  width: 100%;
}

.ug02 {
  width: 90%;
}

.ug3 {
  width: 90%;
}

.column2 {
  width: 65%;
  font-size: 19px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-left: 10px;
  padding-left: 10px;
  margin-right: 10px;
  text-align: center;
}

.text1innercolumn1column2 {
  font-weight: bold;
  font-size: 28px;
}

.text2innercolumn1column2 {
  font-weight: bold;
  font-size: 50px;
}

.text4innercolumn1column2 {
  font-weight: bold;
  font-size: 50px;
  color: red;
}

.text6innercolumn1column2 {
  font-weight: bold;
  font-size: 40px;
  color: red;
}

.text7innercolumn1column2 {
  font-weight: bold;
  font-size: 30px;
  color: red;
}

.text8innercolumn1column2 {
  font-weight: bold;
  font-size: 20px;
  color: red;
}

.text1innercolumn2column2 {
  font-weight: bold;
  font-size: 28px;
}

.text2innercolumn2column2 {
  font-weight: bold;
  font-size: 50px;
}

.text4innercolumn2column2 {
  font-weight: bold;
  font-size: 50px;
  color: yellow;
}

.text6innercolumn2column2 {
  font-weight: bold;
  font-size: 40px;
  color: yellow;
}

.text7innercolumn2column2 {
  font-weight: bold;
  font-size: 30px;
  color: yellow;
}

.text8innercolumn2column2 {
  font-weight: bold;
  font-size: 20px;
  color: yellow;
}

.text1innercolumn3column2 {
  font-weight: bold;
  font-size: 28px
}

.text2innercolumn3column2 {
  font-weight: bold;
  font-size: 50px;
}

.text4innercolumn3column2 {
  font-weight: bold;
  font-size: 50px;
  color: green;
}

.text6innercolumn3column2 {
  font-weight: bold;
  font-size: 40px;
  color: green;
}

.text7innercolumn3column2 {
  font-weight: bold;
  font-size: 30px;
  color: green;
}

.text8innercolumn3column2 {
  font-weight: bold;
  font-size: 20px;
  color: green;
}

.text1column5 {
  font-weight: bold;
}

.text3column5 {
  font-weight: bold;
}

.column2 .title {
  font-size: 22px;
  font-weight: bold;
}

.maincontent {
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}

.maincontent div {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  width: 33%;
  margin: 0;
  padding: 0 12px 0 12px;
}

.maincontent div p {
  margin: 0;
  padding: 10px 0 9px 0;
}

.input1column1 {
  font-size: 52px;
  height: 50px;
}