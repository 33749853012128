.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}

.calculator {
  display: flex;
  justify-content: center;
  flex-direction: row;
  /*align-items: center;*/
  background-color: #712ea1;
  width: 99.2%;
  margin-right: 5px;
  height: 100%;
  padding-bottom: 20px;
  font-weight: bold;
  margin-top: 10px;
}

.leftCels {
  width: 400px;
  background: orangered;
  text-align: center;
  align-self: center;
  border: 3px solid black;
}

/*.leftCels{*/
/*  width: 90%;*/
/*  background: orangered;*/
/*  text-align: center;*/
/*  align-self: center;*/
/*  border: 3px solid black;*/
/*}*/
/*.left {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  !*margin-top: 80px;*!*/
/*}*/
/*.upisIgara {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 350px;*/
/*  height: 60px;*/
/*  background: red;*/
/*  color: whitesmoke;*/
/*  border: 2px solid black;*/
/*  margin-bottom: 5px;*/
/*  font-weight: bolder;*/
/*  font-size: 32px;*/
/*}*/
/*.unosKvota {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 350px;*/
/*  height: 100px;*/
/*  background: red;*/
/*  color: whitesmoke;*/
/*  border: 2px solid black;*/
/*  margin-bottom: 5px;*/
/*  font-weight: bolder;*/
/*  font-size: 32px;*/
/*}*/
/*.proceduralneSanse {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 350px;*/
/*  height: 50px;*/
/*  background: red;*/
/*  color: whitesmoke;*/
/*  border: 2px solid black;*/
/*  margin-bottom: 5px;*/
/*  font-weight: bolder;*/
/*  font-size: 18px;*/
/*}*/
/*.marzaKladionice {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 350px;*/
/*  height: 50px;*/
/*  background: red;*/
/*  color: whitesmoke;*/
/*  border: 2px solid black;*/
/*  margin-bottom: 5px;*/
/*  font-weight: bolder;*/
/*  font-size: 18px;*/
/*}*/
/*.realneNivelisaneKvote {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 350px;*/
/*  height: 50px;*/
/*  background: red;*/
/*  color: whitesmoke;*/
/*  border: 2px solid black;*/
/*  margin-bottom: 5px;*/
/*  font-weight: bolder;*/
/*  font-size: 18px;*/
/*}*/
/*.realniNivelisaniProcenti {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 350px;*/
/*  height: 50px;*/
/*  background: red;*/
/*  color: whitesmoke;*/
/*  border: 2px solid black;*/
/*  margin-bottom: 5px;*/
/*  font-weight: bolder;*/
/*  font-size: 18px;*/
/*}*/
/*.right {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  margin-left: 50px;*/
/*}*/
/*.columns {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*}*/
/*.firstRow,*/
/*.secondRow,*/
/*.thirdRow,*/
/*.forthRow,*/
/*.fifthRow,*/
/*.sixRow {*/
/*  display: flex;*/
/*  margin-bottom: 5px;*/
/*  !* padding: 0; *!*/
/*}*/
/*!* .dveIgre,*/
/*.triIgre,*/
/*.cetiriIgre,*/
/*.petIgara,*/
/*.dveIgre2,*/
/*.triIgre2,*/
/*.cetiriIgre2,*/
/*.petIgara2  *!*/

/*.dveIgre5,*/
/*.triIgre5,*/
/*.cetiriIgre5,*/
/*.petIgara5 {*/
/*  !* display: flex; *!*/
/*  !* border: 3px solid green; *!*/
/*  !* justify-content: space-around; *!*/
/*  !* margin-left: 4px; *!*/
/*}*/
/*.dveIgre3 > input[type="text"],*/
/*.triIgre3 > input[type="text"],*/
/*.cetiriIgre3 > input[type="text"],*/
/*.petIgara3 > input[type="text"] {*/
/*  width: 38px;*/
/*  height: 38px;*/
/*  margin-right: 15px;*/
/*}*/

/*.dveIgre4 > input[type="text"],*/
/*.triIgre4 > input[type="text"],*/
/*.cetiriIgre4 > input[type="text"],*/
/*.petIgara4 > input[type="text"] {*/
/*  width: 38px;*/
/*  height: 76px;*/
/*  margin-right: 15px;*/
/*  margin-top: 5px;*/
/*}*/
/*.dveIgre3 > input[type="text"]:last-child,*/
/*.triIgre3 > input[type="text"]:last-child,*/
/*.cetiriIgre3 > input[type="text"]:last-child,*/
/*.petIgara3 > input[type="text"]:last-child,*/
/*.dveIgre4 > input[type="text"]:last-child,*/
/*.triIgre4 > input[type="text"]:last-child,*/
/*.cetiriIgre4 > input[type="text"]:last-child,*/
/*.petIgara4 > input[type="text"]:last-child,*/
/*.dveIgre5:last-child,*/
/*.triIgre5:last-child,*/
/*.cetiriIgre5:last-child,*/
/*.petIgara5:last-child {*/
/*  !* width: 30px; *!*/
/*  margin-right: 0;*/
/*}*/
/*.dveIgre5,*/
/*.triIgre5,*/
/*.cetiriIgre5,*/
/*.petIgara5 {*/
/*  !* margin-right: 15px; *!*/
/*}*/
/*.kocka {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 43px;*/
/*  height: 43px;*/
/*  border: 2px solid black;*/
/*  color: whitesmoke;*/
/*  font-size: 10px;*/
/*  font-weight: bolder;*/
/*  background: #712ea1;*/
/*  margin-right: 15px;*/
/*}*/
/*.kocka:last-child {*/
/*  margin-right: 0;*/
/*}*/
/*.dveIgre {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 110px;*/
/*  height: 30px;*/
/*  border: 2px solid black;*/
/*  background: red;*/
/*  color: whitesmoke;*/
/*  margin-right: 50px;*/
/*  font-weight: bolder;*/
/*}*/

/*.dveIgre2 {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 110px;*/
/*  height: 20px;*/
/*  border: 2px solid black;*/
/*  background: blue;*/
/*  color: whitesmoke;*/
/*  margin-right: 50px;*/
/*  font-weight: bolder;*/
/*}*/
/*.dveIgre3 {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 110px;*/
/*  height: 40px;*/
/*  margin-right: 50px;*/
/*  font-weight: bolder;*/
/*  margin-left: 2px;*/
/*}*/
/*.dveIgre4 {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 110px;*/
/*  height: 80px;*/
/*  margin-right: 50px;*/
/*  font-weight: bolder;*/
/*  margin-left: 2px;*/
/*}*/
/*.dveIgre5 {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 110px;*/
/*  height: 50px;*/
/*  margin-right: 50px;*/
/*  font-weight: bolder;*/
/*  margin-left: 2px;*/
/*  !* padding: 0; *!*/
/*}*/
/*.triIgre {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 170px;*/
/*  height: 30px;*/
/*  border: 2px solid black;*/
/*  background: red;*/
/*  color: whitesmoke;*/
/*  margin-right: 50px;*/
/*  font-weight: bolder;*/
/*}*/

/*.triIgre2 {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 170px;*/
/*  height: 20px;*/
/*  border: 2px solid black;*/
/*  background: blue;*/
/*  color: whitesmoke;*/
/*  margin-right: 50px;*/
/*  font-weight: bolder;*/
/*}*/
/*.triIgre3 {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 170px;*/
/*  height: 40px;*/
/*  margin-right: 50px;*/
/*  font-weight: bolder;*/
/*  margin-left: 3px;*/
/*}*/
/*.triIgre4 {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 170px;*/
/*  height: 80px;*/
/*  margin-right: 50px;*/
/*  font-weight: bolder;*/
/*  margin-left: 3px;*/
/*}*/
/*.triIgre5 {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 170px;*/
/*  height: 50px;*/
/*  margin-right: 50px;*/
/*  font-weight: bolder;*/
/*  margin-left: 3px;*/
/*}*/
/*.cetiriIgre {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 230px;*/
/*  height: 30px;*/
/*  border: 2px solid black;*/
/*  background: red;*/
/*  color: whitesmoke;*/
/*  margin-right: 50px;*/
/*  font-weight: bolder;*/
/*}*/
/*.cetiriIgre2 {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 230px;*/
/*  height: 20px;*/
/*  border: 2px solid black;*/
/*  background: blue;*/
/*  color: whitesmoke;*/
/*  margin-right: 50px;*/
/*  font-weight: bolder;*/
/*}*/
/*.cetiriIgre3 {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 230px;*/
/*  height: 40px;*/
/*  margin-right: 50px;*/
/*  font-weight: bolder;*/
/*  margin-left: 4px;*/
/*}*/
/*.cetiriIgre4 {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 230px;*/
/*  height: 80px;*/
/*  margin-right: 50px;*/
/*  font-weight: bolder;*/
/*  margin-left: 4px;*/
/*}*/
/*.cetiriIgre5 {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 230px;*/
/*  height: 50px;*/
/*  margin-right: 50px;*/
/*  font-weight: bolder;*/
/*  margin-left: 4px;*/
/*}*/
/*.petIgara {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 290px;*/
/*  height: 30px;*/
/*  border: 2px solid black;*/
/*  background: red;*/
/*  color: whitesmoke;*/
/*  font-weight: bolder;*/
/*  !* margin-right: 50px; *!*/
/*}*/
/*.petIgara2 {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 290px;*/
/*  height: 20px;*/
/*  border: 2px solid black;*/
/*  background: blue;*/
/*  color: whitesmoke;*/
/*  font-weight: bolder;*/
/*}*/
/*.petIgara3 {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 290px;*/
/*  height: 40px;*/
/*  font-weight: bolder;*/
/*  margin-left: 5px;*/
/*}*/
/*.petIgara4 {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 290px;*/
/*  height: 80px;*/
/*  font-weight: bolder;*/
/*  margin-left: 5px;*/
/*}*/
/*.petIgara5 {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 290px;*/
/*  height: 50px;*/
/*  font-weight: bolder;*/
/*  margin-left: 5px;*/
/*}*/