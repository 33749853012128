.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  overflow: auto;
  align-items: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding-bottom: 40px;
}

.calculator {
  background-color: #161616;
  width: 99.2%;
  /*height: 100% !important;*/
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 5px;
  margin-right: 11px;
  color: rgb(206, 5, 5);
  display: flex;
  flex-direction: column;
  border: 1px solid black;
}

.zagljavlje {
  margin: 0;
  padding: 0;
}

.podzaglavlje {
  display: flex;
  justify-content: space-around;
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.podzaglavlje2 {
  display: flex;
  justify-content: space-around;
  font-size: 20px;
  margin-top: -25px;
  padding: 0;
}

.celatabela {
  padding: 0px 0px 20px 15px;
}

.jedanred:first-child {
  margin-top: -50px;
}


.jedanred input {
  width: 80px;
}

.jedanred input:first-child {
  width: 95px;
}

.jedanred input:last-child {
  width: 80px;
}

.prvired {
  display: flex;
  width: 1380px;
}

.prvired p {
  display: flex;
  width: 84px;
  padding: 1px;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-weight: bold;
}

.prvired p:first-child {
  display: flex;
  width: 102px;
  padding: 1px;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-weight: bold;
}

.prvired p:last-child {
  display: flex;
  width: 102px;
  padding: 1px;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-weight: bold;
}

.prvireditemlevo {
  background-color: black;
  border: red 1px solid;
}

.prvireditemdesno {
  background-color: rgb(206, 5, 5);
  color: black;
  border: rgb(245, 101, 101) 1px solid;
}

.parent {
  flex: 1;
  border: 1px solid black;
  box-sizing: border-box;
}

.calculateTable {
  width: 100%;
  border-collapse: collapse;
}

.calculateTable th,
.calculateTable td {
  border: 1px solid black;
}

.column1 {
  background-color: white;
}