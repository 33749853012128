.cardPlaceholder {
  border: 3px solid #022161;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: white;
  padding-bottom: 1rem;
  height: 220px;
  margin-top: auto;
  width: calc((100vw - 60px) / 3);
  font-size: 0.7rem;
  font-family: 'Arial', sans-serif;
  text-align: center;
  flex-shrink: 0;
  padding-bottom: 5px !important;
  background-color: black;
  position: relative;
}

.cardTitle {
  color: white;
  position: absolute;
  bottom: 0.3rem;
  padding: 0.2rem;
  overflow: hidden;
}

.imageWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  /* height: 85px; */
}

.imageWrapper img {
  height: 100%;
  width: 100%;
  object-fit: contain !important;
}

.shopPlaceholder {
  height: 220px;
}
.homePagePlaceholder {
  border: none;
}

@media (min-width: 765px) {
  .cardPlaceholder {
    font-size: 1.3rem;
    aspect-ratio: auto;
    height: 300px;
    width: 90%;
    gap: 5px;
  }

  .shopPlaceholder {
    height: 320px;
    width: 33%;
  }

  .imageWrapper {
    display: flex;
  }
}
