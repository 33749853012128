.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding-bottom: 40px;
}

.calculator {
  background-image: url("../kalkulatoriSlikaPozadina/tacanRezultatSlika.png");
  background-color: #cccccc;
  width: 100%;
  /* height: 284px; */
  height: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 5px;
  margin-right: 10px;
}

.prvi {
  position: absolute;
  top: 13%;
  left: 5%;
  color: white;
  font-size: 36px;
  font-weight: bolder;
}

.drugi {
  position: absolute;
  top: 13%;
  left: 32.5%;
  color: red;
  font-size: 36px;
  font-weight: bolder;
}

.treci {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 10px;
  left: 20px;
}

.cetvrti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 10px;
  left: 175px;
}

.peti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 10px;
  left: 335px;
}

.sesti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 10px;
  left: 530px;
}

.sedmi {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 10px;
  left: 790px;
}

.osmi {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 80px;
  left: 175px;
}

.deveti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 80px;
  left: 650px;
}

.deseti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 80px;
  left: 1150px;
}

.jedanaesti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 10px;
  left: 1150px;
}

.dvanaesti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 135px;
  left: 470px;
}

.trinaesti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 135px;
  left: 565px;
}

.cetrnaesti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 135px;
  left: 720px;
}

.petnaesti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 135px;
  left: 810px;
}

.sesnaesti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 135px;
  left: 1000px;
}

.sedamnaesti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 135px;
  left: 1080px;
}

.osamnaesti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 135px;
  left: 1240px;
}

.devetnaesti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 135px;
  left: 1340px;
}

.dvadeseti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 175px;
  left: 470px;
}

.dvadesetprvi {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 175px;
  left: 565px;
}

.dvadesetdrugi {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 175px;
  left: 720px;
}

.dvadesettreci {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 175px;
  left: 810px;
}

.dvadesetcetvrti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 175px;
  left: 1000px;
}

.dvadesetpeti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 175px;
  left: 1080px;
}

.dvadesetsesti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 175px;
  left: 1240px;
}

.dvadesetsedmi {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 175px;
  left: 1340px;
}

.dvadesetosmi {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 215px;
  left: 470px;
}

.dvadesetdeveti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 215px;
  left: 565px;
}

.trideseti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 215px;
  left: 720px;
}

.tridesetprvi {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 215px;
  left: 810px;
}

.tridesetdrugi {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 215px;
  left: 1000px;
}

.tridesettreci {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 215px;
  left: 1080px;
}

.tridesetcetvrti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 215px;
  left: 1240px;
}

.tridesetpeti {
  position: absolute;
  color: white;
  font-size: 26px;
  top: 215px;
  left: 1340px;
}