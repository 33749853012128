.misterTipsterAdmin {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px) !important;
  padding: 0 2rem;
  overflow: auto;
}

.misterTipsterHeader {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

.misterTipsterHeader .inputWhiteBg {
  width: 200px !important;
}

.formRow {
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.misterTipsterAdmin h3,
.misterTipsterAdmin label {
  color: white;
}

.matchWrapper {
  border: 3px solid white;
  width: 350px;
  padding: 0 1rem;
  background-color: rgb(61, 61, 61);
  border-radius: 1rem;
}

.matchHeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.matchHeaderWrapper input {
  outline: none;
  border: none;
  width: 80px;
}

.matchHeaderWrapper button {
  padding: 0.3rem 0.5rem;
  background-color: #1677ff;
  color: white;
  font-weight: 700;
  border: 2px solid white;
  cursor: pointer;
  border-radius: .5rem;
}

.tipsterFormList {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.tipsterFormList input {
  background-color: white;
}

.inputWhiteBg {
  background-color: white !important;
  flex: 1;
}

.submitBtnsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  gap: 70px;
}

.submitBtnWrapper {
  display: flex;
  justify-content: center;
  width: 50%;
  flex: 1;
}

.submitBtnWrapper .submitBtn {
  flex: 1;
}
