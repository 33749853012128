.shopAdminAwards * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.shopAdminAwards {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.awardTableContainer {
  display: flex;
  margin: 1.5rem 0;
}

.awardTable {
  width: 100%;
  flex: 1;
  height: calc(100vh - 130px);
  overflow: auto;
}

.awardPreviewTable {
  width: 100%;
  border-collapse: collapse;
}

.awardPreviewTable th,
.awardPreviewTable td {
  border: 1px solid #000;
  padding: 0.5rem;
  text-align: left;
}

.awardPreviewTable th {
  background-color: #f2f2f2;
}

.awardPreviewTable td {
  background-color: #fff;
}

.awardPreviewModal {
  width: 70vw !important;
}
