.container {
  width: 99.2%;
  margin-right: 7px;
  margin-top: 5px;
  border: 3px solid black;
  border-radius: 8px;
}
.left {
  width: 80%;
  display: flex;
}
.right {
  width: 20%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
}
.segment {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: bolder;
  align-items: center;
  margin: 10px 30px;
  width: 100%;
}
.btn1 {
  padding: 14px;
  border-radius: 8px;
  background: black;
  color: whitesmoke;
  font-size: 16px;
  font-weight: bolder;
  cursor: pointer;
}
.btn2 {
  padding: 14px;
  border-radius: 8px;
  background: lightskyblue;
  color: black;
  font-size: 16px;
  font-weight: bolder;
  cursor: pointer;
}
a.tooltip {
  text-decoration: none;
  color: whitesmoke;
}
a.tooltip:hover {
  cursor: pointer;
  position: relative;
}
a.tooltip span {
  display: none;
}
a.tooltip:hover span {
  border: black 2px solid;
  border-radius: 8px;
  padding: 10px;
  display: block;
  z-index: 100;
  color: whitesmoke;
  background: black;
  left: 0px;
  margin: 15px;
  text-align: center;
  width: 250px;
  position: absolute;
  bottom: 20px;
  text-decoration: none;
}
