.footer {
  max-width: 1800px;
  width: calc(100% - 100px);
  display: flex;
  margin: 0 auto;
  height: 75px;
  color: #ffffff;
  position: relative;
  z-index: 1100;
}

.img {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 75px;
  background-size: cover;
  /* Maintains aspect ratio */
  background-repeat: round;
  font-size: 27px;
}

.buttonsContainer {
  position: absolute;
  bottom: 37px;
  left: 50%;
  transform: translate(-50%, -37px);
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  width: 62%;
  pointer-events: none;
}

.liveResults {
  position: absolute;
  bottom: 37px;
  left: 50%;
  transform: translate(-50%, -37px);
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  width: 100%;
  pointer-events: none;
}

.liveResults>div {
  width: 62%;
}

.buttonsContainer>div {
  width: 100%;
  max-width: 300px;
}

.buttonsContainer>div>button,
.liveResults>div>button {
  font-family: 'Comic Sans', sans-serif;
  font-weight: bolder;
  height: 37px;
  cursor: pointer;
  border: 2px solid #080236;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  width: 100%;
  font-size: 16px;
  pointer-events: all;
  background-color: #080236;
}

.buttonsContainer>:nth-child(1)>button {
  color: #13f16c;
}

.buttonsContainer>:nth-child(2)>button {
  color: rgb(255, 192, 0);
}

.buttonsContainer>:nth-child(3)>button {
  color: red;
}

.liveResults>:nth-child(1)>button {
  color: red;
}