.container {
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.searchTermInput:focus,
.searchTermInput:focus-within {
  background-color: white !important;
}

.addButton {
  margin-left: 1rem;
  padding: 0.5rem;
}

.sellersTable {
  width: 100%;
  flex: 1;
}

.searchTermInput {
  width: 300px;
  padding: 0.3rem 0.5rem;
}
