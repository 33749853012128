.mainCenter {
  width: 65%;
  height: 100%;
  overflow: auto;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
}

.content>img {
  margin: 5px auto;
}

.popUpContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px 5px;
  gap: 15px;
}

.popUpContainer>:nth-child(1) {
  display: flex;
  align-items: center;
}

.popUpContainer>:nth-child(1)>:nth-child(1),
.popUpContainer>:nth-child(1)>:nth-child(3) {
  flex: 2;
}

.popUpContainer>:nth-child(1)>:nth-child(1)>p {
  margin: 0;
  color: crimson;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;
}

.popUpContainer>:nth-child(1)>:nth-child(2)>button {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.popUpContainer>:nth-child(1)>:nth-child(2)>button>span {
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  color: blue;
  font-weight: bold;
}

.popUpContainer>:nth-child(1)>:nth-child(3)>:nth-child(1)>p {
  margin: 0;
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;
}

.smallerClickAndSee>button {
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 5px solid red;
  background-color: #070044;
  color: red;
  font-weight: bolder;
  border-radius: 10px;
  line-height: 1;
  min-height: 55px;
  cursor: pointer;
}

.newMenuContainer>div {
  width: 100%;
  height: 100%;
  min-height: 120px;
  display: grid;
  border-radius: 22px;
}

.newMenuContainer>div>button {
  background-color: transparent;
  cursor: pointer;
  /* padding: 10px; */
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 25px;
  font-weight: bolder;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  background-color: #070044;
  border: 5px solid red;
}

.newMenuContainer>div>button>span {
  text-align: center;
  width: 100%;
  display: block;
  font-weight: bolder;
  line-height: 1;
}

.newMenuContainer>:nth-child(1)>button {
  color: red;
}

.checkboxPopUpContainer {
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 6px;
  border: 3px solid black;
  border-radius: 10px;
  box-sizing: border-box;
}

.checkboxPopUpContainer>span {
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-weight: bold;
  text-align: center;
}

.checkboxPopUpContainer>div {
  flex: 1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  position: relative;
}

.checkboxPopUpContainer>div>input {
  appearance: none;
  width: 100%;
  height: 100%;
  border: 2px solid black;
  box-sizing: border-box;
  margin: 0;
  min-height: 30px;
  background-color: white;
}

.checkboxPopUpContainer>div>input::before {
  content: '\2713';
  font-size: 20px;
  font-weight: bolder;
  color: transparent;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.checkboxPopUpContainer>div>input:checked::before {
  color: red;
}