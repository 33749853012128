.chosePackage {
  border: 1px solid #212631;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  min-width: 343px;
  height: 100%;
  max-height: 136px;
  background: linear-gradient(90deg, #23283e 32.39%, #4c5c9f 100%);
}

.imgWrapper {
  display: flex;
  gap: 12px;
  align-items: center;
}
.imgContainer {
  padding: 6px;
  background: #ffffff29;
  width: 48px;
  height: 48px;
  border-radius: 44px;
}
.imgContainer > img {
  width: 36px;
  height: 36px;
}
.textContainer > p {
  font-weight: 600;
  font-size: 18px;
  color: #fff;
}
.textContainer > span {
  color: #ffffffd1;
  font-weight: 400;
  font-size: 14px;
  margin-top: 6px;
}
.chosePackage > button {
  background: #d6ff00;
  border: 1px solid #b8db00;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  color: #252f3b;
  border-radius: 8px;
  width: 100%;
  margin-top: 16px;
  height: 40px;
}
.singlePostContainer {
  display: flex;
  margin-top: 24px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.modalBody {
  padding: 24px;
  background: linear-gradient(0deg, #353945, #353945),
    linear-gradient(317.13deg, #111219 10%, #40445e 100%);
  border: 1px solid #212631;
  width: 100%;
  max-width: 916px;
}
.modalHeader {
  padding-bottom: 8px;
  border-bottom: 1px solid #d6ff00;
}
.modalHeader > h2 {
  color: #d6ff00;
  font-weight: 500;
  font-size: 18px;
}
@media (min-width: 1024px) {
  .chosePackage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
  }
  .chosePackage > button {
    width: 120px;
    text-wrap: nowrap;
    margin-top: 0px;
  }
  .singlePostContainer {
    justify-content: space-between;
  }
}
