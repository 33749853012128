.infoBlok {
  display: flex;
  max-width: 1800px;
  width: calc(100% - 100px);
  height: 62px;
  color: #ffffff;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  border-radius: 10px;
  position: relative;
  z-index: 1900;
}

/* .infoWallButtonsWrapper button {
  flex: 1;
  font-family: 'Comic Sans', sans-serif;
  background-color: transparent;
  border: 2px solid var(--s-color) !important;
  color: white;
  font-size: 1.4rem;
  border-radius: 10px;
  cursor: pointer;
  background-color: #032b3d;

} */

.infoLeft,
.infoRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 20%; */
  cursor: pointer;
  background-color: #032b3d;
  width: 270px;
  height: 75%;
  color: white;
  padding: 0 2rem;
  font-size: 1.4rem;
  border-radius: 10px;
  margin: 0 5px;
  border: 2px solid var(--s-color) !important;
}

.infoLeft > p,
.infoRight > p,
.infoCenter > p {
  margin: 0 auto;
  font-size: 28px;
  font-weight: bolder;
}

.arrowIcons {
  width: 18px;
  height: 18px;
}

.titleRounded {
  flex: 1;
  text-align: center;
  border: 3px solid white;
  border-radius: 10px;
  border-radius: 1rem 1rem 0 0 ;
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 6px;
  border-bottom: 5px solid white;
  margin-bottom: -5px;
}

.title > p {
  font-size: 26px;
  font-weight: bolder;
  color: #f92910;
}

.infoWallButtonsWrapper {
  width: 60%;
  height: 83%;
  display: flex;
  gap: 5px;
}

.infoWallButtonsWrapper button {
  flex: 1;
  font-family: 'Comic Sans', sans-serif;
  background-color: transparent;
  border: 2px solid var(--s-color) !important;
  color: white;
  font-size: 1.4rem;
  border-radius: 10px;
  cursor: pointer;
  background-color: #032b3d;
}

.infoWallButtonsWrapper .betBtn {
  background-color: #032b3d;
  color: white;
  font-weight: 700;
  font-size: 1.8rem !important;
}

.infoWallButtonsWrapper .netBtn {
  background-color: #032b3d;
  font-weight: 700;
  font-size: 1.8rem !important;
}

.allCommentsLinkButton {
  height: 83%;
  color: black;
  width: 60%;
  background-color: #ffc000;
  border: 5px solid white;
  border-radius: 12px;
  font-weight: 600;
  font-size: 1.125rem;
  cursor: pointer;
}

@media (min-width: 1256px) {
  .allCommentsLinkButton {
    font-size: 1.5rem;
  }
}

.allCommentsLinkButton span {
  color: red;
}
