.toggleButtonContainer {
  width: 100%;
  margin: 0 auto;
  margin-top: 16px;
}

.toggleButton {
  background-color: #ffffff29;
  color: white;
  padding: 6px 14px 6px 10px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  transition: 0.3s ease;
  width: 100%;
  height: 40px;
}

.container {
  display: none;
}

.container.visible {
  display: block;
  background-color: #353945;
  width: 100%;
  padding: 0;
  overflow: scroll;
  border-radius: 12px;
  border: 1px solid #212631;
}

.container ul {
  margin: 0 auto;
  padding: 8px;
  width: 100%;
  list-style: none;
}

.liTitle {
  background: #353945;
  color: #d6ff00;
  text-align: start;
  padding: 12px 15px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  margin-bottom: 10px;
}

.liItem {
  background: #262c39;
  color: whitesmoke;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
  border-radius: 6px;
  margin-bottom: 8px;
  text-align: start;
  cursor: pointer;
}
.selected {
  border: 1px solid #d6ff00;
  color: #d6ff00;
}
@media (min-width: 768px) {
  .toggleButton {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .toggleButton {
    display: none;
  }
  .kursTitle {
    font-size: 16px;
    font-weight: 500;
    color: #d6ff00;
  }
  .container {
    display: inline-block;
    height: 605px;
    background-color: #353945;
    border-radius: 12px;
    padding: 8px;
    overflow: scroll;
    margin-top: 24px;
  }
  .toggleButtonContainer {
    width: 100%;
    max-width: 362px;
    margin: 0 auto;
  }

  .toggleButton {
    background-color: #ffffff29;
    color: white;
    padding: 6px 14px 6px 10px;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;
    transition: 0.3s ease;
    width: 100%;
    height: 40px;
  }

  .container ul {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    max-width: 327px;
    list-style: none;
  }

  .liTitle {
    background: #353945;
    color: #d6ff00;
    text-align: start;
    padding: 12px 15px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .liItem {
    background: #262c39;
    color: whitesmoke;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 15px;
    border-radius: 6px;
    margin-bottom: 8px;
    text-align: start;
    cursor: pointer;
  }
  .selected {
    border: 1px solid #d6ff00;
    color: #d6ff00;
  }
}
