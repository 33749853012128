.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin: 0;
  padding: 0;
}
.calculator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 3px solid black;
  width: 99.2%;
  margin-right: 5px;
  margin-top: 10px;
}
.firstRow {
  display: flex;
  background: #712ea1;
  width: 99.6%;
  border: 3px solid black;

}
.brIgara {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
}
.niz {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
}
.ulog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
}
.kvota {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
}
.eventualniDobitak {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
}
.plusMinus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
}
.minimalnaIsplativaKvota {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
}
.brIgara,
.niz,
.ulog,
.kvota,
.eventualniDobitak,
.plusMinus,
.minimalnaIsplativaKvota {
  border:3px solid black;
  color: whitesmoke;

}
.brIgara > p,
.niz > p,
.ulog > p,
.kvota > p,
.eventualniDobitak > p,
.plusMinus > p,
.minimalnaIsplativaKvota > p {
  font-size: 17px;
  font-weight: bolder;
  text-align: center;
}
.row {
  display: flex;
  border: 3px solid black;
  width: 99.6%;

}

.brIgaraRow > p,
.nizRow > p,
.ulogRow > p,
.kvotaRow > p,
.eventualniDobitakRow > p,
.plusMinusRow > p,
.minimalnaIsplativaKvotaRow > p {
  margin: 0;
  padding: 0;
}
.brIgaraRow {
  width: 175px;
  background: #712ea1;
  color: whitesmoke;
  text-align: center;
  border-right: 3px solid black;
  border-left: 3px solid black;

}
.nizRow {
  width: 130px;
  background: red;
  color: whitesmoke;
  text-align: center;
  border-right: 3px solid black;
  border-left: 3px solid black;

}
/* .nizFirstRow > input[type="text"] {
  width: 77px;
} */
.ulogRow {
  width: 150px;
  color: whitesmoke;
  text-align: center;
  background: red;
  color: whitesmoke;
  text-align: center;
  border-right: 3px solid black;
  border-left: 3px solid black;
}
.kvotaRow2 {
  width: 160px;
  border-right: 3px solid black;
  border-left: 3px solid black;
}

.kvotaRow {
  width: 160px;
  background: red;
  color: whitesmoke;
  text-align: center;
  border-right: 3px solid black;
  border-left: 3px solid black;
}

.eventualniDobitakRow {
  width: 280px;
  text-align: center;
  background: red;
  color: whitesmoke;
  border-right: 3px solid black;
  border-left: 3px solid black;
}

.plusMinusRow {
  width: 190px;
  text-align: center;
  background: red;
  border-right: 3px solid black;
  border-left: 3px solid black;
  color: whitesmoke;
}

.minimalnaIsplativaKvotaRow {
  width: 330px;
  text-align: center;
  border-right: 3px solid black;
  border-left: 3px solid black;  background: red;
  color: whitesmoke;
}

.odvojenoInputPolje {
  width: 89.7%;
  text-align: center;
  border: none;
  font-weight: bolder;
}

.inputPolje {
  width: 152px;
  text-align: center;
  border: none;
  font-weight: bolder;
}
