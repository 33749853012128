.container {
  width: 99.2%;
  margin-right: 7px;
  margin-top: 5px;
  border: 3px solid black;
  border-radius: 8px;
}
.left {
  width: 80%;
  /* height: 284px; */
  height: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-right: 10px;
  color: rgb(206, 5, 5);
  display: flex;
  flex-direction: column;
}
.right {
  width: 20%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
}
/* .segment {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: bolder;
  align-items: center;
  margin: 10px 30px;
  width: 9.2%;
} */
.btn1 {
  padding: 14px;
  border-radius: 8px;
  background: black;
  color: whitesmoke;
  font-size: 16px;
  font-weight: bolder;
  cursor: pointer;
}
.btn2 {
  padding: 14px;
  border-radius: 8px;
  background: lightskyblue;
  color: black;
  font-size: 16px;
  font-weight: bolder;
  cursor: pointer;
}

.rotateP{
  transform: rotate(-90deg);
  writingMode: vertical-rl;
  textOrientation: mixed;

}
