.container {
  flex: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  overflow-y: auto;
  height: calc(100vh - 358px) !important;
  font-weight: 700;
}

.container h3 {
  margin: 0;
}

.sticky {
  position: sticky;
  top: 0;
  padding-bottom: 3px;
  background-color: white;
  overflow: hidden;
}

.stickyInner {
  display: flex;
}

.backButton {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem !important;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  height: 20px;
  flex-shrink: 0;
  cursor: pointer;
}

.backButton svg {
  color: #ff0000;
  text-align: center;
  height: 100%;
  font-size: 3rem;
  display: flex;
  align-items: center;
  width: 20px;
  transform: scale(1.8);
}

.backButton span {
  margin-left: -2px;
}

.componentHeader {
  height: 80px;
  display: flex;
  background-color: #000064;
  padding: 1rem;
  gap: 1rem;
  border-radius: 0.5rem;
}

.componentHeader button {
  color: white;
  background-color: #f64027;
  border: none;
  padding: 1rem;
  font-size: 1.2rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.componentHeader button svg {
  color: black;
  height: 50px;
  width: 50px;
}
.componentHeader input {
  flex: 1;
  border: 0;
  font-size: 2rem;
  outline: none;
}

.xx {
  width: 100%;
}

.tableTitle .gameDescription {
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  padding: .4rem 1rem;
  box-sizing: border-box;
  font-size: 1.1rem;
  font-weight: 700;
  width: 190px;
  cursor: pointer;
  justify-self: flex-start;
}

.tableTitle {
  background-color: #000064;
  color: #1bf836;
  font-size: 2rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3px 0;
}

.isGlobalTable {
  justify-content: center;
}

.isGlobalTable .tablePeriodPicker {
  display: none;
}

.tablePeriodPicker input,
.tablePeriodPicker span {
  font-weight: 700 !important;
  font-size: 1.1rem !important;
  text-align: center;
}

.tableHeader {
  background-color: white;
}

.tableHeader,
.tableBody {
  display: flex;
  gap: 5px;
  width: 100%;
  height: 40px;
}

.tableHeader > div,
.tableItem > div {
  background-color: #0914f1;
  color: white;
  font-size: 1.3rem;
  padding: 1rem;
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  box-sizing: border-box;
}

.tableHeader > div {
  background: linear-gradient(to right, #033181, #162a58);
  color: #1bf836 !important;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
}

.tableBody {
  flex-direction: column;
}

.pagination {
  position: sticky;
  background-color: white;
  bottom: 0;
}

.tableItem {
  display: flex;
  gap: 5px;
}

.tableItem > div {
  background-color: #00143d;
  color: #f64027;
}

.totalHeaderTotalRank,
.totalItemTotalRank {
  width: 120px;
  flex-shrink: 0;
}

.tableCurrentUser {
  display: flex;
  justify-content: space-between !important;
  cursor: pointer;
}

.tableCurrentUser div { 
  flex: 1;
  max-width: 100px;
}

.editBtn {
  margin-right: -.9rem;
  background-color: #2932F1;
  padding: .55rem;
  border-radius: .5rem;
  border: none;
  font-weight: 700;
  color: white;
  cursor: pointer;
}

.tableHeaderRank,
.tableItemRank {
  width: 120px;
  background-color: #f64027 !important;
  color: #00143d !important;
}

.tableHeaderTeam,
.tableItemTeam {
  width: 90px;
}

.tableHeaderPlayer,
.tableItemPlayer {
  flex: 3;
  flex-shrink: 0;
  cursor: pointer;
}

.tableHeaderTotalScore,
.tableItemTotalScore {
  flex: 1;
  flex-shrink: 0;
}

.tableItemTotalScore {
  color: white !important;
  background-color: #f64027 !important;
}

.tableItemTeam {
  background-color: white !important;
  padding: 0 !important;
  border: 2px solid black;
}

.tableItemTeam img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.totalHeaderTotalRank {
  rotate: 180deg;
  background-color: green;
  padding: 20px;
  box-sizing: border-box;
  max-height: 100%;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  text-align: center;
  vertical-align: center;
  margin-right: 5px;
  background-color: #00143d;
  color: #ff0000;
  font-weight: 700 !important;
  font-size: 1.3rem;
  padding: 0.6rem;
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.rankSeparator {
  color: #f64027;
  background-color: #00143d;
  font-size: 2.5rem;
  padding: 1.5rem;
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  box-sizing: border-box;
  padding-left: 180px;
  font-weight: 700;
  margin-top: 3px;
}

.tableItemWrapper {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.tableItemAccent {
  background-color: #0914f1 !important;
  color: white !important;
}

.currentSeparator {
  margin: 5px 0;
}

.currentUserTableItem .totalItemTotalRank {
  background-color: #f64027;
  color: #00143d;
}

.currentUserTableItem .tableItemRank {
  color: #f64027 !important;
  background-color: #00143d !important;
}

.currentUserTableItem .tableItemPlayer {
  background-color: #f64027 !important;
  color: #00143d !important;
}

.currentUserTableItem .tableItemTotalScore {
  color: #f64027 !important;
  background-color: #00143d !important;
}

.noData {
  margin: 3rem auto;
  font-size: 2rem;
  font-weight: 700;
}

.spinner {
  height: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
