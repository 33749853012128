.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  overflow: auto;
  align-items: center;
  margin: 0;
  padding: 0;
}

.calculator {
  background-image: url("../kalkulatoriSlikaPozadina/bejzbolSlika.png");
  background-color: #cccccc;
  width: 100%;
  /* height: 284px; */
  height: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 5px;
  margin-right: 10px;
}

.procenatPobede1 {
  position: absolute;
  color: white;
  font-size: 32px;
  left: 180px;
  color: #FC6A03;
  font-size: 50px;
  font-weight: bolder;
}
.procenatPobede2 {
  position: absolute;
  color: white;
  font-size: 32px;
  left: 540px;
  color: #FC6A03;
  font-size: 50px;
  font-weight: bolder;
}
.opcijaTacnogRezultata1 {
  position: absolute;
  top: 65px;
  left: 1020px;
  color: white;
  top: 6px;
  right: 385px;
  color: #062C82;
  font-size: 32px;
  font-weight: bolder;
  font-size: 70px;
}
.opcijaTacnogRezultata2 {
  position: absolute;
  top: 65px;
  left: 1280px;
  color: white;
  font-size: 32px;
  top: 6px;
  right: 120px;
  color: #062C82;
  font-weight: bolder;
  font-size: 70px;
}
.bodBalans {
  position: absolute;
  top: 143px;
  left: 660px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.bodMin {
  position: absolute;
  top: 100px;
  left: 660px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.bodMax {
  position: absolute;
  top: 185px;
  top: 186px;
  left: 660px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.betkopredlog {
  position: absolute;
  top: 130px;
  left: 1080px;
  top: 130px;
  right: 250px;
  color: white;
  font-size: 70px;
  font-weight: bolder;
}
