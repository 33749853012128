.container {
  flex: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  overflow-y: auto;
  height: calc(100vh - 358px) !important;
  font-weight: 700;
}

.container h3 {
  margin: 0;
}

.sticky {
  position: sticky;
  top: 0;
  padding-bottom: 3px;
  background-color: white;
}

.pickerWrapper{ 
  background-color: white;
  display: flex;
  border-radius: .5rem;
}

.backButton {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem !important;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  height: 20px;
  flex-shrink: 0;
  cursor: pointer;
}

.backButton svg {
  color: #ff0000;
  text-align: center;
  height: 100%;
  font-size: 3rem;
  display: flex;
  align-items: center;
  width: 20px;
  transform: scale(1.8);
}

.backButton span {
  margin-left: -2px;
}

.componentHeader {
  height: 80px;
  display: flex;
  background-color: #000064;
  padding: 1rem;
  gap: 1rem;
  border-radius: 0.5rem;
}

.componentHeader button {
  color: white;
  background-color: #f64027;
  border: none;
  padding: 1rem;
  font-size: 1.2rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.componentHeader button svg {
  color: black;
  height: 50px;
  width: 50px;
}
.componentHeader input {
  flex: 1;
  border: 0;
  font-size: 2rem;
  outline: none;
}

.tableTitle {
  background-color: #000064;
  color: #1bf836;
  font-size: 2rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin: 3px 0;
}

.tableTitle .gameDescription {
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  padding: 0rem 2rem;
  box-sizing: border-box;
  font-size: 1.1rem;
  font-weight: 700;
  width: 190px;
  cursor: pointer;
  justify-self: flex-start;
}

.isGlobalTable {
  justify-content: flex-start;
}

.isGlobalTable .gameDescription {
  margin-right: 210px;
}

.tablePeriodPicker input,
.tablePeriodPicker span {
  font-weight: 700 !important;
  font-size: 1.1rem !important;
  text-align: center;
}

.isGlobalTable .tablePeriodPicker {
  display: none;
}
.tableHeader {
  background-color: white;
}

.tableHeader,
.tableBody {
  display: flex;
  gap: 5px;
  width: 100%;
  height: 40px;
}

.tableHeader > div,
.tableItem > div {
  background-color: #0914f1;
  color: white;
  font-size: 1.3rem;
  padding: 1rem;
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  box-sizing: border-box;
}

.tableBody {
  flex-direction: column;
}

.pagination {
  position: sticky;
  background-color: white;
  bottom: 0;
}

.tableItem {
  display: flex;
  gap: 5px;
}

.tableItem > div {
  background-color: #00143d;
  color: #f64027;
}

.tableHeaderRank,
.tableItemRank {
  width: 20%;
}

.tableHeaderTeam,
.tableItemTeam {
  width: 10%;
}

.tableHeaderPlayer,
.tableItemPlayer {
  flex: 50%;
  cursor: pointer;
}


.tableCurrentUser {
  display: flex;
  justify-content: space-between !important;
  cursor: pointer;
}

.tableCurrentUser div { 
  flex: 1;
  max-width: 100px;
}

.editBtn {
  margin-right: -.80rem;
  background-color: #2932F1;
  padding: .55rem;
  border-radius: .5rem;
  border: none;
  font-weight: 700;
  color: white;
  cursor: pointer;
}


.tableHeaderTotalScore,
.tableItemTotalScore {
  width: 20%;
}

.tableItemTotalScore {
  color: white !important;
  background-color: #f64027 !important;
}

.tableItemTeam {
  background-color: white !important;
  padding: 0 !important;
  border: 2px solid black;
}

.tableItemTeam img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.currentUserTableItem {
  margin-top: 3px;
}

.currentUserTableItem .tableItemRank {
  background-color: #f64027;
  color: #01143f;
}

.currentUserTableItem .tableItemPlayer {
  background-color: #f64027;
  color: #01143f;
}
.currentUserTableItem .tableItemScore {
  color: #f64027;
  background-color: #01143f;
}

.noData {
  margin: 3rem auto;
  font-size: 2rem;
  font-weight: 700;
}
