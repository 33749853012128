.singlePost {
  width: 100%;
  padding: 10px 20px;
  border: 2px solid blueviolet;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
}
.left {
  display: flex;
  align-items: center;
}
.right {
}
.singlePost > .left > p {
  padding: 0;
  font-size: 20px;
  margin: 10px;
}
.title {
  color: aqua;
  margin-right: 8px;
}
.btn {
  width: 80px;
  padding: 8px;
  margin: 0 12px;
  font-size: 18px;
  color: aqua;
  text-align: center;
  background-color: black;
  border: 1px solid aqua;
  border-radius: 12px;
  cursor: pointer;
}
