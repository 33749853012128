.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 102px);
  width: 100%;
  align-items: center;
}
.container > h1 {
  margin-bottom: 35px;
}
.top {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.postContainer {
  padding: 10px 50px;
  overflow: auto;
}
