.container {
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.addButton {
  margin-bottom: 20px;
  padding: 1rem;
}

.categoriesTable {
  width: 100%;
  flex: 1;
}

.editInput { 
  width: 100%;
  
}