.backBtnWrapper * {
  box-sizing: border-box;
}

.backBtnWrapper {
  position: sticky;
  top: 0;
  width: 100%;
  padding: 5px 0;
  background-color: #fff;
}

.backButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--a-color);
  color: var(--t-color);
  border: 1px solid white;
  font-size: 1.1rem;
  border-radius: 0.6rem;
  padding: .3rem .6rem;
  padding-right: .8rem;
  cursor: pointer;
}

.backButton svg {
  color: red;
  height: 30px ;
  flex-shrink: 0;
  width: 30px ;
}
