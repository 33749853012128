.tipsterPage {
  flex: 1;
  overflow: auto;
  padding: 1rem;
  padding-top: 0;
  margin-bottom: 2rem !important;
  font-weight: 900;
  position: relative;
  scrollbar-width: none;
}

.buttonWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3px;
  justify-content: center;
}

.buttonWrapper button {
  cursor: pointer;
  border: 2px solid black;
  border-radius: 8px;
  padding: 0.5rem;
  font-size: 1.6rem;
  font-family: Arial Black, sans-serif;
}

.sticky {
  position: sticky;
  top: 0;
  padding: 3px 0;
  background-color: white;
  overflow: hidden;
}

.topBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -1rem 0;
}

.topBtns .mailNotificationCheckbox,
.topBtns button {
  background-color: #0914f1;
  height: 100%;
  border: none;
  color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  height: 50px;
  box-sizing: border-box;
  padding: 1rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.topBtns h3 {
  margin: 0;
  padding: 0;
  font-weight: 900;
  font-family: Arial Black, sans-serif;
  color: red;
  font-size: 3.5rem;
}

.topBtns button {
  cursor: pointer;
}

.mailNotificationCheckbox {
  gap: 8px;
}

.mailNotificationCheckbox > label {
  display: flex;
  align-items: center;
  color: white;
  border-radius: 10px;
  text-transform: uppercase;
  text-align: center;
  flex: 1;
  min-height: 40px;
}

.mailNotificationCheckbox > div {
  display: block;
  height: 100%;
  min-width: 40px;
  border: 1px solid #0914f1;
  border-radius: 5px;
  box-sizing: border-box;
}

.mailNotificationCheckbox > div > input {
  min-width: 40px;
  min-height: 40px;
  appearance: none;
  position: relative;
  box-sizing: border-box;
  padding: 0;
  background-color: white;
  cursor: pointer;
  margin-top: -13px;
}

.mailNotificationCheckbox > div > input:checked::before {
  content: '\2713';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: #0914f1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: white;
  border-radius: 0.5rem;
}

.backAndTitle {
  display: flex;
  gap: 5px;
  align-items: center;
}

.backAndTitle button {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem !important;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  min-width: 150px;
  flex-shrink: 0;
  cursor: pointer;
}

.backAndTitle button svg {
  color: #ff0000;
  text-align: center;
  height: 100%;
  font-size: 5rem;
  display: flex;
  align-items: center;
  margin: 0 -20px;
}

.dailyTipsterTitle {
  background-color: #00143d;
  color: #00ea42;
  font-family: Arial Black, sans-serif;
  font-size: 2.45rem !important;
  padding: 0.5rem 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  flex: 1;
}

.greenBtn {
  background-color: #24fe7b;
  color: black;
}

.blueBtn {
  background-color: #1d3cf7;
  color: white;
}

.tipsterMatchTableHeader {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 6px 0;
  font-size: 5rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
}

.tipsterMatchTableHeader .x12Div {
  font-size: 4.5rem;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  border: 3px solid black;
  border-radius: 0.5rem;
  padding: 0rem 0.7rem;
  text-align: center;
  align-items: center;
  font-family: Arial Black, sans-serif;
  box-sizing: border-box;
}

.x12 {
  padding: 0 0.5rem;
  margin: -0.7rem 0;
  display: block;
}
.x12 span {
  color: red;
}

.tipsterMatchTableHeader .clickOnTip {
  font-size: 1rem;
  color: #070de6;
}

.tipsterMatchTableHeader .dailyPairsTitle {
  background-color: #070de6;
  color: white;
  font-size: 4.3rem;
  flex: 1;
  text-align: center;
  margin-left: 0.5rem;
  padding: 0.2rem;
  border-radius: 8px;
  border: 1px solid black;
  font-family: Arial Black, sans-serif;
}

.tipsterMatchContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.placeYourBetsBtn {
  background-color: #0914f1;
  color: white;
  width: 100%;
  border: none;
  padding: 0.5rem;
  font-size: 2rem;
  border-radius: 0.5rem;
  margin: 1rem 0;
  font-weight: 700;
  cursor: pointer;
  position: sticky;
  bottom: 5px;
  border: 2px solid black;
}

.empty {
  text-align: center;
  font-size: 2rem;
  font-family: Arial Black, sans-serif;
}

.loginModal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;
}

.ant-modal-content {
  padding: 0 !important;
}
