.categoriesSidebar {
  background-color: #0b0b25;
  flex-shrink: 0;
  border-radius: 0.5rem;
  flex: 1;
  padding-bottom: 3rem;
  scrollbar-width: none;
  height: calc(100vh - 446px);
  overflow: auto;
}

.categoriesSidebar ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  list-style: none;
  font-size: 1.1rem;
}

.categoriesSidebar li {
  width: 100%;
  text-align: center;
  padding: 0.1rem 0;
}

.categoriesSidebar button {
  background-color: transparent;
  border: none;
  color: white;
  text-transform: uppercase;
}

.categoriesSidebar .emptyCategory {
  color: red;
}

.selectedCategory {
  background-color: #38f81e;
}

.selectedCategory button {
  color: black !important;
}

@media (min-width: 765px) {
  .categoriesSidebar {
    height: 100%;
    overflow: auto;
    width: 200px;
    flex: auto;
    flex-shrink: 0;
  }
}
