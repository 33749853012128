.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 65%;
  height: 100%;
  overflow: auto;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-bottom: 40px;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.msg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 32px;
  font-weight: bold;
}