.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  height: calc(100vh -300px) !important;
  overflow-y: auto;
  padding: 30px;
}

.container::-webkit-scrollbar {
  width: 15px !important;
  height: 10px !important;
}

.container::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 4px !important;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.container::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 4px !important;
}

.editor {
  min-height: calc(100vh - 330px) !important;
  overflow: auto;
  background: whitesmoke;
  color: black;
  border-radius: 15px;
}
.bottomContainer {
  display: flex;
}
.containerLeft {
  width: 100%;
}
.containerRight {
  width: 100%;
  margin-top: 16px;
}
.singleSearch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.singleSearch > p {
  margin: 0;
}
.input {
  margin-left: 14px;
  width: 65%;
  border-radius: 5px;
  padding: 6px;
}

.btn {
  margin-top: 15px;
  padding: 18px;
  background: transparent;
  border: 2px solid aqua;
  border-radius: 8px;
  color: aqua;
  font-size: 18px;
  cursor: pointer;
  margin-left: 8px;
}
.errorDiv {
  width: 65%;
  font-size: 24px;
  color: red;
  text-align: right;
}
.errorWithBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.statistikaFooter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.main {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}
.main p {
  margin-right: 10px;
  width: 20%;
}

.main input {
  width: 80%;
  border-radius: 10px;
}

.imageInput {
  margin-top: 10px;
  display: none; /* This will hide the default input */
}

.imagesList {
  width: 80%;
  max-height: 150px !important;

  display: flex;
  flex-wrap: wrap;
}

.imageItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
  margin-top: 20px;
  min-height: 180px !important;
  margin-right: 10px;
}

.imageItem img {
  max-height: 200px !important;
  border-radius: 10px;
}

.delButton {
  background: transparent;
  position: relative;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid rgb(255, 0, 0);
  border-radius: 25px;
  outline: none;
  overflow: hidden;
  color: rgb(255, 0, 0);
  transition: color 0.3s 0.1s ease-out;
  text-align: center;
}

.delButton span {
  margin: 10px;
}

.delButton::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  content: "";
  border-radius: 50%;
  display: block;
  width: 20em;
  height: 20em;
  left: -5em;
  text-align: center;
  transition: box-shadow 0.5s ease-out;
  z-index: -1;
}

.delButton:hover {
  color: #fff;
  border: 1px solid rgb(255, 0, 0);
  background-color: 1px solid rgb(255, 0, 0);
}

.delButton:hover::before {
  box-shadow: inset 0 0 0 10em rgb(255, 0, 0);
}

.imageInput + label {
  padding: 10px 15px;
  background-color: #007bff; /* Blue color by default */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.imageInput + label:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.imageInput:active + label {
  background-color: #003580; /* Even darker blue on active click */
}

.imageInput:focus + label {
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Blue glow when focused */
}

.imageInput:disabled + label {
  background-color: #d1d1d1; /* Gray color when disabled */
  cursor: not-allowed;
}

.imageDiv {
  margin: 15px 0 0 20px;
}
