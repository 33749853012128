.bgContainer {
  padding: 0;
  margin: 0;
  background-color: #353945;
  width: 100%;
}

.container {
  margin: 0px 16px;
  width: 100%;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-items: center;
  box-sizing: border-box;

  background-color: #353945;

  padding-bottom: 16px;
}
.backButtonContainer {
  width: 100%;
  text-align: center;
}
.backButton {
  width: 100%;
  padding: 8px 16px 8px 12px;
  background-color: #ffffff29;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  font-weight: 500;
}
.backButton > img {
  width: 20px;
  height: 20px;
}
.buttonContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 16px;
  gap: 12px;
}
.buttonContainer > button {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
}
.button {
  width: 100%;
  border: 1px solid #212631;
  padding: 6px 12px 6px 6px;

  background-color: #2b3240;
  color: #fff;
  border-radius: 12px;
}
.linkArrow {
  width: 36px;
  height: 36px;
  background-color: #ffffff29;
  border-radius: 36px;
  color: #d6ff00;
  padding: 8px;
}
.downloadContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
}
.downloadContainer > p {
  color: #ffffffd1;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.downloadButton > img {
  width: 18px;
  height: 18px;
}
.downloadButton {
  display: flex;
  align-items: center;
  background-color: #d6ff00;
  border: 1px solid #b8db00;
  padding: 4px 12px 4px 8px;
  border-radius: 8px;
  gap: 6px;
  width: 100%;
  height: 40px;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  margin-top: 16px;
}
@media (min-width: 1024px) {
  .bgContainer {
    display: block;
    background-color: #2b3240;
  }
  .container {
    margin-left: 0;
    background-color: #353945;
    border: 1px solid #212631;
    padding: 16px;
    padding-left: 0px;
  }
  .buttonContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    padding: 0px 0px 0px 16px;
    margin-bottom: 0px;
    width: 100%;
    max-width: 496px;
  }
  .downloadContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 496px;
    margin-top: 0px;
    white-space: nowrap;
    padding-left: 8px;
  }

  .downloadContainer > p {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    margin: 0;
  }
  .downloadButton {
    width: 194px;
    margin: 0px;
  }
  .backButton {
    display: none;
  }
}
