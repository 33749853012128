.spPage {
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-sizing: border-box;
  margin-bottom: 3rem;
  font-weight: 700;
  padding: 0 30px;
  scrollbar-width: none;
  position: relative;
}

.spPage p {
  color: #0066cc;
  font-size: 1.5em;
}

.spPage p span {
  display: block;
}
.spPage h2 {
  font-size: 1.7rem;
}
.spPage h1,
.spPage h2 {
  text-align: center;
  color: red;
}

.spPage h3 {
  text-decoration: underline;
  color: #0066cc;
  font-size: 1.6rem;
}

.underline {
  text-decoration: underline;
}

.inline {
  display: inline !important;
}

.parWithPadding {
  padding-inline: 5rem;
}