.packagesPageWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 180px) !important;
  padding: 0 2rem;
}

.topWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
}
.packagesModal {
  width: 799px !important;
}
.packagesModal span {
  margin-inline: 30px;
}
.packagesModal textarea {
  width: 100%;
  min-height: 200px;
}

.packagesList li {
  border: 2px solid white;
}

.packagesTable {
  display: flex;
  width: 100%;
  overflow: auto;
}

.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.packagesContent {
  padding-left: 1500px;
}
