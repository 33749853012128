.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  flex-wrap: wrap;
  /* padding-left: 8px; */
  /* padding-bottom: 40px; */
  box-sizing: border-box;
  position: relative;
  scrollbar-width: none;
}

.infoBlockContainer {
  width: 100%;
  justify-content: flex-start;
  margin-top: 24px;
}

.container::-webkit-scrollbar {
  width: 15px !important;
  height: 10px !important;
}

.container::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 4px !important;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.container::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 4px !important;
}

.postsPageContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  overflow: auto;
  padding-top: 24px;
  box-sizing: border-box;
  position: relative;
  scrollbar-width: none;
  width: 100%;
}
.pageTitleContainer {
  width: 100% !important;
}
.pageTitle {
  color: var(--a-color);
  border-bottom: 2px solid var(--a-color);
  width: 100%;
  padding-bottom: 10px;
  text-align: center;
  margin-block: 1.5rem;
  display: block;
}
.backButton {
  display: none;
}
.postsAndCalculatorWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 16px;
}

.postsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  width: 100%;
  justify-content: center;
}

@media (min-width: 1024px) {
  .postsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    width: 100%;
    max-width: 528px;
  }
  .pageTitleContainer {
    width: 80% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .semaforiTitleContainer {
    margin-block: 0px;
    height: 100%;
    max-height: 58px;
  }
  .semaforpostsAndCalculatorWrapper {
    padding-top: 24px;
  }

  .backButton {
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    cursor: pointer;
    position: absolute;
    left: 4px;
    background-color: #ffffff29;
    color: #fff;
    border: 1px solid #d0d5dd;
    padding: 8px 16px 8px 12px;
    margin-bottom: 6px;
    font-weight: 500;
  }
  .backButton > img {
    width: 20px;
    height: 20px;
  }
}
