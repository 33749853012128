.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 180px) !important;
  padding: 30px;
  overflow: hidden;
  overflow-y: auto;
}

.container::-webkit-scrollbar {
  width: 15px !important;
  height: 10px !important;
}

.container::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 4px !important;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.container::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 4px !important;
}
.editor {
  min-height: calc(100vh - 330px) !important;
  overflow: auto;
  background: whitesmoke;
  color: black;
  border-radius: 15px;
}
.bottomContainer {
  display: flex;
}
.containerLeft {
  width: 100%;
}
.containerRight {
  width: 100%;
  margin-top: 16px;
}
.singleSearch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.singleSearch > p {
  margin: 0;
}
.input {
  margin-left: 14px;
  width: 65%;
  border-radius: 5px;
  padding: 6px;
}

.btn {
  margin-top: 15px;
  padding: 18px;
  background: transparent;
  border: 2px solid aqua;
  border-radius: 8px;
  color: aqua;
  font-size: 18px;
  cursor: pointer;
  margin-left: 8px;
}
.errorDiv {
  width: 65%;
  font-size: 24px;
  color: red;
  text-align: right;
}
.errorWithBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.statistikaFooter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.main {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}
.main p {
  margin-right: 10px;
  width: 20%;
}

.main input {
  width: 80%;
  border-radius: 10px;
}

.textitem {
  display: flex;
  width: 100%;
  align-items: center;
}

.textitem p,
input {
  margin-right: 5px;
}

.textitem input {
  border-radius: 10px;
}

.file-input-container {
  position: relative;
  width: 200px;
}

.file-input {
  display: none;
}

.file-label {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4caf50; /* Green */
  color: white;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.file-label:hover {
  background-color: #45a049;
}

.imagesList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.imageItem {
  max-width: 18%;
  min-width: 18%;
  margin-right: 10px;
  max-height: 180px !important;
  display: flex;
}

.imageItem img {
  max-width: 100%;
  min-width: 100%;
  max-height: 100px !important;
  border-radius: 10px;
}

.addImageBtn {
  background-color: #007bff; /* Primary blue color */
  border: none;
  color: white; /* White text color */
  padding: 12px 24px; /* Padding to make the button larger */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px; /* Rounded corners */
  transition-duration: 0.4s; /* Smooth transition for hover effect */
}

.addImageBtn:hover {
  background-color: #0056b3; /* Darken the button color when hovered */
  color: white;
}

.submitButton {
  background-color: #007bff; /* Primary blue color */
  border: none;
  color: white; /* White text color */
  padding: 12px 24px; /* Padding to make the button larger */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 24px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px; /* Rounded corners */
  transition-duration: 0.4s; /* Smooth transition for hover effect */
}

.submitButton:hover {
  background-color: #0056b3; /* Darken the button color when hovered */
  color: white;
}

.submitButton:disabled {
  background-color: #5a5a5a; /* Darken the button color when hovered */
  color: white;
}

.textAreaInput {
  border-radius: 8px;
  margin-right: 10px;
  width: 400px;
  background-color: white !important;
}

.inputNumberMargin {
  background-color: white !important;
}

.inputMarketingLink {
  background-color: white !important;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.item input {
  min-height: 25px;
}

.colorSelect {
  min-width: 150px;
  margin-right: 10px;
}

.textitem {
  padding-bottom: 15px;
  border-bottom: 1px dashed #707070;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.textItemLeft {
  display: flex;
  align-items: center;
}

.textitem Select {
  margin-right: 10px;
}

.imageitem {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px dashed #707070;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.imageitemLeft {
  display: flex;
  align-items: center;
}

.labelRow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 40px;
  color: white;
  background-color: #070044;
  font-size: 24px;
  font-weight: bolder;
  border-radius: 10px;
  border: 1px solid white;
  margin: 10px 0 10px 0;
}

.arrows {
  display: flex;
  flex-direction: column;
}

.svgimg:hover {
  filter: brightness(0) saturate(100%) invert(34%) sepia(94%) saturate(7500%)
    hue-rotate(216deg) brightness(99%) contrast(101%);
}

.selectVideoButton {
  background-color: #007bff; /* Primary blue color */
  border: none;
  color: white; /* White text color */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px; /* Rounded corners */
  transition-duration: 0.4s; /* Smooth transition for hover effect */
}

.selectVideoButton:hover {
  background-color: #0056b3; /* Darken the button color when hovered */
  color: white;
}

.videoItem {
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.videosList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.videoItemLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}
