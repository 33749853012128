.apkUrlPageWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 180px) !important;
  padding: 0 2rem;
}

.apkUrlPageWrapper * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.apkUrlPageWrapper a  {
  color: white;
  font-size: 1.5rem;
}

.topWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;
}

.topWrapper button {
  background-color: #1d3cf7;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: white;
  border: 2px solid white;
  font-weight: 700;
  border-radius: 0.5rem;
  cursor: pointer;
}

.apkUrlPageWrapper input {
  padding: 1rem;
  background-color: wheat;
  color: black;
  font-size: 1.1rem;
}

.apkUrlModal {
  width: 300px !important;
}
.apkUrlModal .upload{ 
  width: 100% ;
  margin: 2rem auto !important;
}
.apkUrlModal input  { 
  width: 100% !important;
}