.deniedPageContianer * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.deniedPageContianer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  font-family: Arial Black, sans-serif;
  font-weight: 900;
  scrollbar-width: none;
  overflow: auto;
  padding-bottom: 3rem;
}

.deniedPageContianer .iconWrapper {
  height: 120px;
  display: flex;
  justify-content: center;
  aspect-ratio: 1/1;
  align-items: center;
  border-radius: 100%;
}

.deniedPageContianer .iconWrapper svg {
  color: #fe0f17;
  width: 100%;
  height: 100%;
}

.deniedPageContianer h2 {
  font-size: 3rem;
  color: #fe0f17;
  text-shadow: -4px -4px 0 black, 4px -4px 0 black, -4px 4px 0 black,
    4px 4px 0 black;
}

.deniedPageContianer p {
  font-size: 1.2rem;
  text-align: center;
  margin-block: 1.5rem;
}

.deniedPageContianer button {
  width: 80%;
  color: white;
  text-shadow: -1.5px -1.5px 0 black, 1.5px -1.5px 0 black,
    -1.5px 1.5px 0 black, 1.5px 1.5px 0 black;
  border: 3px solid black;
  font-size: 2rem;
  border-radius: 0.5rem;
  background-color: #fe0f17;
  cursor: pointer;
  padding: 0.5rem;
}
