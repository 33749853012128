.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  overflow: auto;
  align-items: center;
  margin: 0;
  padding: 0;
}
.calculator {
  background-image: url("../kalkulatoriSlikaPozadina/odbojkaNaPeskuSlika.png");
  background-color: #cccccc;
  width: 100%;
  /* height: 284px; */
  height: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 5px;
  margin-right: 10px;
}
.prvi {
  position: absolute;
  top: 10px;
  left: 110px;
  color: white;
  font-size: 42px;
  font-weight: bolder;
}
.drugi {
  position: absolute;
  top: 10px;
  left: 450px;
  color: white;
  font-size: 42px;
  font-weight: bolder;
}
.maxSetova {
  position: absolute;
  top: 140px;
  left: 840px;
  color: white;
  font-size: 42px;
  font-weight: bolder;
}

.treci {
  position: absolute;
  top: 10px;
  left: 840px;
  color: white;
  font-size: 42px;
  font-weight: bolder;
}

.cetvrti {
  position: absolute;
  top: 10px;
  left: 1220px;
  color: white;
  font-size: 42px;
  font-weight: bolder;
}

.peti {
  position: absolute;
  top: 140px;
  left: 510px;
  color: white;
  font-size: 42px;
  font-weight: bolder;
}

.minSetova {
  position: absolute;
  top: 140px;
  left: 160px;
  color: white;
  font-size: 42px;
  font-weight: bolder;
}

.betkopredlog {
  position: absolute;
  top: 155px;
  left: 82.5%;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
