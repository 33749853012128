.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 180px) !important;
  padding: 30px;
  overflow: hidden;
  overflow-y: auto;
}

.container::-webkit-scrollbar {
  width: 15px !important;
  height: 10px !important;
}

.container::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 4px !important;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.container::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 4px !important;
}

.statistikaFooter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.submitButton {
  background-color: #007bff; /* Primary blue color */
  border: none;
  color: white; /* White text color */
  padding: 12px 24px; /* Padding to make the button larger */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 24px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px; /* Rounded corners */
  transition-duration: 0.4s; /* Smooth transition for hover effect */
}

.submitButton:hover {
  background-color: #0056b3; /* Darken the button color when hovered */
  color: white;
}

.submitButton:disabled {
  background-color: #5a5a5a; /* Darken the button color when hovered */
  color: white;
}

.main {
  display: flex;
  flex-wrap: wrap;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.card {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid rgb(255, 255, 255);
  width: 30%;
  padding: 10px;
  margin: 0 10px 0 10px;
}

.field {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}
.field p {
  margin-right: 10px;
}

.field input {
  border-radius: 10px;
}

.addImageBtn {
  background-color: #007bff; /* Primary blue color */
  border: none;
  color: white; /* White text color */
  padding: 12px 24px; /* Padding to make the button larger */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px; /* Rounded corners */
  transition-duration: 0.4s; /* Smooth transition for hover effect */
  overflow: hidden;
}

.addImageBtn:hover {
  background-color: #0056b3; /* Darken the button color when hovered */
  color: white;
}

input {
  border-radius: 5px;
  padding: 6px;
}

.imagesList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.imageItem {
  max-width: 200px;
  min-width: 200px;
  margin-right: 10px;
  max-height: 150px !important;
  min-height: 150px !important;
  padding: 5px;
}

.imageItem img {
  max-width: 200px;
  min-width: 200px;
  max-height: 150px !important;
  min-height: 150px !important;
  border-radius: 10px;
}

.addImageBtn {
  background-color: #007bff; /* Primary blue color */
  border: none;
  color: white; /* White text color */
  padding: 12px 24px; /* Padding to make the button larger */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px; /* Rounded corners */
  transition-duration: 0.4s; /* Smooth transition for hover effect */
}

.addImageBtn:hover {
  background-color: #0056b3; /* Darken the button color when hovered */
  color: white;
}

.selectVideoButton {
  background-color: #007bff; /* Primary blue color */
  border: none;
  color: white; /* White text color */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px; /* Rounded corners */
  transition-duration: 0.4s; /* Smooth transition for hover effect */
}

.selectVideoButton:hover {
  background-color: #0056b3; /* Darken the button color when hovered */
  color: white;
}

.videoItem {
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.videosList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.videoItemLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}
