.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  height: 100%;
  overflow: auto;
  flex-wrap: wrap;
  padding-bottom: 40px;
  box-sizing: border-box;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.msg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 32px;
  font-weight: bold;
}
