.familyPageContainer {
  flex: 1;
  overflow: auto;
  padding: 0 2rem;
  padding-top: 0;
  font-weight: 900;
  position: relative;
}

.familyPageContainer * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Comic Sans', sans-serif;
  color: white;
  font-weight: 900;
}

.textContainer {
  background-color: #203864;
  border-radius: 2rem;
  text-align: center;
  flex: 1;
  margin: 1rem 0;
  scrollbar-width: none;
  padding: 2rem;
  border: 4px solid black;
}

.join {
  font-size: 1.2rem !important;
  margin-top: -0.1rem !important;
}

.familyPageContainer h2,
.green,
.email {
  color: #47FD54;
}

.green {
  font-size: 1.1rem !important;
}

.familyPageContainer h2 {
  font-size: 2rem;
}

.familyPageContainer h3 {
  color: #FF0000;
  font-size: 1.2rem;
}

.familyPageContainer p {
  font-size: 0.9rem;
  margin: 1rem 0;
}

.emailBetko {
  font-size: 2rem !important;
  color: #4ffc65;
}
