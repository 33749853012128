.productPreview * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.productPreview {
  display: flex;
  gap: 1rem;
  font-weight: 700;
  width: 100%;
  overflow: hidden;
  height: calc(100vh - 585px);
  text-align: center;
}

.productImageWrapperDesktop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 350px;
  aspect-ratio: 1/1;
  flex-shrink: 0;
}

.productImageWrapperDesktop img {
  height: 100%;
  width: 100%;
  object-fit: contain !important;
}

.productInfo {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 5px;
  height: 340px;
  flex: 1;
  height: 100%;
  overflow: auto;
}

.productTitle {
  color: black;
  text-transform: uppercase;
  font-size: 2rem;
  padding: 0.2rem;
  flex-shrink: 0;
}

.productDescription {
  font-size: 1rem;
  padding: 0.2rem;
  text-align: start;
  white-space: pre-line;
}

.productPriceSeparator {
  color: white;
  background-color: #0b0b25;
  padding: 0.5rem;
  text-align: center;
  width: 100%;
  border-radius: 0.5rem;
}

.productPrice {
  color: #022161;
  font-size: 1.5rem;
  text-align: center;
}

.productStock {
  background: linear-gradient(to right, #14cc69, #04617d);
  width: 100%;
  color: white;
  padding: 0.3rem 0 !important;
  font-size: 1.3rem;
  text-align: center;
  border-radius: 0.5rem;
}

.outOfStock {
  background: linear-gradient(to right, #e20000, #790303);
}

.outOfStock {
  background: linear-gradient(to right, #e20000, #790303);
}

.auctionExpireDate,
.productCategory,
.tourDate {
  color: #0b0b25;
  font-weight: 700;
}

.expiredAuction {
  color: red;
}

.productDescription {
  display: inline;
  margin-block: 1rem;
}

.seeMoreBtn {
  cursor: pointer;
  font-family: Comic Sans, sans-serif;
  font-size: 14px;
  font-weight: 700;
  border: 2px solid #0b0b25;
  border-radius: 0.5rem;
  padding: 0 0.2rem;
}

.attributesTable {
  width: 100%;
  border-collapse: collapse;
}

.attributesTable th,
.attributesTable td {
  border: 1px solid #000;
  padding: 0.5rem;
  text-align: left;
}

.attributesTable th {
  background-color: #f2f2f2;
}

.attributesTable td {
  background-color: #fff;
}

.featuredProductsMobile {
  display: none;
}

.productImageWrapperMobile {
  display: none;
}

.productImageWrapperDesktop {
  display: block;
  cursor: zoom-in;
}

.closeBtn {
  display: none;
}

.attributesForm {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.formQuantityItem {
  background-color: black;
  color: white;
  border-radius: 0.5rem;
  display: flex;
  padding: 0.2rem 0.8rem;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
}

.atrQuantityField {
  background-color: #93e23e;
  color: black;
  border-radius: 0.5rem;
  padding: 0.1rem 0.5rem;
}

.formQuantityItem input {
  width: 7ch;
  height: 100%;
  font-size: 1.1rem;
  padding: 0.125rem;
  border: 1px solid black;
  outline: none;
}
.formDescItem textarea {
  display: flex;
  width: 100%;
  flex: 1;
  border-radius: 0.5rem;
  padding: 0.5rem;
  border: 2px solid black;
  margin-block: 0.2rem;
}

.formDescItem textarea::placeholder {
  text-align: center; /* Center the placeholder text */
  vertical-align: center;
}
.addToCartBtn {
  color: #375623;
  padding: 0.3rem 0 !important;
  font-size: 1.3rem;
  background: linear-gradient(to bottom, #07b64e, #51f53b);
  border-radius: 0.5rem;
  border: 2px solid black;
}

@media (max-width: 765px) {
  .productPreview {
    height: auto;
  }

  .productInfo {
    flex-direction: column;
    overflow-y: auto;
    height: calc(100vh - 167px);
    flex: 1;
    background-color: white;
    text-align: center;
  }

  .attributesTable {
    font-size: 0.8rem;
  }

  .productsHeader h3 {
    font-size: 2rem;
  }

  .featuredProductsMobile {
    display: block;
  }

  .productImageWrapperDesktop {
    display: none;
  }

  .productImageWrapperMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    aspect-ratio: 1/1;
    flex-shrink: 0;
    cursor: pointer;
    cursor: zoom-in;
  }
  .productPriceSeparator {
    display: none;
  }

  .addToCartBtn,
  .productStock {
    font-size: 1rem;
  }

  .productPrice {
    font-size: 1.5rem;
    margin-block: 1rem;
  }

  .closeBtn {
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    border: none;
    background-color: transparent;
    margin-bottom: 1rem;
    gap: 5px;
  }

  .closeBtn span {
    display: block;
  }
  .closeBtn img {
    color: red;
    height: 50px;
    width: 50px;
    font-weight: 900;
  }
}
