.newsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  height: 52px;
  background-color: #00000094;
  color: white;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  z-index: 50;
}

.newsContent {
  border: #0a0d23;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  padding: 10px 0;
  position: relative;
  animation: scroll linear infinite;
}

.newsBtnWrapper {
  background-color: #12151b;
  display: flex;
  align-items: center;
  z-index: 10;
  padding: 0.5rem;
  padding: 0.75rem;
  padding-left: 1rem;
  font-size: 14px;
}

.newsBtnWrapper button {
  background-color: #d92d20;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 6px;
  font-size: 14px;
  border: none;
}

.newsItem {
  display: flex;
  align-items: center;
}

.redDotWrapper {
  fill: #d92d20;
  color: #d92d20;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  height: 100%;
}
.redDotWrapper img {
  height: 8px;
  width: 8px;
}

.newsOuterWrapper { 
  
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
