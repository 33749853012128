.container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 80%;
  align-items: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding-bottom: 40px;
  /*margin-right: 15px;*/
}

.header {
  width: 100%;
}

.title {
  display: flex;
  justify-content: center;
  color: white;
}

.inputiKvota {
  display: flex;
  text-align: center;
  border: 1px solid black;
  height: 100px;
  width: 99%;
}

.inputiKvota div input {
  width: 119px;
  text-align: center;
}

.procenti {
  width: 99%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  color: white;
  text-align: center;
}

.procenticalc {
  display: flex;
  flex-direction: column;
  height: 150px;
  justify-content: center;
  align-items: center;
  border: 3px solid black;
  width: 100%;
  min-width: 100px;
  color: black;
}

.procenticalc div {
  height: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.kategorija {
  width: 100%;
  display: flex;
  align-items: center;
}