.container {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  align-items: center;
  margin: 0;
  padding: 0;
  overflow: auto;
  box-sizing: border-box;
  padding-bottom: 40px;
}

.table {
  margin-top: 10px;
  width: 99.2%;
  margin-right: 5px;
  border: 1px solid black;
  background: darkblue;
}

.table>th {
  color: whitesmoke;
  background-color: orangered;
  padding: 24px;
  border: 1px solid black;
}

.table>tr {
  text-align: center;
  color: whitesmoke;
  background-color: orangered;
}

.white {
  background-color: whitesmoke;
  border: 1px solid black;
}

.blueviolet {
  background-color: #282677;
  border: 1px solid black;
}

.smallerInput {
  background: whitesmoke;
  height: 32px;
  border: none;
  text-align: center;
  font-weight: bolder;
}

.biggerInput {
  background: whitesmoke;
  height: 381px;
  width: 200px;
  border: none;
  text-align: center;
  font-size: 50px;
  font-weight: bolder;
}