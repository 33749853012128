.mainCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 65%;
  height: 100%;
  overflow: auto;
}
.headerImage {
  width: 700px;
  margin-top: 30px;
}
.blue {
  color: blue;
}
.red {
  color: red;
}
