.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px;
  box-sizing: border-box;
  overflow: scroll;
}

.headerContainer {
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #d6ff00;
  padding-bottom: 10px;
}

.headerContainer h1 {
  color: #d6ff00;
  font-size: 28px;
  font-weight: 600;
}

.subtitleContainer {
  text-align: center;
  background-color: #262c39;
  color: #d6ff00;
  padding: 8px 16px;
  border-radius: 8px;
  margin-top: 24px;
}

.content {
  color: #fff;
  line-height: 22px;
}
.content > p {
  margin-top: 24px;
}

@media (min-width: 1024px) {
  .mainContainer {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 32px;
    max-width: 1200px;
    margin: 0 auto;
  }
  .headerContainer {
    margin-top: 24px;
  }
  .sidebar {
    display: block;
  }

  .content {
    padding: 0;
    overflow: scroll;
  }
  .content > p {
    margin-top: 26px;
  }
}
