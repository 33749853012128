.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  flex-wrap: wrap;
  box-sizing: border-box;
  position: relative;
  scrollbar-width: none;
}

.postsContainer {
  width: 100%;
}

.infoBlockContainer {
  width: 100%;
  justify-content: flex-start;
  margin-top: 24px;
}

.postsPageContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  overflow: auto;
  padding-top: 24px;
  box-sizing: border-box;
  position: relative;
  scrollbar-width: none;
  width: 100%;
}

.postsPageContainer > h1 {
  color: var(--a-color);
  border-bottom: 2px solid var(--a-color);
  width: 100%;
  padding-bottom: 10px;
  text-align: center;
  margin-top: 24px;
  display: block;
}

.postsAndCalculatorWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.marketingMediaWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.featureImage,
.featureVideoDiv,
.marketingImageATag {
  aspect-ratio: 1/1;
  border: 2px solid var(--a-color);
  min-width: 101px;
  width: 28vw;
  height: 28vw;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.optionsAndMarketing {
  background-color: #353945;
  border: 1px solid #212631;
  padding: 1rem 10px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.postTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  color: #d6ff00;
  text-align: center;
}

.postOptions {
  display: flex;
  background-color: #262c39;
  padding: 4px;
  padding-inline: 1rem;
  border: 1px solid #212631;
  justify-content: space-between;
  border-radius: 0.5rem;
}

.postOptions button {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  color: #ffffff;
  /* flex: 1; */
  align-items: center;
  padding-block: 6px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.postOptions button img {
  height: 20px;
  width: 20px;
}

.postOptions button:nth-of-type(1) img {
  transform: rotate(270deg);
}

.postOptions button:nth-of-type(4) img {
  transform: rotate(180deg);
}
.postOptions button:nth-of-type(5) img {
  transform: rotate(90deg);
}

.postOptions button span {
  display: none;
}
.commentCounterContainer {
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  text-align: center;
}

.commentCounter {
  font-size: 14px;
  font-weight: 500;
  color: #ffffffd1;
  width: 100%;
}

.counterLabel {
  color: #ffffffd1;
}

.leaveCommentContainer > p {
  display: none;
}

.leaveCommentButton > img {
  width: 20px;
  height: 20px;
}
.leaveCommentButton {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #d6ff00;
  color: #252f3b;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #b8db00;
  padding: 4px 16px 4px 16px;
  border-radius: 8px;
  width: 100%;
  justify-content: center;
  margin-top: 16px;
  height: 40px;
}
.emailNotificationsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  gap: 16px;
  margin-top: 16px;
  width: 100%;
  justify-content: start;

  box-sizing: border-box;
}
.emailNotifications {
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
}
.emailNotifications > input[type='checkbox'] {
  display: block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  color: #000;
}

@media (min-width: 1024px) {
  .featureImage,
  .featureVideoDiv,
  .marketingImageATag {
    width: 160px;
    height: 160px;
  }

  .postsContainer {
    max-width: 520px;
    width: 100%;
  }

  .postsPageContainer {
    width: auto;
  }

  .postOptions {
    padding-inline: 4px;
  }

  .postOptions button span {
    display: block;
    font-size: 14px;
    font-weight: 500;
  }
  .calculatorContainer {
    position: absolute;
    margin-left: 540px;
    margin-top: 215px;
  }
  .leaveCommentContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
  }
  .leaveCommentContainer > p {
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #ffffffd1;
  }
  .leaveCommentButton {
    width: 182px;
    white-space: nowrap;
    margin-top: 0px;
  }
}
