.pricesModal * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: 700 !important;
}

.pricesModal {
  height: 100%;
  border-radius: 1rem;
  padding: 0.5rem;
  flex: 1;
  margin-bottom: 0.5rem;
  scrollbar-width: none;
}

.closeBtn {
  display: flex;
  background-color: transparent;
  flex-direction: column;
  border: none;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  margin: 0 5rem 0rem 1rem;
  cursor: pointer;
}

.top {
  display: flex;
}

.pricesModal h2 {
  color: #1e03e9;
  font-size: 4rem;
}

.pricesModal h3 {
  color: #1e03e9;
  font-size: 2.5rem;
  margin: 0 auto;

  text-align: center;
}

.packagesWrapper {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.packagesWrapper button {
  display: flex;
  color: white;
  padding: 5px;
  border-radius: 0.5rem;
  border: none;
  background-color: #1e03e9;
  cursor: pointer;
  align-items: center;
}

.selectedPackage {
  background-color: black !important;
}

.selectedPackage {
  border: 2px solid var(--p-color) !important;

  background-color: var(--a-color) !important;
  color: white !important;
}

.packagesWrapper button .desc {
  background-color: white;
  flex: 1;
  /* width: 90%; */
  border-radius: 0.5rem;
  display: flex;
  color: red;
  margin: 0 1rem;
  padding: 2px;
  font-size: 1.1rem;
  align-items: center;
  justify-content: center;
  white-space: pre-line;
}
.packagesWrapper button .side {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.paymentPg .packagesWrapper button .side span:nth-child(1) {
  font-size: 0.6rem;
}

.packagesWrapper button .side span:nth-child(1) {
  font-size: 1rem;
}

.paymentPg .packagesWrapper button .side span:nth-child(2) {
  font-size: 1.1rem;
}
.packagesWrapper button .side span:nth-child(2) {
  font-size: 1.5rem;
}

.paymentPg .packagesWrapper button .desc {
  font-size: 0.65rem;
  padding-block: 10px;
}
.tnx {
  text-align: center;
  font-size: 2.1rem;
  margin: 5px 0;
}

.paymentPg .tnx {
  display: none;
  margin-top: auto;
}


.paymentPg.pricesModal {
  border: 5px solid #1e03e9;
}