.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding-bottom: 40px;
}

.container>img {
  width: 100%;
  margin-top: 5px;
  margin-right: 9px;
}

.calculator {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}

.row {
  display: flex;
  width: 100%;
  /* flex-direction: column; */
}

.col1 {
  width: 360px;
  text-align: center;
  border: 2px solid black;
  background: #bdd7ee;
  font-weight: bolder;
}

.col2 {
  width: 70px;
  text-align: center;
  border: 1px solid black;
  /* border: 1px solid red; */
}

.col3 {
  width: 503px;
  /* width: 100%; */
  padding: 2px 0;
  text-align: center;
  border: 1px solid black;
  background: #4297f7;
  color: whitesmoke;
  font-weight: bolder;
}

.col4 {
  width: 67px;
  text-align: center;
  border: 1px solid black;
  /* border: 1px solid red; */
}

.col5 {
  width: 421px;
  text-align: center;
  border: 2px solid black;
  background: #bdd7ee;
  font-weight: bolder;
}

.col2>input[type="text"],
.col4>input[type="text"] {
  width: 10px;
  border: "none";
}