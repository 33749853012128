.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 180px) !important;
  padding: 30px;
  overflow: hidden;
  overflow-y: auto;
}

.container::-webkit-scrollbar {
  width: 15px !important;
  height: 10px !important;
}

.container::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 4px !important;
}

.container::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.container::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 4px !important;
}
.menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 20px;
  padding: 30px;
}

.addImageBtn {
  background-color: #007bff; /* Primary blue color */
  border: none;
  color: white; /* White text color */
  padding: 12px 24px; /* Padding to make the button larger */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px 0 10px;
  cursor: pointer;
  border-radius: 4px; /* Rounded corners */
  transition-duration: 0.4s; /* Smooth transition for hover effect */
}

.addImageBtn:hover {
  background-color: #0056b3; /* Darken the button color when hovered */
  color: white;
}

.row {
  display: flex;
  padding: 10px 5% 10px 5%;
  flex-wrap: wrap;
  min-height: 80px;
  justify-content: space-between;
  border-bottom: 1px solid rgb(100, 100, 100);
}

.imagesList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.imageItem {
  max-width: 200px;
  min-width: 200px;
  margin-right: 10px;
  max-height: 150px !important;
  min-height: 150px !important;
  padding: 5px;
}

.imageItem img {
  max-width: 200px;
  min-width: 200px;
  max-height: 150px !important;
  min-height: 150px !important;
  border-radius: 10px;
}

.left {
  display: flex;
}

.left p {
  min-width: 200px;
}

.input {
  background-color: white !important;
  width: 18%;
}

.country {
  background-color: white !important;
  width: 15%;
}

.inputnumber_fromant {
  background-color: white !important;
  width: 4%;
  display: flex;
  align-items: center;
}

.saveBtn {
  background-color: #ff0000; /* Primary blue color */
  border: none;
  color: white; /* White text color */
  padding: 12px 24px; /* Padding to make the button larger */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 28px;
  margin: 4px 4.5% 0 10px;
  cursor: pointer;
  border-radius: 4px; /* Rounded corners */
  transition-duration: 0.4s; /* Smooth transition for hover effect */
}

.saveBtn:hover {
  background-color: #9b0000; /* Darken the button color when hovered */
  color: white;
}

.saveBtn:disabled {
  background-color: #5a5a5a; /* Darken the button color when hovered */
  color: white;
}

.saveDiv {
  display: flex;
  flex-direction: row-reverse;
}

.img {
  max-width: 100px;
  min-width: 100px;
  max-height: 50px !important;
  min-height: 50px !important;
  padding: 5px;
}

.img:hover {
  max-width: 550px;
  /* min-width: 550px; */
  max-height: 200px !important;
  /* min-height: 200px !important; */
  padding: 5px;
}

.delBtn {
  background-color: #ff0000; /* Primary blue color */
  border: none;
  color: white; /* White text color */
  padding: 12px 24px; /* Padding to make the button larger */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 4.5% 0 10px;
  cursor: pointer;
  border-radius: 4px; /* Rounded corners */
  transition-duration: 0.4s; /* Smooth transition for hover effect */
}

.delBtn:hover {
  background-color: #9b0000; /* Darken the button color when hovered */
  color: white;
}

.arrows {
  display: flex;
  flex-direction: column;
}

.svgimg:hover {
  filter: brightness(0) saturate(100%) invert(34%) sepia(94%) saturate(7500%)
    hue-rotate(216deg) brightness(99%) contrast(101%);
}
