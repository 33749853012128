.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding-bottom: 40px;
}

.ceokalkulator {
  width: 99%;
  display: flex;
  flex-direction: row;
  background-color: #ff0000;
  margin-top: 10px;
  margin-right: 10px;
}

.forma {
  padding-left: 40px;
  /*background-color: #ff0000;*/
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  margin: 5px;
}

.desnatabela {
  background-color: #ff0000;
  width: 40%;
  height: 95%;
}

.row {
  display: flex;
}

.row div {
  width: 14%;
}

.row div:first-of-type {
  width: 7%;
  color: white;
  border: 3px black solid;
  text-align: center;
}

.row div:last-of-type {
  width: 19%;
}

.prvired {
  display: flex;
}

.prvireddesno {
  display: flex;
}

.prvired p {
  display: flex;
  width: 13.6%;
  padding: 1px;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-weight: bold;
}

.prvired p:first-child {
  display: flex;
  width: 7%;
  padding: 1px;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-weight: bold;
}

.prvired p:last-child {
  display: flex;
  width: 19%;
  padding: 1px;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-weight: bold;
}

.prvireditem {
  background-color: black;
  border: red 1px solid;
}

.desnatabela {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.desnatabela div {
  border: 3px solid white;
  padding: 15px;
}

.label {
  color: white;
  text-align: center;
  background-color: rgb(20, 47, 100);
}

.header {
  color: white;
  font-size: 20px;
  text-align: center;
  background-color: rgb(20, 47, 100);
}

.inputfieldprocenavrednosti {
  align-items: center;
  text-align: center;
  border: 3px solid black;

  width: 100%;
  font-weight: bolder;
}

.prvireddesnoitem {
  align-items: center;
  text-align: center;
  font-weight: bolder;
}