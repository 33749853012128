.paymentPageContainer * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.paymentPageContainer {
  flex: 1;
  padding: 1rem;
  padding-top: 0;
  overflow: scroll;
  scrollbar-width: none;
}

.inner {
  display: flex;
  margin: 1rem 0;
}

.prices .title {
  color: #25f853;
  border: 1px solid var(--s-color);
  text-align: center;
  padding: 1rem;
  font-weight: 700;
  background-color: black;
  border-radius: 0.5rem;
  font-size: 2rem;
}

.formContainer {
  background-color: white;
  padding-left: 20px;
  border-radius: 10px;
  flex: 1;
}

.imgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 500px;
}

.imgWrapper img {
  height: auto;
  object-fit: contain;
  width: 100%;
}

.formItem {
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
}

.formItem label {
  background-color: #fb284e;
  color: black;
  font-size: 1.5rem;
  width: 250px;
  text-align: center;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  padding: 5px;
  font-size: 1.3rem;
  border: 2px solid var(--s-color);
  color: white;
  background-color: var(--a-color);
}

.formItem input,
.formItem .numberInput {
  border: 2px solid black;
  border-radius: 5px;
  padding: 10px;
  font-weight: 700;
  color: black;
  width: 295px !important;
}

.formItem input::placeholder {
  color: gray;
  font-size: 0.9rem;
  text-align: center;
}

.formBtn {
  width: 100%;
  color: white;
  background-color: #253b62;
  font-weight: bold;
  padding: 0.7rem;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
}

.formBtn[type='submit'] {
  background-color: #25f853;
  color: black;
  background-color: #000f2c;
  color: #00a8f4;
  margin-right: 8px;
  border-radius: 10px;
  font-size: 2rem;
  border: 2px solid var(--s-color);
}

.cardsWrapper {
  display: flex;
  justify-content: space-evenly;
}

.wsPayLogo {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.safePurchaseLink {
  width: 100%;
  text-align: center;
  font-size: 1.1rem;
  cursor: pointer;
}

.selectPackage {
  width: 190%;
}
.cardImgWrapper {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.cardImgWrapper img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}