.mainCenter {
  width: 65%;
  padding-right: 20px;
  overflow: auto !important;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.25;
  box-sizing: border-box;
  padding-bottom: 30px;
}

.content {
  padding: 15px;
}

.title {
  width: 100%;
  font-size: 26px;
  justify-content: center;
  background-color: #070044;
  color: red;
  border-radius: 15px;
  display: flex;
  padding: 10px;
}

.comment {
  width: 100%;
  font-size: 26px;
  justify-content: center;
  background-color: #070044;
  color: rgb(0, 235, 0);
  border-radius: 15px;
  display: flex;
  padding: 10px;
}

.firstRow {
  width: 100%;
  display: flex;
  padding: 10px;
}

.contentRow {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 26px;
  text-align: center;
  margin-top: 10px;
}

.textField {
  margin-top: 10px;
  width: 100%;
  font-size: 12px;
  justify-content: center;
  background-color: #070044;
  color: white;
  border-radius: 15px;
  display: flex;
  padding: 10px;
}

.featureImage {
  min-width: 337px;
  height: 268px;
  margin-right: 10px;
}

.marketingImageATag {
  margin-right: 10px;
  min-width: 337px;
  height: 268px;
}

.marketingImage {
  width: 100%;
  height: 100%;
}

.itemImage {
  margin: 0 10px 10px 0;
  border-radius: 10px;
  width: 100%;
  max-height: 800px;
  cursor: pointer;
}

.itemImageATag {
  margin: 0 10px 10px 0;
  border-radius: 10px;
  width: 100%;
  max-height: 400px;
}

.navigationRow {
  display: flex;
  justify-content: space-around;
}