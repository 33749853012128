.container {
  padding: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 70vh;
}

.commentsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.commentsHeader>h1 {
  flex: 1;
  display: flex;
}

.commentsHeader>form {
  flex: 1;
  display: flex;
  gap: 5px;
}

.commentsHeader>form>input {
  width: 100%;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  outline: none;
  border: none;
  font-family: 'Questrial', sans-serif;
}

.commentsHeader>form>div {
  display: flex;
}

.commentsHeader>form>div>button {
  border: none;
  border-radius: 4px;
  color: white;
  background-color: #007bff;
  padding: 0px 20px;
  font-family: 'Questrial', sans-serif;
  cursor: pointer;
  transition: all .15s ease;
}

.commentsHeader>form>div>button:hover {
  background-color: #0058b6;
}

.addButton {
  padding: 10px 25px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  transition: all .15s ease;
  cursor: pointer;
}

.addButton:hover {
  background-color: #0058b6;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-x: scroll;
}

.commentTable {
  border: 1px solid white;
  border-collapse: collapse;
}

.commentTable>thead {
  position: sticky;
  top: -1px;
}

.commentTable>thead>tr>th {
  border: 1px solid black;
  padding: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  font-weight: normal;
  background-color: darkblue;
}

.commentTable>tbody>tr>td {
  border: 1px solid black;
  /* text-align: center; */
  padding: 12px;
  color: black;
  background-color: white;
  word-wrap: break-word;
  max-width: 270px;
}

.actionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.actionButtonEdit,
.actionButtonDelete,
.actionButtonReports {
  color: white;
  border: none;
  flex: 1;
  max-width: 80px;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color .15s ease;
  cursor: pointer;
}

.actionButtonReports {
  background-color: #007bff;
}

.actionButtonEdit {
  background-color: #007bff;
}

.actionButtonDelete {
  background-color: red;
}

.actionButtonReports:hover {
  background-color: rgba(0, 123, 255, .5);
}

.actionButtonEdit:hover {
  background-color: rgba(0, 123, 255, .5);
}

.actionButtonDelete:hover {
  background-color: rgba(255, 0, 0, .6);
}

.sidebarCreateTeam {
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  gap: 30px;
  box-sizing: border-box;
  height: 100%;
  width: 0px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  transition: width .3s ease;
}

.sidebarCreateTeam.active {
  width: 350px;
}

.closeButton {
  background-color: transparent;
  border: none;
  padding: 0;
  width: 40px;
  height: 40px;
}

.closeButtonCreateTeam {
  font-size: 40px;
  width: 100%;
  height: 100%;
  transition: all .3s ease;
  cursor: pointer;
}

.closeButtonCreateTeam:hover {
  transform: rotate(90deg);
  transform-origin: center;
}

.sidebarCreateTeamForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  padding: 20px;
}

.sidebarCreateTeamForm>div>div {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.sidebarCreateTeamForm>div>div>label {
  font-size: 16px;
  color: gray;
  min-width: 100px;
}

.nameInput {
  border: 1px solid lightgray;
  outline: none;
  font-size: 16px;
  transition: all .15s ease;
  font-family: 'Questrial', sans-serif;
}

.nameInput:focus {
  border-color: #007bff;
}

.nameInput.active {
  border-color: red;
}

.imageInput {
  display: none;
}

.buttonAddImage {
  padding: 7px;
  width: 120px;
  background-color: black;
  border: 2px solid black;
  border-radius: 6px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: all .15s ease;
  font-family: 'Questrial', sans-serif;
}

.buttonAddImage:hover {
  border: 2px solid black;
  color: black;
  background-color: white;
}

.imagePreview {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
  border-radius: 50%;
}

.readImage {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
}

.removeImageBtn {
  background-color: rgba(255, 255, 255, .8);
  width: 100%;
  height: 150px;
  border: none;
  border-radius: 10px;
  color: red;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  transition: all .3s ease;
}

.imagePreview:hover>.removeImageBtn {
  opacity: 1;
  pointer-events: all;
}

.submitButton {
  padding: 10px;
  width: 100%;
  min-width: 120px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: all .15s ease;
  font-family: 'Questrial', sans-serif;
}

.submitButton:hover {
  background-color: #0059b9;
}

.loadingButton {
  display: block;
  padding: 10px;
  min-width: 100px;
  background-color: #0051a7;
  text-align: center;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  transition: all .15s ease;
}

.errorMsg {
  color: red;
  position: absolute;
  bottom: 0;
  left: 7px;
  transform: translateY(10px);
  margin: 0;
  font-size: 15px;
  max-height: 0;
  pointer-events: none;
  overflow: hidden;
  transition: all .15s ease;
  opacity: 0;
}

.errorMsg.active {
  max-height: 1000px;
  transform: translateY(20px);
  opacity: 1;
}

.overlayTeamPage {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  opacity: 0;
  pointer-events: none;
  z-index: 9;
}

.overlayTeamPage.active {
  opacity: 1;
  pointer-events: all;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.firstPage,
.lastPage,
.targetPage,
.pageBack,
.pageForward {
  border: none;
  background-color: transparent;
  color: white;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  transition: color .15s ease;
  cursor: pointer;
}

.firstPage:hover,
.lastPage:hover,
.targetPage:hover,
.pageBack:hover,
.pageForward:hover {
  color: rgba(255, 255, 255, .5);
}

.targetPageContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.firstPage,
.lastPage {
  opacity: 0;
  pointer-events: none;
}

.firstPage.show,
.lastPage.show {
  opacity: 1;
  pointer-events: all;
}

.targetPage.isActive {
  color: #007bff;
}

.pageBack.disabled {
  pointer-events: none;
}

.pageForward.disabled {
  pointer-events: none;
}

.filterType {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  display: flex;
  flex-direction: column;
  background-color: white;
  /* padding: 10px 0px; */
  border-radius: 4px;
  max-height: 0px;
  overflow: hidden;
  transition: max-height .3s 0s ease;
}

.filterType.show {
  max-height: 1000px;
  transition: max-height .3s 0s ease;
}

.filterType>button {
  margin: 5px 0px;
  color: gray;
  font-size: 16px;
  font-family: 'Questrial', sans-serif;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: color .3s ease;
}

.filterType>button:hover {
  color: black;
}

.excelContainer {
  height: 36px;
  display: flex;
}

.excelExport {
  height: 100%;
  background-color: rgba(0, 255, 0, .5);
  border: none;
  padding: 0px 20px;
  border-radius: 4px;
  color: white;
  font-family: 'Questrial', sans-serif;
  cursor: pointer;
  transition: all .15s ease;
}

.excelExport:hover {
  background-color: rgba(0, 255, 0, .7);
}

.editCommentModal,
.modalOfReports {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(.2);
  background-color: white;
  width: 500px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all .3s ease;
  z-index: 999;
}

.editCommentModal.activeEditModal,
.modalOfReports.activeReportsModal {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: translate(-50%, -50%) scale(1);
}

.editCommentModal>.row,
.modalOfReports>.row {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
}

.editCommentModal>.row>p,
.modalOfReports>.row>p {
  color: black;
  font-weight: 600;
  font-size: 20px;
  margin: 0px;
}

.editCommentModal>.row>textarea {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  outline: none;
}

.editCommentModal>.row>.editCommentActionContainer,
.modalOfReports>.row>.editCommentActionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.editCommentModal>.row>.editCommentActionContainer>button,
.modalOfReports>.row>.editCommentActionContainer>button {
  padding: 5px 20px;
  font-size: 16px;
  border: 1px solid white;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: all .15s ease;
}

.editCommentModal>.row>.editCommentActionContainer>:nth-child(1),
.modalOfReports>.row>.editCommentActionContainer>:nth-child(1) {
  background-color: rgba(0, 123, 255, 1);
}

.editCommentModal>.row>.editCommentActionContainer>:nth-child(1):hover,
.modalOfReports>.row>.editCommentActionContainer>:nth-child(1):hover {
  background-color: rgba(0, 123, 255, .7);
  color: black;
  border-color: black;
}

.editCommentModal>.row>.editCommentActionContainer>:nth-child(2) {
  background-color: red;
}

.editCommentModal>.row>.editCommentActionContainer>:nth-child(2):hover {
  background-color: rgba(255, 0, 0, .7);
  color: black;
  border-color: black;
}

.editCommentOverlay,
.modalOfReportsOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .4);
  display: none;
  z-index: 998;
}

.editCommentOverlay.editOverlayActive,
.modalOfReportsOverlay.modalOfReportsOverlayActive {
  display: block;
}

.searchBlockedUser {
  border: none;
  background-color: transparent;
  color: white;
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-family: 'Questrial', sans-serif;
  cursor: pointer;
}

/* 
.cancelCommentButton {
  background-color: red;
  padding: 5px 20px;
  font-size: 16px;
  border: 1px solid white;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: all .15s ease;
}

.cancelCommentButton:hover {
  background-color: rgba(255, 0, 0, .7);
  color: black;
  border-color: black;
} */