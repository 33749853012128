.shopAdminLayout {
  height: 100vh;
  width: 100% !important;
  background-color: #2c2b2b;
  color: #ffffff;
  overflow: hidden;
}

.shopAdminLayout img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.shopAdminLayout * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.center {
  display: flex;
}
