.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin: 0;
  padding: 0;
}
.calculator {
  background-image: url("../kalkulatoriSlikaPozadina/vaterpoloSlika.png");
  background-color: #cccccc;
  width: 100%;
  /* height: 284px; */
  height: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 5px;
  margin-right: 10px;
}
.pobedaDomacina {
  position: absolute;
  left: 190px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.pobedaGosta {
  position: absolute;
  right: 230px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.nereseno {
  position: absolute;
  left: 680px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.golMin {
  position: absolute;
  top: 58px;
  left: 680px;
  color: red;
  font-size: 32px;
  font-weight: bolder;
}
.golMax {
  position: absolute;
  top: 187px;
  left: 680px;
  color: green;
  font-size: 32px;
  font-weight: bolder;
}
.golBalans {
  position: absolute;
  top: 122px;
  left: 680px;
  color: yellow;
  font-size: 32px;
  font-weight: bolder;
}
.tacanRezultatDomacin {
  position: absolute;
  top: 73px;
  right: 275px;
  color: white;
  font-size: 30px;
  font-weight: bolder;
}
.tacanRezultatGost {
  position: absolute;
  top: 73px;
  right: 190px;
  color: white;
  font-size: 30px;
  font-weight: bolder;
}
.pobedaSaRazlikom {
  position: absolute;
  top: 137px;
  right: 210px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.pobedaSaRazlikomZagrada {
  position: absolute;
  top: 137px;
  right: 125px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.betkoPredlog1 {
  position: absolute;
  top: 211px;
  right: 285px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}
.betkoPredlog2 {
  position: absolute;
  top: 211px;
  right: 145px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}