.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65%;
  overflow: auto;
}
.container > h2 {
  color: #070232;
}
.container > p {
  font-weight: bold;
  text-align: center;
}
.red {
  color: red;
  max-width: 600px;
  font-weight: bold;
  font-size: 18px;
}
.input {
  width: 400px;
  border: 3px solid #070232;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  padding: 8px;
}
.btnContainers {
  display: flex;
  width: 300px;
  justify-content: space-between;
  align-items: center;
}
.btn {
  margin-top: 20px;
  min-width: 100px;
  padding: 12px 18px;
  background: #070232;
  color: whitesmoke;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
.error > p {
  color: red;
  font-size: 22px;
  font-weight: bolder;
}
