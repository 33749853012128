.marketingPage {
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-sizing: border-box;
  margin-bottom: 3rem;
  font-weight: 700;
  padding: 0 30px;
  scrollbar-width: none;
  text-align: center;
}




.marketingPage h1{
  color: #C00000;
  font-size: 1.7rem;
}

.marketingPage h2 { 
  color: red;
  font-size: 1.6rem;

}

.marketingPage p {
  font-size: 1.5rem;
}

.blueP, .email {
  color: blue;
}

.email { 
  font-size: 2rem !important;
}