.container {
    margin: 2rem;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
    min-height: 100vh;
}

.btn {
  width: 160px;
  padding: 14px;
  font-size: 18px;
  color: #d6ff00;
  text-align: center;
  background-color: black;
  border: 1px solid #d6ff00;
  border-radius: 12px;
  cursor: pointer;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

h2 {
    border-bottom: 2rem;
}

.container > div {
    margin-bottom: 1rem;
    border-bottom: 1px solid white;
}

.bottom {
    margin-bottom: 3rem;
}

.btnContainer {
  width: inherit;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;  
}

.hiddenInput {
  display: none;
}

.selectedAdsContainer {
  display: flex;
  flex-wrap: wrap;
  width: inherit;
  justify-content: center;
  gap: 5rem;
}

.adsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.adContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 200px;
  overflow: hidden;
  background-color: transparent;
  border: 2px solid #d6ff00;
  border-radius: 0.5rem;
  cursor: pointer;
}

.adContainer.active {
  border: 2px solid aqua;
}