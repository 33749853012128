.tipsterAwardsPage {
  flex: 1;
  overflow: auto;
  font-weight: 700;
  text-align: center;
  position: relative;
  overflow: auto;
  margin: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  scrollbar-width: none;
}

.tipsterAwardsPage h3,
.tipsterAwardsPage h4 {
  margin: 0;
}

.awards {
  overflow: scroll;
  scrollbar-width: none;
}

.tipsterAwardsPage ul {
  text-decoration: none;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  word-wrap: break-word;
}
.titleSticky {
  position: sticky;
  top: 0;
  overflow: hidden;
  margin-bottom: 5px;
  background-color: white;
}

.backButton {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem !important;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  height: 20px;
  flex-shrink: 0;
  cursor: pointer;
}

.backButton svg {
  color: #ff0000;
  text-align: center;
  height: 100%;
  font-size: 3rem;
  display: flex;
  align-items: center;
  width: 20px;
  transform: scale(1.8);
}

.backButton span {
  margin-left: -2px;
}

.mainTitle {
  color: #1cf836;
  background-color: #000064;
  font-size: 2.5rem;
  padding: 1rem;
  border-radius: 1rem;
}

.awardsItemHeader {
  background-color: #f74027;
  color: white;
  font-size: 1.5rem;
  padding: 1.5rem;
  border-radius: 1rem;
}

.period {
  background-color: #00143d;
  color: white;
  margin: 0.2rem 0;
  font-weight: 700;
  font-size: 1.1rem;
}

.table {
  font-size: 1.1rem;
  width: 100%;
  font-weight: 700 !important;
  font-family: Arial, sans-serif;
  border-spacing: 5px;
}

.tableTile {
  background-color: black;
  color: #58e913;
  border-radius: 0.5rem;
  padding: 0.5em;
  font-size: 1.5rem;
  margin: 0.5rem;
  cursor: pointer;
  justify-content: space-between;
  position: relative;
}
.tableBack {
  position: absolute;
  left: 0;
  top: 0;
  color: white;
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 1.2rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.tableBack svg {
  color: #ff0000;
  text-align: center;
  height: 100%;
  font-size: 2rem;
}

.table th,
.table td {
  font-size: 1.1rem;
  border-radius: 0.5rem;
  padding: 0.5em;
  margin: 3px;
}

.backToActual {
  background-color: #f50000;
  border: 3px solid #661316;
  font-size: 1.2rem;
  font-family: 'Comic Sans', sans-serif;
  padding: 0.5em;
  width: 100%;
  border-radius: 0.5rem;
  color: white;
  font-weight: 700;
  cursor: pointer;
}

.table th {
  background-color: #0421ce;
  color: white;
}

.table td {
  border: 2px solid #0421ce;
  color: black;
}
