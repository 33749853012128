.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding-bottom: 40px;
}

.calculator {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.firstRow {
  display: flex;
  background: #282677;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.upisUlog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 80px;
}

.upisKvota {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 81px;
}

.eventualniDobitak {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 80px;
}

.prolazNe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 80px;
}

.gubitak {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 80px;
}

.isplativost {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 80px;
}

.first>p,
.second>p {
  margin: 0;
  font-size: 14px;
  font-weight: bolder;
}

.upisUlog,
.upisKvota,
.eventualniDobitak,
.prolazNe,
.gubitak,
.isplativost {
  border: 2px solid black;
  color: whitesmoke;
}

.upisUlog>p,
.upisKvota>p,
.eventualniDobitak>p,
.prolazNe>p,
.gubitak>p,
.isplativost>p {
  font-size: 17px;
  font-weight: bolder;
}

.row {
  display: flex;
}

.upisUlogFirstRow>input[type="text"],
.upisKvotaRow>input[type="text"] {
  margin: 0;
  padding: 0;
  font-weight: bolder;
}

.upisUlogFirstRow>p,
.upisKvotaRow>p,
.eventualniDobitakRow>p,
.prolazNeRow>p,
.gubitakRow>p,
.isplativostRow>p {
  margin: 0;
  padding: 0;
}

.upisUlogFirstRow,
.upisKvotaRow,
.eventualniDobitakRow,
.upisProlazDaNeRow,
.gubitakRow,
.prolazNeRow,
.isplativostRow {}

.footerFirstRow {
  width: 102px;
  border: 3px solid black;
  position: relative;
}

.upisUlogFirstRow {
  width: 210px;
  border: 3px solid black;
  position: relative;
}

.upisUlogFirstRow>input[type="text"] {
  width: 100%;
  height: 20px;
  border: none;
  padding: 0;
  margin: 0;
  text-align: center;
  font-weight: bolder;
}

.footerSecondRow {
  width: 103px;
  border: 3px solid black;
  position: relative;
}

.upisKvotaRow {
  width: 210px;
  border: 3px solid black;
  position: relative;
}

.upisKvotaRow>input[type="text"] {
  width: 100%;
  height: 100%;
  border: none;
  padding: 0;
  margin: 0;
  text-align: center;
  font-weight: bolder;
}

.footerThirdRow {
  width: 211px;
  position: relative;
  border: 3px solid black;
}

.eventualniDobitakRow {
  width: 247px;
  background: #002060;
  color: whitesmoke;
  text-align: center;
  border: 3px solid black;
}

.footerForthRow {
  width: 468px;
  position: relative;
  border: 3px solid black;
}

.prolazNeRow {
  width: 217px;
  background: red;
  color: whitesmoke;
  text-align: center;
  border: 3px solid black;
}

.gubitakRow {
  width: 233px;
  text-align: center;
  background: #002060;
  border: 3px solid black;
  color: whitesmoke;
}

.isplativostRow {
  width: 280px;
  text-align: center;
  border: 3px solid red;
  background: red;
  color: whitesmoke;
}

.bottom {
  display: flex;
  border: 2px solid black;
}

.prvi {
  width: 102px;
  border-right: 2px solid black;
  border-left: 2px solid black;
  text-align: center;
  font-weight: bolder;
  padding: 10px 0;
  background: red;
  color: black;
}

.drugi {
  width: 103px;
  border-right: 2px solid black;
  text-align: center;
  font-weight: bolder;
  padding: 10px 0;
  background: red;
  color: black;
}

.treci {
  width: 210px;
  border-right: 2px solid black;
  text-align: center;
  font-weight: bolder;
  padding: 10px 0;
  background: #282677;
  color: whitesmoke;
}

.cetvrti {
  width: 462px;
  border-right: 2px solid black;
  text-align: center;
  font-weight: bolder;
  padding: 10px 0;
  background: #282677;
  color: whitesmoke;
}

.peti {
  width: 232px;
  border-right: 2px solid black;
  text-align: center;
  font-weight: bolder;
  padding: 10px 0;
  background: #282677;
  color: whitesmoke;
}

.sesti {
  width: 305px;
  border-right: 2px solid black;
  text-align: center;
  font-weight: bolder;
  padding: 10px 0;
  background: #282677;
  color: whitesmoke;
}