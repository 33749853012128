.row {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 50px;
  /*justify-content: space-between;*/
  /*align-items: center;*/
}

.tip {
  width: 100%;
  background: linear-gradient(rgb(4, 44, 131), rgb(13, 40, 104));
  color: white;
  border: 1px solid black;
}

.tip p {
  text-align: center;
}

.input {
  width: 105.5px;
  height: 100%;
  text-align: center;
  font-size: 30px;
  color: darkblue;
  border: 3px solid black;
  border-radius: 10px;
}

.column1 {
  width: 30%;
  border: 2px solid black;
  justify-content: center;
  align-items: center;
}

.column1 p {
  text-align: center;
}

.column2 {
  display: flex;
}
