.btnContainers {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 1800px;
  width: calc(100% - 100px);
  margin: 0 auto;
  overflow-y: visible !important;

  padding-bottom: 0;
  height: 120px;
}

.image {
  width: 1000px;
  height: 68px;
  background: linear-gradient(#070044, #080808);
}

.btn1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 339px;
  flex-shrink: 0;
  height: 90%;
  border: 2px solid var(--s-color);
  cursor: pointer;
  background: var(--a-color);
  color: var(--t-color);
  font-size: 28px;
  box-sizing: border-box;
  font-weight: bolder;
  border-radius: 10px;
  margin-left: 4px;

}

.btn2 {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  width: 338px;
  flex-shrink: 0;
  height: 90%;
  border: 2px solid var(--s-color);
  cursor: pointer;
  background: var(--a-color);
  color: var(--t-color);
  font-size: 28px;
  font-weight: bolder;
  margin-right: 4px;
  border-radius: 10px;
  
}

.recommendations {
  width: 55%;
  overflow-y: visible !important;
  height: 100%;
  background: linear-gradient(#070044, #080808);
  color: #ffffff;
  font-weight: bolder;
  position: relative;
}

.card {
  min-height: 150px;
  /* Example minimum height */
  /* height: 150px; */
  display: flex;
  cursor: pointer;
  justify-content: center;
  position: relative;
  /* Needed for absolute positioning context */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.card img {
  height: 120px;
  width: 150px;
  border-radius: 10px;
  padding-left: 5px;
  margin-top: -10px;
  /* border: 5px solid #86e925; */
}

.card video {
  border-radius: 10px;
  margin-top: -10px;

  /* border: 5px solid #86e925; */
}

.card > div > h2 {
  position: absolute;
  width: 150px;
  bottom: 32px;
  font-size: 15px;
  text-align: center;
  background-color: #1300c065;
  border-radius: 4px;
  /* height: 20%; */
}

.card > div {
  padding: 0px 5px;
}

.arrows {
  cursor: pointer;
  width: 70px;
  height: 70px;

  /* background: linear-gradient(#070044, #080808); */
}

.arrowcont {
  cursor: pointer;
  width: 60px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background: linear-gradient(#070044, #080808); */
}

.card:hover {
  transform: scale(1.8);
  z-index: 100;
  margin-top: 10px;
}

.card:hover > div > h2 {
  font-size: 11px;
}

.card:first-of-type:hover {
  transform: scale(1.8);
  z-index: 100;
  margin-top: 10px;
  margin-left: 50px;
}

.card:last-of-type:hover {
  transform: scale(1.8);
  z-index: 100;
  margin-top: 10px;
  padding-right: 250px !important;
}

.swiperclass {
  overflow-y: visible !important;
}

.swiperclass > div {
  overflow-y: visible !important;
}

.swipercont {
  overflow-y: visible !important;
}

.swiper {
  overflow: visible !important;
}

.latestCommentedLeft,
.latestCommentedRight {
  position: absolute;
  bottom: -3px;

  font-size: 12px;
  display: flex;
  align-items: center;
  color: var(--t-color);
  pointer-events: none;
}

.latestCommentedLeft {
  left: 0;
}

.latestCommentedRight {
  left: 50%;
  transform: translateX(-50%);

  color: var(--t-color);
}
