.singleUser {
  width: 1024px;
  padding: 0 20px;
  border: 2px solid blueviolet;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
  cursor: pointer;
}
.left {
  display: flex;
  width: 30%;
  justify-content: flex-start;
  align-items: center;
}
.center {
  width: 20%;
}
.right {
  width: 50%;
}
.left,
.right,
.center,
.title,
.span > p {
  font-size: 20px;
}
.title {
  color: aqua;
  margin-right: 8px;
}
