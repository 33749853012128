.container {
  width: 300px;
  flex-shrink: 0;
  height: calc(100vh - 71px);
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  border-right: 1px solid #ffffff;
  height: 96%;
  overflow-y: auto;
}

.main>ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
}

.main>ul>li {
  margin: 6px 0;
  cursor: pointer;
}