.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding-bottom: 40px;
}

.calculator {
  background-image: url("../kalkulatoriSlikaPozadina/boksSlika.png");
  background-color: #cccccc;
  width: 100%;
  /* height: 284px; */
  height: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 5px;
  margin-right: 10px;
}

.prvi {
  position: absolute;
  top: -20px;
  left: 200px;
  color: #F04A00;
  font-size: 100px;
  font-weight: bolder;
}

.drugi {
  position: absolute;
  top: -20px;
  right: 220px;
  color: mediumblue;
  font-size: 100px;
  font-weight: bolder;
}