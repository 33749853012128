.tipsterDescriptionPage {
  flex: 1;
  overflow: auto;
  font-weight: 700;
  text-align: center;
  position: relative;
  overflow: auto;
  margin: 1rem;
  font-size: 1.1rem;
  line-height: 2rem;
  font-family: Arial Black, sans-serif;
}

.tipsterDescriptionTitle {
  background-color: #00143d;
  color: #00ea42;
  font-size: 2.5rem !important;
  padding: 1rem 3rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  flex: 1;
}

.backButton {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem !important;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  height: 20px;
  flex-shrink: 0;
  cursor: pointer;
}

.backButton svg {
  color: #ff0000;
  text-align: center;
  height: 100%;
  font-size: 3rem;
  display: flex;
  align-items: center;
  width: 20px;
  transform: scale(1.8);
}

.backButton span {
  margin-left: -2px;
}

.sticky {
  position: sticky;
  top: 0;
  background-color: white;
}

.tipsterDescriptionPage h2 {
  font-size: 3rem;
  color: #c00000;
}

.tipsterDescriptionPage .red {
  color: #c00000;
}

.tipsterDescriptionPage .blue {
  color: #0757fa;
}
.tipsterDescriptionPage .register {
  color: #c00000;
  font-size: 2rem;
  margin-bottom: 0rem;
}

.tipsterDescriptionPage .green {
  color: #00b050;
  font-size: 2.3rem;
}
