.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding-bottom: 40px;
}

.calculator {
  margin-top: 10px;
  width: 100%;
}

.calculator table {
  width: 99.4%;
}


.bottom {
  width: 100%;
}