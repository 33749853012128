.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin: 0;
  padding: 0;
  font-weight: bold;
  box-sizing: border-box;
  padding-bottom: 40px;
}

.upisImeSudije {
  border-radius: 15px;
  border: 3px solid black;
  margin-top: 2px;
  width: 80%;
  height: 80px;
  font-weight: bold;

  text-align: center;
  font-size: 15px;
  font-weight: lighter;
}

.upisZemljaSudije {
  border-radius: 15px;
  border: 3px solid black;
  margin-top: 2px;
  font-weight: bold;
  width: 18%;
  height: 80px;
  text-align: center;
  font-size: 15px;
  font-weight: lighter;
}

.calculatorTabela {
  width: 100%;
  height: 80px;
  font-weight: bold;

  text-align: center;
  font-weight: bold;
  border-style: none;
}

.cellInput95Width {
  width: 95%;
  height: 100%;
  border: none;
  font-weight: bold;

  border-radius: 5px;
  text-align: center;
}

.cellInput90Width {
  width: 90%;
  height: 100%;
  border: none;
  border-radius: 5px;
  font-weight: bold;

  text-align: center;
}

.cellInput70Width {
  width: 70%;
  height: 100%;
  font-weight: bold;

  border: none;
  border-radius: 5px;
  text-align: center;
}

.cell {
  border-radius: 5px;
  /* Add border radius value here */
  font-weight: bold;
}