.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

nav{ 
  padding: 0;
}

.navUl {
  list-style: none;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin:0;
  padding: 0;

}
.navLi {
  margin-left: 15px;
  color: var(--t-color);
}
.socialIcons {
  width: 16px;
  cursor: pointer;
}
.navLi > a {
  cursor: pointer;
  text-decoration: none;
}
.navLi > a:hover {
  color: #24006c;
}
.active {
  color: #24006c;

  
}

.navUl a {
  color: var(--t-color) !important;

}
.wrp {
  display: flex;
  background-color: var(--a-color);
  border: 2px solid  var(--s-color);
  border-radius: .5rem;
  /* padding: .2rem; */
  align-items: center;
box-sizing: border-box;
height: 44px;
padding-right: 10px;
margin-left: 1rem;
gap: 25px;

}