.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  overflow: auto;
  align-items: center;
  margin: 0;
  padding: 0;
}
.calculator {
  background-image: url("../kalkulatoriSlikaPozadina/tenisSlika.png");
  background-color: #cccccc;
  width: 100%;
  /* height: 284px; */
  height: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 5px;
  margin-right: 10px;
}
.prvi {
  position: absolute;
  top: 12px;
  left: 115px;
  color: yellow;
  font-size: 50px;
  font-weight: bolder;
}
.drugi {
  top: 12px;
  left: 465px;
  position: absolute;
  color: yellow;
  font-size: 50px;
  font-weight: bolder;
}
.treci {
  top: 12px;
  position: absolute;
  right: 535px;
  color: yellow;
  font-size: 50px;
  font-weight: bolder;
}

.cetvrti {
  top: 12px;
  position: absolute;
  right: 195px;
  color: yellow;
  font-size: 50px;
  font-weight: bolder;
}

.peti {
  position: absolute;
  top: 160px;
  left: 510px;
  color: yellow;
  font-size: 50px;
  font-weight: bolder;
}

.sesti {
  position: absolute;
  top: 160px;
  right: 520px;
  color: yellow;
  font-size: 50px;
  font-weight: bolder;
}

.sedmi {
  position: absolute;
  top: 145px;
  left: 125px;
  color: yellow;
  font-size: 50px;
  font-weight: bolder;
}

.osmi {
  position: absolute;
  top: 160px;
  right: 185px;
  color: yellow;
  font-size: 50px;
  font-weight: bolder;
}
