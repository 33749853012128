/* RecommendedPosts.module.css */

.recommendationsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 190px;
  overflow: visible;
  max-width: 980px;
  margin-top: 24px;
}

.slider {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;
  width: 100%;
  gap: 1rem;
  padding: 1rem;
}

.leftArrow {
  transform: rotate(180deg);
}

.hidden {
  display: none;
}

.card {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  width: 140px;
  height: 180px;
  cursor: pointer;
  flex-shrink: 0;
  padding: 4px;
  background-color: #353945;
  border-radius: 8px;
  border: 2px solid #212631;
}

.card:hover {
  transform: scale(1.05);
}

.videoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 4px;
  width: 100%;
}

.featuredImage {
  width: 100%;
  height: 106px;
  width: 132px;
  object-fit: cover;
  border-bottom: 2px solid #333;
}

.title {
  font-size: 14px;
  color: #ffffff;
  padding: 5px;
  font-weight: 600;
}

.recommendationsArrow {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  z-index: 2;
  display: none;
}

.featuredTitle {
  margin-top: auto;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: white;
  white-space: break-spaces;
  text-align: left;
  padding: 6px;
  padding-top: 0;
}

@media (min-width: 1024px) {
  .recommendationsArrow {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 92px;
  }
  .slider {
    padding-left: 0;
  }
}
