.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  overflow: auto;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 100%;
}

.calculator {
  background-image: url("../kalkulatoriSlikaPozadina/UslovneKvoteSistemaSlika.png");
  background-color: #cccccc;
  width: 100%;
  /* height: 284px; */
  height: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 5px;
  margin-right: 10px;
}

.minIsplativo {
  position: absolute;
  left: 680px;
  color: white;
  font-size: 50px;
  font-weight: bolder;
}

.minProcenat {
  position: absolute;
  left: 680px;
  top: 80px;
  color: white;
  font-size: 50px;
  font-weight: bolder;
}

.ukupanBroj {
  position: absolute;
  left: 680px;
  top: 170px;
  color: white;
  font-size: 50px;
  font-weight: bolder;
}

.onerowisplative {
  display: flex;
  text-align: center;
  width: 100%;
}

.input {
  width: 50%;
  height: 100px;
  border-radius: 5px;
  border: 4px solid black;
  margin-right: 5px;
  text-align: center;
  font-size: 40px;
}

.paragraphisplative {
  margin: 10px 0 10px 0;
  font-weight: bold;
  width: 100%;

  text-align: center;
}