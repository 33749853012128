.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 102px);
  width: 100%;
  align-items: center;
}
.container > h1 {
  margin-bottom: 35px;
}
.top {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  width: 100%;
}
.btn {
  width: 15%;
  padding: 14px;
  margin: 14px;
  font-size: 18px;
  color: aqua;
  text-align: center;
  background-color: black;
  border: 1px solid aqua;
  border-radius: 12px;
  cursor: pointer;
}
.postContainer {
  padding: 10px 50px;
  overflow: auto;
}
.btn:first-of-type {
  width: 300px;
  margin-right: 20px;
}
