.pageContainer * {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
}

.pageContainer {
  background-color: white;
  width: 100% !important;
  height: calc(100vh - 167.6px);
  overflow: hidden;
  max-width: 1070px;
  scrollbar-width: none;
}

.navigation {
  display: flex;
  background-color: #0b0b25;
  color: #ff0000;
  justify-content: space-between;
  font-size: 2rem;
  border-top: 8px solid #ff0000;
  border-bottom: 2px solid white;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 15;
}

.navigation button {
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.navigation button:first-of-type svg {
  color: #ff0000;
  flex-shrink: 0;
  height: 50px;
  width: 50px;
}

.navigation button:nth-of-type(2) svg {
  color: #38f81e;
  flex-shrink: 0;
  height: 50px;
  width: 50px;
}

.productsHeader {
  display: flex;
  min-height: 50px;
  background-color: #0b0b25;
  padding-inline: 0.725rem;
  margin: 1.5px;
  border-radius: 0.5rem;
  position: relative;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.productsHeader .sortBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  gap: 0.5rem;
  border: none;
  color: #0b0b25;
  padding: 0.2rem;
  border-radius: 0.5rem;
  font-size: 0.7rem;
}

.productsHeader .myCart {
  display: flex;
  color: white;
  align-items: center;
  font-size: 1rem;
  gap: 0.5rem;
  background-color: transparent;
  border: none;
  padding: 0.5rem;
}

.productsHeader .myCart svg {
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  color: greenyellow;
}

.carets {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.caretDown,
.caretUp {
  color: #ff0000;
  height: 20px;
  width: 20px;
  transform: scaleY(2.5);
}

.caretUp {
  color: #b2ec0a;
  margin-left: -0.5rem;
}

.productsHeader h3 {
  color: #ff0000;
  font-size: 1rem;
}

.categoriesAndProducts {
  display: flex;
  gap: 5px;
  min-height: 360px;
  overflow: hidden;
}

.productsContainer {
  display: flex;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.productsContainerInner {
  padding-inline: 25px !important;
  width: 100%;
  gap: 5px;
  display: flex;
  overflow-x: auto;
}

.navigatePrev,
.navigateNext {
  position: absolute;
  height: 100%;
  background-color: white;
  color: #ff0000;
  flex-shrink: 0;
  height: 50px;
  width: 25px;
  cursor: default;
  display: flex;
  align-items: center;
  height: 100%;
  background-color: #0b0b25;
  z-index: 5;
}
.navigatePrev {
  left: 0;
}
.navigatePrev svg,
.navigateNext svg {
  transform: scale(1.8);
  cursor: pointer;
}

.navigateNext {
  color: #38f81e;
  right: 0;
}

.navigation button {
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.modalWrapper {
  width: fit-content !important;
  height: fit-content !important;
  max-width: 100vw;
  max-height: 100vh;
}

.imagePreviewWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex: 1;
}

.imagePreviewWrapper img {
  max-width: 100%;
  max-height: 100vh;
  display: block;
  object-fit: contain;
}

.productContainerMobile {
  display: flex;
  justify-content: center;
  gap: 30px;
  background-color: #0b0b25;
  height: 220px;
}

.noResults {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: white;
}

.spinner {
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 60px;
}

.backFromProd {
  display: none;
}

@media (min-width: 765px) {
  .pageContainer {
    height: calc(100vh - 252.6px);
    position: relative !important;
    overflow: auto;
  }

  .backFromProd {
    display: flex;
    color: red;
    font-size: 1.3rem;
    bottom: 0;
    height: 100%;
    align-items: center;
    background-color: transparent;
    border: none;
  }

  .backFromProd svg {
    flex-shrink: 0;
    height: 25px;
    width: 25px;
  }

  .categoriesAndProducts {
    align-items: center;
    height: calc(100% - 55px);
  }

  .products {
    height: 100%;
  }

  .noResults {
    font-size: 3rem;
    color: black;
  }

  .productsHeader h3 {
    font-size: 2rem;
  }

  .productContainerMobile {
    display: none;
  }

  .navigatePrev,
  .navigateNext {
    flex-shrink: 0;
    height: 70px;
    width: 50px;
    background-color: white;
    height: 100%;
    top: 0;
  }

  .navigatePrev svg,
  .navigateNext svg {
    transform: scale(1.8);
    cursor: pointer;
  }

  .productsContainerInner {
    padding-inline: 55px !important;
    width: 100%;
    gap: 5px;
    display: flex;
    overflow-x: auto;
    scrollbar-width: none;
    scrollbar-color: transparent transparent;
  }

  .spinner {
    margin-top: 120px;
  }
}
