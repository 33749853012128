.container {
  display: flex;
  height: 48px;
  max-width: 1800px;
  width: calc(100% - 100px);
  color: linear-gradient(#070044, #080808);
  font-weight: 700;
  margin: 0 auto;
  margin-top: 4px;
  position: relative;
}

.infoNavUl {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101 !important;
  cursor: pointer;
  padding: 0;
}

.dot {
  color: white;
  font-size: 1.1rem;
  margin: 0 7px;
}

.infoNavLi {
  display: flex;
  color: var(--t-color) !important;
}

.infoNavLi svg,
.infoNavLi span {
  color: var(--t-color) !important;
}

.infoNavReload button {
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  font-size: 1.2rem;
  color: #2a2a2a;
  cursor: pointer;
}

.infoNavReload span {
  font-size: 0.6rem;
}

.infoNavUlKorisnickiPaketi {
  padding: 5px;
  border: 5px solid;
  border-color: #080808;
  width: 170px;
  text-align: center;
  border-radius: 8px;
  padding: 5px;
  border: 2px solid var(--s-color);
  color: white;
  background-color: var(--a-color);
}

.infoNavUlOtvoriIOsvoji {
  padding: 5px;
  border: 2px solid var(--s-color);
  color: white;
  background-color: var(--a-color);
  width: 170px;
  text-align: center;
  border-radius: 8px;
  margin: 0 5px;
}

.infoNavUlOtvoriIOsvoji button {
  background-color: var(--a-color);
  color: white;
}

.infoNavUlBetkoZid {
  margin-left: 5px;
  padding: 5px;
  width: 170px;
  text-align: center;
  border: 5px solid;
  border-color: #070426;
  border-radius: 8px;
  padding: 5px;
  border: 2px solid var(--s-color);
  color: white;
  background-color: var(--a-color);
}

.navLeft {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.navCenter {
  display: flex;
  width: 100%;
  justify-content: center;
}

.navRight {
  display: flex;
  justify-content: flex-end;
  margin-right: 0;
  width: 100%;
}

.popUpContainer {
  background-color: white;
  position: fixed;
  top: 230px;
  left: 50px;
  bottom: 0;
  transform: translate(-0%, 0);
  width: calc(100% - 50px - 20%);
  min-height: 58vh;
  padding: 20px 20px 10px 0px;
  z-index: 2000 !important;
  box-sizing: border-box;
}

@media (max-width: 1550px) {
  .popUpContainer {
    min-height: 46vh;
  }
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.popUpContainer button {
  cursor: pointer;
}

.buttonsContainer > :nth-child(1),
.buttonsContainer > :nth-child(3) {
  background-color: #13f16c;
  border: 2px solid darkblue;
  border-radius: 6px;
  font-weight: bold;
  padding: 15px 10px;
  font-size: 18px;
  color: darkblue;
}

.buttonsContainer > :nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.buttonsContainer > :nth-child(2) > button {
  display: flex;
  padding: 0;
  border: none;
  background-color: transparent;
}

.buttonsContainer > :nth-child(2) > span {
  font-weight: bold;
  pointer-events: none;
}

.popUpContainer > :nth-child(2) > button {
  background-color: #0b0d24;
  color: white;
  border: 2px solid red;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 22px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.popUpContainer > :nth-child(2) > button > span {
  display: flex;
  align-items: center;
}

.popUpGridContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 10px;
  row-gap: 5px;
}

.popUpGridContainer > div > button,
.popUpGridContainer > div > a {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #0b0d24;
  border: 2px solid red;
  color: white;
  padding: 10px 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-decoration: none;
}

.downloadContainer {
  display: block;
  background: linear-gradient(to right, #14cc68, #0b6f39);
  border: 4px solid black;
  margin: 0.5rem 0;
  padding: 3rem;
  font-size: 5rem;
  text-align: center;
}

.downloadContainer,
.downloadInfo p {
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  color: black;
  text-align: center;
}

.downloadInfo {
  font-size: 1.5rem;
  padding: 0 0.5rem;
  text-align: center;
  color: white;
  background: linear-gradient(to right, #e30000, #7a0303);
}
.downloadInfo p {
  color: white;
  margin: 0.75rem;
}

@media (max-width: 1157px) {
  .buttonsContainer > :nth-child(1),
  .buttonsContainer > :nth-child(3) {
    font-size: 14px;
  }

  .buttonsContainer > :nth-child(2) > span {
    font-size: 14px;
  }
}

/* OPEN AND CONQUER MODAL */

.openAndConquerModal {
  display: flex;
  flex-direction: column;
  gap: 3px;
  font-weight: 700;
}

.openAndConquerModal button {
  cursor: pointer;
  border-radius: 8px;
  font-weight: 700;

}

.openAndConquerModal .conquerBackBtn {
  border:4px solid #f81d03;
  color: #f81d03;
  background-color: white;
  font-size: 1.2rem;
  padding: .5rem;
}

.openAndConquerModal .conquerTitleDiv {
  background-color: #0ef64c;
  border:4px solid black;
  border-radius: 8px;
  color: black;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem .5rem ;
}

.conquerNavBtn {
  background-color: #002060;
  color: #0df64c;
  border:4px solid black;
  padding: .8rem .5rem;
  font-size: 1.7rem;
}