.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.input {
  width: 600px;
  background: transparent;
  border: 1px solid blueviolet;
  border-radius: 12px;
  padding: 12px;
  color: whitesmoke;
  font-size: 18px;
}
.btn {
  color: aqua;
  font-size: 18px;
  background: transparent;
  border: 1px solid blueviolet;
  border-radius: 12px;
  padding: 12px;
  margin-left: 20px;
  cursor: pointer;
}
