.container {
  width: 100%;
  margin: 5px 0;
}
.input {
  width: 9.2%;
  height: 30px;
  margin-right: 6px;
  border: 3px solid red;
  border-radius: 8px;
  font-size: 22px;
  font-weight: bolder;
}
.input::placeholder {
  font-size: 16px;
  color: #ccc;
  text-align: center;
}

.blackCenteredInput {
  width: 9.2%;
  height: 30px;
  margin-right: 6px;
  border: 3px solid black;
  border-radius: 8px;
  font-size: 22px;
  font-weight: bolder;
  text-align: center;
}
.blackCenteredInput::placeholder {
  font-size: 16px;
  color: #ccc;
  text-align: center;
}