.betkoShopLayout button {
  cursor: pointer;
}

.betkoShopLayout {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  width: 100vw;
  font-weight: 700;

  position: relative;
}

.betkoShopLayout img,
.betkoShopLayout svg {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

/* @media (min-width: 765px) {
  .betkoShopLayout {
    margin: 0.2rem;
    border-radius: .5rem;
    margin-top: 13px;
  }
} */
