.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin: 0;
  padding: 0;
}

.calculator {
  background-color: white;
  width: 100%;
  /* height: 284px; */
  height: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 5px;
  margin-right: 10px;
  color: rgb(206, 5, 5);
  display: flex;
  flex-direction: column;
}

.celijaGornjeTabele{
  height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  font-size: 13px;
}

.inputCelija{
  width: 100%;
  height: 100%;
  text-align: center;
  border: none;
  overflow: hidden;
}


