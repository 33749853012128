.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 99%;
  margin: 0 auto;
  height: 70px;
  border-bottom: 1px solid #ffffff;
  cursor: pointer;
}
