.header {
  height: 60px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1rem;
}

.logoWrapper {
  margin-right: 17.4px;
  cursor: pointer;
  flex-shrink: 0;
}

.headerInnerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  /* margin-block: auto; */
}

.headerLeft {
  display: flex;
  align-items: center;
}

.headerMenuBtn {
  display: flex;
  align-items: center;
  gap: 9px;
  padding: 6px 8px;
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  cursor: pointer;
}

.dot {
  width: 8px;
  height: 8px;
  display: flex;
  background-color: #d6ff00;
  border-radius: 50%; /* Makes it a circle */
}

.headerRight {
  display: flex;
  gap: 6px;
  align-items: center;
}

.header a:visited {
  color: white;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.875rem;
}

.desktopLinks {
  display: none;
  gap: 20px;
}

.menuItem {
  position: relative;
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-weight: 500;
}

.menuItem img {
  transform: rotate(180deg);
  filter: brightness(0) invert(1);
}

.activeMenuItem {
  color: var(--a-color);
}

.activeMenuItem img {
  color: var(--a-color);
  filter: none;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.subMenu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--p-color);
  padding: 6px;
  padding-top: 24px;
  z-index: 75;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  border-radius: 0 6px 6px;
  list-style: none;
}

.subMenu li,
.headerLink {
  text-decoration: none;
  color: white;
  padding: 6px 8px;
  white-space: nowrap;
  cursor: pointer;
}

.authBtn {
  display: none;
  background-color: #d6ff00;
  border: 1px solid #b8db00;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #252f3b;
  border-radius: 6px;
  padding: 0.5rem 1.5rem;
}

.startMenuBtn {
  border: 1px solid var(--a-color);
  color: var(--a-color);
  padding: 6px;
  padding-right: 8px;
  background-color: var(--p-color);
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.subMenu li:hover,
.headerLink:hover {
  color: var(--a-color);
}

.activeStartMenuBtn {
  background-color: var(--a-color);
  color: #252f3b;
}

@media (min-width: 1024px) {
  .desktopLinks {
    display: flex;
    gap: 0px;
  }

  .startMenuBtn {
    display: none;
  }

  .authBtn {
    display: inline-flex;
  }
}
