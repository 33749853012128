.shopHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 342px;
  padding-left: 16px;
  padding-right: 16px;
}
.header {
  padding: 0px 10px;
  border-bottom: 2px solid #d6ff00;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header > h1 {
  color: #d6ff00;
  font-size: 28px;
  font-weight: 600;
  padding-bottom: 10px;
}
.header > img {
  width: 28px;
  height: 28px;
}
