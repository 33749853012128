.pageContainer * {
  margin: 0;
  padding: 0;
}

.pageContainer {
  background-color: white;
  width: 100% !important;
  height: 100%;
  overflow: auto;
  text-align: center;
  scrollbar-width: none;
  height: calc(100vh - 167.6px);
}

.sticky {
  position: sticky;
  top: 0;
  background-color: white;
  padding-block: 2px;
}

.menuTitle {
  font-size: 1.2rem;
  padding: 0.725rem;
  text-transform: uppercase;
  background-color: #0B0B25;
  text-align: center;
}

.cf {
  font-weight: 700 !important;
}

.shop h2 {
  color: red;
}

.shop .navBtns button {
  background-color: #ff0000;
}

.licitacije h2 {
  color: #ffc000;
}

.licitacije .navBtns button {
  background-color: #ffc000;
}

.sport-turist h2 {
  color: #38f81e;
}

.sport-turist .navBtns button {
  background-color: #38f81e;
}

.navBtns {
  display: flex;
  justify-content: space-between;
  gap: 3px;
  margin: 2px;
  min-height: 45px;
}

.navBtns button {
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #0B0B25;
  outline: none;
  border: none;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.9rem;
}

.becomeSellerBtn {
  margin: 10px 2px;
  display: flex;
}

.becomeSellerBtn button {
  font-size: 1.3rem;
  padding: 0.9rem;
  background: linear-gradient(to bottom, #002060, #0729f7) !important;
  color: white;
}

.pageContainer h3 {
  font-size: 2rem;
  text-align: center;
  color: red;
  text-transform: uppercase;
  margin-block: 8px;
}

.pageContainer p {
  color: #022161;
  font-size: 1.1rem;
  margin-inline: 5px;
  margin-bottom: 1.1rem;
}

@media (min-width: 765px) {
  .navBtns button {
    font-size: 1.1rem;
  }

  .becomeSellerBtn button {
    font-size: 1.5rem;
  }
}
