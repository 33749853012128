.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  overflow: auto;
  align-items: center;
  margin: 0;
  padding: 0;
}
.calculator {
  background-image: url("../kalkulatoriSlikaPozadina/kosarka.png");
  background-color: #cccccc;
  width: 100%;
  /* height: 284px; */
  height: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 5px;
  margin-right: 10px;
}

.konacanIshod1 {
  position: absolute;
  top: 15px;
  left: 100px;
  color: orangered;
  font-size: 18px;
  font-weight: bolder;
}

.konacanIshod2 {
  position: absolute;
  top: 55px;
  left: 100px;
  color: orangered;
  font-size: 18px;
  font-weight: bolder;
}

.poeniMin {
  position: absolute;
  top: 20px;
  left: 258px;
  color: orangered;
  font-size: 32px;
  font-weight: bolder;
}

.poeniBalans {
  position: absolute;
  top: 20px;
  left: 422px;
  color: white;
  font-size: 32px;
  font-weight: bolder;
}

.poeniMax {
  position: absolute;
  top: 20px;
  left: 585px;
  color: orangered;
  font-size: 32px;
  font-weight: bolder;
}

.pobedaSaRazlikomMin {
  position: absolute;
  top: 115px;
  left: 555px;
  color: orangered;
  font-size: 32px;
  font-weight: bolder;
}

.pobedaSaRazlikomBalans {
  position: absolute;
  top: 157px;
  left: 555px;
  color: orangered;
  font-size: 32px;
  font-weight: bolder;
}

.pobedaSaRazlikomMax {
  position: absolute;
  top: 199px;
  left: 555px;
  color: orangered;
  font-size: 32px;
  font-weight: bolder;
}

.tacanRezultatDom1 {
  position: absolute;
  top: 0;
  left: 900px;
  color: orangered;
  font-size: 32px;
  font-weight: bolder;
}

.tacanRezultatDom2 {
  position: absolute;
  top: 60px;
  left: 900px;
  color: orangered;
  font-size: 32px;
  font-weight: bolder;
}

.tacanRezultatDom3 {
  position: absolute;
  top: 120px;
  left: 900px;
  color: orangered;
  font-size: 32px;
  font-weight: bolder;
}

.tacanRezultatGost1 {
  position: absolute;
  top: 0;
  left: 1230px;
  color: orangered;
  font-size: 32px;
  font-weight: bolder;
}

.tacanRezultatGost2 {
  position: absolute;
  top: 60px;
  left: 1230px;
  color: orangered;
  font-size: 32px;
  font-weight: bolder;
}

.tacanRezultatGost3 {
  position: absolute;
  top: 120px;
  left: 1230px;
  color: orangered;
  font-size: 32px;
  font-weight: bolder;
}

.betkopredlog {
  position: absolute;
  top: 160px;
  left: 1050px;
  color: orangered;
  font-size: 47px;
  font-weight: bolder;
}
