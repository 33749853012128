.container {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding-bottom: 40px;
}

.calculator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 3px solid black;
  width: 99.2%;
  margin-right: 5px;
  margin-top: 10px;
}

.firstRow {
  display: flex;
  background: #712ea1;
  width: 99.6%;
  border: 3px solid black;
}

.ulog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 120px;
}

.kvota {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 80px;
}

.prolazUpis {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
}

.dobitak {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 112px;
}

.trenutnoStanje {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 100px;
}

.second {
  width: 100%;
  background: green;
  color: whitesmoke;
  text-align: center;
}

.first>p,
.second>p {
  margin: 0;
  padding: 0;
  font-weight: bolder;
}

.ulog,
.kvota,
.prolazUpis,
.dobitak,
.trenutnoStanje {
  border: 2px solid black;
  color: whitesmoke;
}

.ulog>p,
.kvota>p,
.prolazUpis>p,
.dobitak>p,
.trenutnoStanje>p {
  font-size: 17px;
  font-weight: bolder;
  text-align: center;
}

.row {
  display: flex;
  border: 3px solid black;
  width: 99.6%;

}

.ulogRow>p,
.kvotaRow>p,
.prolazUpisRow>p,
.dobitakRow>p,
.trenutnoStanjeRow>p {
  margin: 0;
  padding: 0;
}

.ulogRow {
  width: 288px;
  text-align: center;
  background: red;
  color: whitesmoke;
  border-right: 3px solid black;
  border-left: 3px solid black;
}

.ulogRow>input[type="text"] {
  width: 280px;
}

.ulogRow2 {
  width: 288px;
  text-align: center;
  background: red;
  color: whitesmoke;
  border-right: 3px solid black;
  border-left: 3px solid black;
}

.kvotaRow {
  width: 212px;
  background: whitesmoke;
  color: whitesmoke;
  text-align: center;
  border-right: 3px solid black;
  border-left: 3px solid black;
}

.kvotaRow>input[type="number"] {
  width: 200px;
  border: none;
  text-align: center;
  font-weight: bolder;
}

.prolazUpisRow {
  width: 272px;
  color: whitesmoke;
  text-align: center;
  background: whitesmoke;
  text-align: center;
  border-right: 3px solid black;
  border-left: 3px solid black;
}

.prolazUpisRow>input[type="text"] {
  width: 265px;
  border: none;
  text-align: center;
  font-weight: bolder;
}

.prolazUpisRow>select {
  width: 265px;
}

.dobitakRow {
  width: 293px;
  text-align: center;
  background: #230F32;
  border-right: 3px solid black;
  border-left: 3px solid black;
  color: whitesmoke;
}

.dobitakRow2 {
  width: 298px;
  text-align: center;
  background: red;
  border-right: 1px solid black;
  color: whitesmoke;
}

.trenutnoStanjeRow {
  width: 346px;
  text-align: center;
  border-right: 3px solid black;
  border-left: 3px solid black;
  background: red;
  color: whitesmoke;
}

.odvojenoInputPolje {
  width: 97%;
  height: 88%;
  text-align: center;
  border: none;
  font-weight: bolder;
}