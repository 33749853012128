.tablesPageContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-weight: 700;
  flex: 1;
  padding: 1rem;
  padding-top: 0.5rem;
  overflow: auto;
}

.tablesPageContainer button {
  cursor: pointer;
  border-radius: 8px;
  font-weight: 700;
  border: none;
}

.backButton {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem !important;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  height: 20px;
  flex-shrink: 0;
  cursor: pointer;
  margin-right: auto;
}

.backButton svg {
  color: #ff0000;
  text-align: center;
  height: 100%;
  font-size: 3rem;
  display: flex;
  align-items: center;
  width: 20px;
  transform: scale(1.8);
}

.backButton span {
  margin-left: -2px;
}

.tablesPageContainer .tablesTitleDiv {
  background-color: #00143d;
  border-radius: 8px;
  color: #1cf836;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.tableNavBtn {
  background-color: #1cf836;
  color: #00143d;
  padding: 0.8rem 0.5rem;
  font-size: 1.7rem;
}

.tigerImgWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 450px;
  margin: 1rem 0;
}

.tigerImgWrapper img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
