.successPageContianer * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.successPageContianer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  font-family: Arial Black, sans-serif;
  font-weight: 900;
  scrollbar-width: none;
  overflow: auto;
  padding-bottom: 3rem;
}

.successPageContianer .iconWrapper {
  height: 120px;
  display: flex;
  justify-content: center;
  aspect-ratio: 1/1;
  align-items: center;
  background-color: #20f853;
  border-radius: 100%;
  border: 10px solid #11b058;
  padding: 0.5rem;
}

.successPageContianer .iconWrapper svg {
  color: white;
  width: 100%;
  height: 100%;
}

.successPageContianer h2 {
  font-size: 3rem;
  text-shadow: -2px -2px 0 #0ef64c, 2px -2px 0 #0ef64c, -2px 2px 0 #0ef64c,
    2px 2px 0 #0ef64c;
}

.successPageContianer p {
  font-size: 1.2rem;
  text-align: center;
  margin-block: 1.5rem;
}

.successPageContianer button { 
    width: 80%;
    color: white;
    text-shadow: -1.5px -1.5px 0 #11b058, 1.5px -1.5px 0 #11b058, -1.5px 1.5px 0 #11b058,
    1.5px 1.5px 0 #0ef64c;
    border: 3px solid #11b058;
    font-size: 2rem;
    border-radius: .5rem;
    background-color: #20f853;
    cursor: pointer;
    padding: .5rem;
}
