.singleProductCard {
  border: 3px solid #022161;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: white;
  padding-bottom: 1rem;
  height: 220px;
  margin-top: auto;
  width: calc((100vw - 60px) / 3);
  font-size: 0.8rem;
  font-family: 'Arial', sans-serif;
  cursor: pointer;
  text-align: center;
  flex-shrink: 0;
  padding-bottom: 5px !important;
}

.productTitle {
  color: black;
  padding: 0.2rem;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  text-transform: uppercase;
}

.productTitle p {
  margin: 0;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em;
  max-height: calc(2 * 1.2em);
}


.productPriceSeparator {
  color: white;
  background-color: #0b0b25;
  padding: 0.2rem;
  width: 100%;
  margin-top: auto !important;
}

.seeMore {
  border: none;
  background-color: white;
  color: #022161;
  font-size: 0.5rem;
}

.imageWrapper {
  display: flex;
  width: 100%;
  height: 85px;
}

.imageWrapper img {
  height: 100%;
  width: 100%;
  object-fit: contain !important;
}

.productPrice {
  color: #022161;
}

.productStock {
  background: linear-gradient(to right, #14cc69, #04617d);
  width: 100%;
  color: white;
  padding: 0.3rem 0 !important;
}

.outOfStock {
  background: linear-gradient(to right, #e20000, #790303);
}

.auction {
  color: #38f81f;
  margin-top: auto;
}

.expiredAuction {
  color: red;
  margin-top: auto;
}

.homePage {
  border: none !important;
}
.cardNumber { 
  font-size: .55rem;
  margin-top: 0.3rem;

}

@media (min-width: 765px) {
  .featuredProductsMobile {
    display: none;
  }


  .cardNumber {
    font-size: 1.1rem;
    color: #022161;
  }
  
  .singleProductCard {
    font-size: 1.3rem;
    aspect-ratio: auto;
    height: 300px;
    width: 90%;
    gap: 5px;
  }

  .productPriceSeparator {
    padding: 0.3rem !important;
  }

  .shopPage {
    width: 250px;
    flex-shrink: 0;
    height: 320px;
  }

  .imageWrapper {
    display: flex;
    height: 120px;
  }

  .seeMore {
    font-size: 0.8rem;
  }
}
