.cartContainer {
  background-color: white;
  width: 100% !important;
  height: 100%;
  overflow: auto;
  text-align: center;
  scrollbar-width: none;
  height: calc(100vh - 167.6px);
}

.cartHeader {
  background-color: #0B0B25;
  color: white;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
}

.backFromCartBtn {
  display: flex;
  color: red;
  font-size: 1rem;
  bottom: 0;
  height: 100%;
  align-items: center;
  background-color: transparent;
  border: none;
}

.backFromCartBtn svg {
  flex-shrink: 0;
  height: 25px;
  width: 25px;
}

.userInfoForm {
  color: #0B0B25;
  width: 100%;
  padding: 1rem;
  flex: 1;
  z-index: -1;
  display: flex;
  flex-direction: column;
}

.formItem {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 0.5rem;
}

.formItem > div:nth-of-type(1) {
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.formItem label {
  display: block;
  margin: 0 auto;
  font-size: 0.9rem;
  padding: 0;
}

.inputWrapper {
  display: flex;
  border: 2px solid red;
  flex: 1;
}

.formItem input {
  flex: 1;
  width: 60vw;
  border: 3px solid #0B0B25;
  outline: none !important;
  margin-left: auto;
  font-size: 1rem !important;
}

.auctionForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
  font-size: 1rem;
}

.auctionForm input {
  border: 3px solid #0B0B25;
  outline: none !important;
  width: 50vw;
  font-size: 1rem;
  text-align: center;
}

.auctionForm span {
  color: red;
}

.cartTitle {
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  color: #0B0B25;
  background-color: #b2ec0a;
  border-radius: 0.5rem;
  border: 3px solid #0B0B25;
}

.cartProducts {
  gap: 3px;
  flex-direction: column;
  font-size: 1rem;
  border: 2px solid red;
  text-align: center;
  justify-content: flex-start;
}

.tdQuantity {
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantityBtn {
  height: 20px;
  width: 20px;
  margin-inline: 5px;
  border-radius: 50%;
  border: 1px solid black;
  margin-bottom: auto;
}

.productName {
  color: red;
}

.totalSum {
  width: 100%;
  background-color: #0B0B25;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
}

.submitBtn {
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  color: #0B0B25;
  background-color: #b2ec0a;
  border-radius: 0.5rem;
  border: 3px solid #0B0B25;
  margin-top: 5px;
  padding: 0.725rem !important;
}

.orderInfo {
  text-align: center;
}

.orderInfo p:nth-of-type(1) {
  background-color: red;
  color: white;
  text-align: center;
  padding: 0.5rem;
}

@media (min-width: 765px) {
  .formItem input {
    width: 35vw;
  }

  .formItem label {
    font-size: 1.2rem;
  }

  .cartContainer {
    height: calc(100vh - 252.6px);
    position: relative !important;
  }

  .auctionForm {
    font-size: 1.5rem;
  }

  .auctionForm input {
    width: 30vw;
  }

  .cartHeader {
    justify-content: flex-start;
    padding: 0.1rem;
  }

  .backFromCartBtn {
    margin-right: 190px;
    font-size: 2rem;
  }

  .backFromCartBtn svg {
    height: 60px;
    width: 60px;
  }
}
