.pageContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem;
  padding-top: .5rem;
  font-weight: 700;
  gap: 0.5rem;
  box-sizing: border-box;
  overflow: auto;
  padding-bottom: 5rem;
  justify-content: stretch;
  font-weight: 700;
  scrollbar-width: none;
}

.backButton {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem !important;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  height: 20px;
  flex-shrink: 0;
  margin-right: auto;
  cursor: pointer;
}

.backButton svg {
  color: #ff0000;
  text-align: center;
  height: 100%;
  font-size: 3rem;
  display: flex;
  align-items: center;
  width: 20px;
  transform: scale(1.8);
}

.backButton span {
  margin-left: -2px;
}


.titleWrapper {
  cursor: default;
  width: 100%;
  display: flex;
}


.titleWrapper button {
  background-color: #002060;
  border: none;
  color: white;
  border-radius: 0.5rem;
  padding: 2rem;
  font-size: 1.8rem;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
  width: 100%;
  font-weight: 700 !important;
}

.textWrapper {
  background-color: #64fa36;
  color: #002060;
  border-radius: 0.5rem;
  text-align: center;
  font-size: 1.2rem;
  padding: 1.5rem;
}

