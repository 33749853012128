.pageContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  overflow: auto;

  padding-top: 24px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  scrollbar-width: none;
}

.postsPageContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  overflow: auto;
  padding-top: 24px;
  box-sizing: border-box;
  position: relative;
  scrollbar-width: none;
}

.postsPageContainer > h1 {
  color: var(--a-color);
  border-bottom: 2px solid var(--a-color);
  width: 100%;
  padding-bottom: 10px;
  text-align: center;
  margin-block: 1.5rem;
  display: block;
}

.homePageContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  overflow: auto;
  flex-wrap: wrap;
  box-sizing: border-box;
  position: relative;
  scrollbar-width: none;
  justify-content: flex-start;
  max-width: 980px;
  width: 100%;
}

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  flex-wrap: wrap;
  /* padding-left: 8px; */
  /* padding-bottom: 40px; */
  box-sizing: border-box;
  position: relative;
  scrollbar-width: none;
}

.infoBlockContainer {
  width: 100%;
  justify-content: flex-start;
  margin-top: 24px;
}

.postsAndCalculatorWrapper {
  width: 100%;
  height: 100%;
  display: flex;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.msg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 32px;
  font-weight: bold;
}

.containercard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  min-height: 300px;
  box-shadow: 5px 20px 50px #070044;
  border-radius: 8px;
  cursor: pointer;
  /* margin: 8px; */
  width: 100%;
}

.containercard > img {
  width: 320px;
  padding: 12px;
}

.containerMainCard {
  justify-content: flex-end;
  overflow: hidden;
}

.containerMainCardInnerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  text-align: center;
  padding: 0 20px !important;
}

.updatedAtMainCardContainer {
  background-color: #1f66e4;
  color: white;
  width: 100%;
  text-align: center;
  padding: 0.7rem 0;
}

.updatedAtMainCardContainer span {
  color: white;
  font-weight: bolder;
  font-size: 1.4rem;
  font-family: 'Questrial', sans-serif;
}

.btnContainers {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 1800px;
  width: calc(100% - 100px);
  margin: 0 auto;
  overflow-y: visible !important;

  padding-bottom: 0;
  height: 120px;
}

.image {
  width: 1000px;
  height: 68px;
  background: linear-gradient(#070044, #080808);
}

.btn1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  height: 100%;
  cursor: pointer;
  background: linear-gradient(#070044, #080808);
  /* color: #ffffff; */
  color: orangered;
  font-size: 28px;
  font-weight: bolder;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.btn1 > p {
  margin-left: 80px;
}

.btn2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  height: 100%;
  cursor: pointer;
  background: linear-gradient(#070044, #080808);
  /* color: #ffffff; */
  color: orangered;
  font-size: 28px;
  font-weight: bolder;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.btn2 > p {
  margin-right: 60px;
  /* text-align: right; */
}

.recommendations {
  /* display: flex; */
  /* justify-content: flex-start; */
  /* align-items: center; */
  width: 55%;
  overflow-y: visible !important;
  height: 100%;
  background: linear-gradient(#070044, #080808);
  color: #ffffff;
  font-weight: bolder;
}

.card {
  min-height: 150px;
  width: 100%;
  display: flex;
  cursor: pointer;
  justify-content: center;
  position: relative;
  /* Needed for absolute positioning context */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background-color: black;
}

.card img {
  height: 120px;
  width: 150px;
  border-radius: 10px;
  padding-left: 5px;
  margin-top: -10px;
  /* border: 5px solid #86e925; */
}

.card video {
  border-radius: 10px;
  margin-top: -10px;

  /* border: 5px solid #86e925; */
}

.card > div > h2 {
  position: absolute;
  width: 150px;
  bottom: 32px;
  font-size: 15px;
  text-align: center;
  background-color: #1300c065;
  border-radius: 4px;
  /* height: 20%; */
}

.card > div {
  padding: 0px 5px;
}

.whiteSpace {
  width: 100%;
  background-color: var(--p-color);
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1000;
  /* padding:3px 0 0px 0; */
}

.arrowcont {
  cursor: pointer;
  width: 225px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(7, 0, 68);
}

.swiperclass {
  overflow-y: visible !important;
}

.swiperclass > div {
  overflow-y: visible !important;
}

.swipercont {
  overflow-y: visible !important;
}

.swiper {
  overflow: visible !important;
}

.swiperContainer {
  display: flex;
  justify-content: space-between;
  height: 300px;
  overflow: hidden;
  object-fit: contain;
  width: 100%;
  z-index: 3;
  background-color: var(--p-color);
}

.swiperVideo {
  width: 100% !important;
  height: 300px !important;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  position: relative;
}

.arrowsupleft,
.arrowsright {
  cursor: pointer;
  width: 220px;
  height: 70px;
}

.arrowsleft,
.arrowsupright {
  cursor: pointer;
  height: 200px;
}

.arrowsleft {
  margin-right: -1.5rem;
}
.arrowsupright {
  margin-left: -1.5rem;
}

.arrowsupright,
.arrowsupleft {
  margin-bottom: -1.8rem;
}

.newMenuContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 0px 30px;
  z-index: 10;
  margin-top: 2px;
}

.newMenuContainer div {
  border: 2px solid var(--s-color);
  border-radius: 0.5rem;
}

@media (min-width: 1450px) {
  .newMenuContainer {
    width: 100%;
  }
}

.newMenuContainer > div {
  width: 100%;
  height: 100%;
  min-height: 140px;
  display: grid;
  border-radius: 12px;
}

.newMenuContainer > div > button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  font-family: 'Questrial', sans-serif;
  font-size: 22px;
  font-weight: 700;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  background-color: #070044;
}

.newMenuContainer > div > button > span {
  text-align: center;
  width: 100%;
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
}

@media (max-width: 1560px) {
  .newMenuContainer > div > button > span {
    font-size: 20px;
  }
}

.newMenuContainer > :nth-child(1) > button {
  color: #93e312;
}

.newMenuContainer > :nth-child(2) > button {
  color: #ffc000;
}

.newMenuContainer > :nth-child(3) > button {
  color: #ff0000;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  row-gap: 15px;
  padding: 15px 30px;
}

@media (max-width: 1050px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

.homePopUpContainer {
  position: absolute;
  top: 0;
  left: 0px;
  right: 0px;
  bottom: 0;
  background-color: white;
  z-index: 999 !important;
  padding: 10px;
}

.homePopUpContainer > :nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
}

.homePopUpContainer button {
  cursor: pointer;
}

.homePopUpContainer > :nth-child(2) > button {
  background-color: #0b0d24;
  color: white;
  border: 2px solid red;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.homePopUpContainer > :nth-child(2) > button > span {
  display: flex;
  align-items: center;
}

.homePopUpDesc {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.homePopUpDesc > p {
  font-size: 14px;
  font-weight: bolder;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  text-wrap: balance;
  line-height: 1.2;
}

.homePopUpDesc > :nth-child(1) {
  margin-top: 5px;
}

.checkboxPopUpContainer {
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 6px;
  border: 3px solid black;
  border-radius: 10px;
  box-sizing: border-box;
}

.checkboxPopUpContainer > span {
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-weight: bold;
}

.checkboxPopUpContainer > div {
  flex: 1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  position: relative;
}

.checkboxPopUpContainer > div > input {
  appearance: none;
  width: 100%;
  height: 100%;
  border: 2px solid black;
  box-sizing: border-box;
  margin: 0;
  min-height: 30px;
  background-color: white;
}

.checkboxPopUpContainer > div > input::before {
  content: '\2713';
  font-size: 20px;
  font-weight: bolder;
  color: transparent;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.checkboxPopUpContainer > div > input:checked::before {
  color: red;
}

.sportskePrice {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px 0px 30px;
}

.sportskePrice > button {
  padding: 15px 20px;
  background-color: #070044;
  color: white;
  font-weight: bold;
  font-size: 30px;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Questrial', sans-serif;
}

.allCommentsLoaderWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.allCommentsLoaderWrapper div {
  align-items: flex-start !important;
  padding-top: 2rem;
}

/* REDESIGN */

.desktopShortcutBtns {
  justify-content: space-between;
  width: 100%;
  max-width: 616px;
  gap: 1rem;
  display: none;
}

.desktopShortcutBtns button {
  background-color: #0000009e;
  color: var(--a-color);
  border: 1px solid #d6ff00;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  flex: 1;
}

.commentsAndCalculatorWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 980px;
  gap: 1.5rem;
  padding-inline: 1rem;
}

.commentsand {
  width: 100%;
}

.containerInner {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.xx {
  display: flex;
  flex-direction: column;
}

.postsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  width: 100%;
  max-width: 520px;
}
@media (min-width: 1024px) {
  .desktopShortcutBtns {
    display: flex;
    margin-bottom: 24px;
    margin-top: 20px;
  }

  .commentsand {
    max-width: 616px;
  }
  .commentsAndCalculatorWrapper {
    padding: 0;
  }
}
