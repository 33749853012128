.apkUpdate {
  height: 100vh;
  width: 100vw;
  background-color: red;
  position: absolute;
  inset: 0;
  z-index: 10000000;
}

.apkUpdate img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
