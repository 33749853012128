.openAndConquerModal {
  display: flex;
  flex-direction: column;
  gap: 3px;
  font-weight: 700;
  width: 1050px;
}

.openAndConquerModal button {
  cursor: pointer;
  border-radius: 8px;
  font-weight: 700;
}

.openAndConquerModal .conquerBackBtn {
  color: #f81d03;
  background-color: black;
  padding: 1rem;
  font-size: 1.6rem;
}

.openAndConquerModal .conquerTitleDiv {
  background-color: #0ef64c;
  border: 4px solid black;
  border-radius: 8px;
  color: black;
  font-size: 4.5rem;
  display: flex;
  align-items: center;
  font-family: sans-serif;
  font-weight: 900;
  justify-content: space-between;
  font-family: Arial Black, sans-serif !important;
}

.conquerTitleDiv svg {
  height: 90px;
  width: 90px;
  margin-inline: 2rem;
}

.beWinner {
  color: black;
  font-size: 6rem;
  margin-bottom: 0;
  margin-top: -7rem;
  display: flex;
  align-items: center;
  height: 7rem;
  width: 100%;
  justify-content: center;
  font-weight: 700;
  background-color: transparent;
  text-shadow: -4px -4px 0 #0ef64c, 4px -4px 0 #0ef64c, -4px 4px 0 #0ef64c,
    4px 4px 0 #0ef64c;
}

.beWinner.soon {
  margin-top: 1rem;
}

.conquerNavBtn {
  background-color: #002060;
  color: #0df64c;
  border: 4px solid black;
  padding: 0.8rem 0.5rem;
  font-size: 1.7rem;
}

.tigerImgWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 450px;
  margin: 1rem 0;
}

.tigerImgWrapper img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
