.sideImages {
  display: none;
  flex-direction: column;
  gap: 24px;
  margin-right: 24px;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.adItem  {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 200px;
  overflow: hidden;
  background-color: white;
  border: 2px solid #d6ff00;
  border-radius: 0.5rem;
}

@media (min-width: 1024px) {
  .sideImages {
    display: flex;
  }
}