.tipsterPricesAdmin {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px) !important;
  padding: 0 2rem;
  overflow: auto;
  color: white;
}

.tipsterPricesForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}

.row {
  width: 100%;
  flex: 1;
  /* display: flex; */
}
.awardFormRow {
  display: flex;
  width: 70%;
  gap: 20px;
}

.awardFormRow input {
  width: 25vw;
}

.archiveButtons {
  display: flex;
  gap: 1.1rem;
}

.archiveButtons button,
.submitBtn {
  width: 500px;
  background-color: #007bff;
  color: white;
  padding: 0.7rem;
  border-radius: 0.5rem;
  font-size: 1.1rem;
  cursor: pointer;
  border: 2px solid white;
  width: 25vw;
}

.tableTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  position: sticky;
  top: 0;
  padding: 1.5rem 0;
  background-color: #2c2b2b;
  z-index: 500;
}

.tableTop * {
  margin: 0;
}

.userManual {
  color: white;
}

.tipsterPricesForm input:focus,
.tipsterPricesForm input:focus-within {
  background-color: white !important;
}

.tipsterPricesForm label,
.tipsterPricesAdmin h3 {
  color: white !important;
}

.tipsterPricesForm .submitBtn {
  width: 100%;
  margin-left: auto !important;
}
